
<!--
   main.component.html
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
-->

<div class="main-container text-center">
  <h2>{{ 'MAIN.REMOTE_CONNECTION' | translate }}</h2>
  <h3>{{ 'MAIN.DEVICE_CONNECTED' | translate }}</h3>
  <div class="connected-wrapper">
    <img src="/assets/images/portbay_connected.svg" alt="{{ 'MAIN.REMOTE_CONNECTION' | translate }}" />
  </div>
</div>

<app-xrx-modal #errorModal title="{{ 'JOB_CANCEL.TITLE.UNEXPECTED_ERROR' | translate }}">
  <div>
    <!--p>{{error}}</p-->
    <p *ngIf="twoSidedError">{{ 'FAULT.BODY.TWO_SIDED_NOT_SUPPORTED' | translate }}</p>
    <p *ngIf="!twoSidedError">{{ 'JOB_CANCEL.BODY.RETRY_JOB_SUBMISSION' | translate }}</p>
  </div>
</app-xrx-modal>

<app-xrx-modal #sessionErrorModal title="{{ 'FAULT.TITLE.CONNECTION_ERROR' | translate }}">
  <div>
    <!--p>{{error}}</p-->
    <p>{{ 'FAULT.BODY.PROBLEM_CONNECTING_SERVER' | translate }}</p>
  </div>
</app-xrx-modal>

<app-xrx-modal #webSocketErrorModal title="{{ 'FAULT.TITLE.WEBSOCKET_DISCONNECT' | translate }}">
  <div>
    <p>{{ 'FAULT.BODY.WEBSOCKET_DISCONNECT' | translate }}</p>
  </div>
</app-xrx-modal>

<app-xrx-modal #copyTwoSideModal [showClose]="false" title="{{ 'MODAL.COPY_TWO_SIDED' | translate }}">
  <div>
    <p>{{ 'MODAL.COPY_TWO_SIDED_DESCRIPTION' | translate }}</p>
    <div class="modal-footer">
      <button class="xrx-btn btn-white two-sided-button mar-right-20" (click)="cancelTwoSidedCopyJob()">{{
        'MODAL.COPY_TWO_SIDED_CANCEL' | translate }}</button>
      <button class="xrx-btn btn-primary two-sided-button" (click)="continueTwoSidedCopyJob()" [disabled]="disableButton">{{
        'MODAL.COPY_TWO_SIDED_CONTINUE' | translate }}</button>
    </div>
  </div>
</app-xrx-modal>

<app-xrx-modal #copyTwoSideErrorModal [showClose]="false" title="{{ 'MODAL.CANNOT_USE_FEEDER_FOR_SIDE_TWO' | translate }}">
  <div>
    <p>{{ 'MODAL.CANNOT_USE_FEEDER_FOR_SIDE_TWO_DESC' | translate }}</p>
    <div class="modal-footer">
      <button class="xrx-btn btn-white two-sided-button mar-right-20" (click)="cancelTwoSidedCopyJob()">{{
        'MODAL.COPY_TWO_SIDED_CANCEL' | translate }}</button>
      <button class="xrx-btn btn-primary two-sided-button" (click)="continueTwoSidedCopyJob()">{{
        'MODAL.COPY_TWO_SIDED_CONTINUE' | translate }}</button>
    </div>
  </div>
</app-xrx-modal>

<app-thankyou-modal #ShowConversionError [showDescription]="true" [receiptImage]="false" [descriptionSize]= "true"></app-thankyou-modal>

<app-xrx-modal #trayInfoModal [showClose]="true" [showExit]="false" [showDescription]="true"></app-xrx-modal>
<app-xrx-modal #paperSupplyNotSupportModal [showClose]="true" [showExit]="false" [showDescription]="true"></app-xrx-modal>
<app-xrx-modal #cancelErrorModal [showClose]="true" [showExit]="false" [showDescription]="true"></app-xrx-modal>
<app-xrx-modal #logoutErrorModal [showClose]="false" [showExit]="true" [showDescription]="true"></app-xrx-modal>
<app-thankyou-modal #showReceiptModal [showDescription]="true" [receiptImage]="true"></app-thankyou-modal>
<app-thankyou-modal #showEndSessionModal [showDescription]="true" [receiptImage]="false"></app-thankyou-modal>
<app-thankyou-modal #showThankyouModal [showDescription]="true" [receiptImage]="false" [descriptionSize]= "true"></app-thankyou-modal>
