
/* --------------------------------------------------------------------------------------
   scan.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { delay, map, retryWhen, scan, tap } from 'rxjs/operators';
import { SnmpService } from './snmp.service';
import { EmailContentService } from './email-content.service';
import { LogService, PwaSessionService, SessionService } from 'src/app/services';
import { EnvironmentService } from 'src/app/services/environment.service';
import { oidCodes } from './constants/oid-codes.const';
declare function xrxScanV2InitiateScanJob(url, ticket, callback, errorCallback, timeout: number)
declare function xrxScanV2ParseInitiateScanJob(response: string)

@Injectable({
  providedIn: 'root'
})
export class ScanService {

  recipient: string
  sides: string
  resolution: string
  originalSize: string
  originalOrientation: string
  colorMode: string
  jobId: string
  scanTemplate: any
  scanTicket: any
  finalScanTicket: any
  faxTicket: any
  fullFaxTicket: any
  destinationHost: string
  requestUri: string
  fileSavingLocation: string
  fileName: string
  faxSides: string
  faxOriginalSize: string
  faxResolution: string
  scanToEmailSubject: string;
  scanToEmailBody: string;
  decodedStringForEmailSubject: string;
  decodedStringForEmailBody: string;
  regexToMatchHex = /^[0-9A-Fa-f]+$/g;
  regexToRemoveSpaces = /\s+/g;
  formattedScanToEmailBody: string


  constructor(
    private emailContentService: EmailContentService,
    private pwaSessionService: PwaSessionService,
    private sessionService: SessionService,
    private snmpService: SnmpService,
    private environmentService: EnvironmentService,
    private logService: LogService
  ) {
    this.emailContentService.get().subscribe((res) => {
      this.scanToEmailBody = res.body;
      this.scanToEmailSubject = res.subject;

      if ((this.isHexadecimal(this.scanToEmailBody) && !(this.sessionService.isFujiBasedProduct)) || (this.isHexadecimal(this.scanToEmailSubject) && !(this.sessionService.isFujiBasedProduct))) {
        this.decodedStringForEmailBody = decodeURIComponent(escape((this.hexToString(this.scanToEmailBody))));
        this.scanToEmailBody = this.decodedStringForEmailBody;
        this.decodedStringForEmailSubject = decodeURIComponent(escape((this.hexToString(this.scanToEmailSubject))));
        this.scanToEmailSubject = this.decodedStringForEmailSubject;
      }
    })
  }

  isHexadecimal(str: string): boolean {
    str = str.replace(this.regexToRemoveSpaces, "")
    return this.regexToMatchHex.test(str);
  }

  // The below function converts hexadecimal characters to it's respective language
  hexToString(hex: string) {
    hex = hex.replace(this.regexToRemoveSpaces, "")
    let string = '';
    for (let i = 0; i < hex.length; i += 2) {
      const hexValue = hex.substr(i, 2);
      const decimalValue = parseInt(hexValue, 16);
      string += String.fromCharCode(decimalValue);
    }
    return string;
  }

  scan(deviceUrl, template) {

    //Scan ticket parameter values
    this.recipient = template.recipient;
    this.sides = template.sides;
    this.resolution = template.resolution;
    this.originalSize = template.originalSize;
    this.originalOrientation = template.originalOrientation;
    this.colorMode = template.colorMode;

    this.scanTicket = '&lt;?xml version="1.0" encoding="utf-8"?&gt;' +
      '&lt;ScanJobTicket xmlns:xsi=&quot;http://www.w3.org/2001/XMLSchema-instance&quot; xmlns:xsd=&quot;http://www.w3.org/2001/XMLSchema&quot; xmlns=&quot;http://schemas.xerox.com/enterprise/eipjobmodel/1&quot;&gt;' +
      '&lt;JobProcessing&gt;' +
      '&lt;Input&gt;&lt;ColorMode&gt;' + this.colorMode + '&lt;/ColorMode&gt;&lt;Sides&gt;' + this.sides + '&lt;/Sides&gt;&lt;ImageOptions&gt;&lt;Resolution&gt;' + this.resolution + '&lt;/Resolution&gt;&lt;/ImageOptions&gt;&lt;LayoutAdjustment&gt;&lt;InputOrientation&gt;' + this.originalOrientation + '&lt;/InputOrientation&gt;&lt;InputMediaSize&gt;&lt;MediaSizeType&gt;' + this.originalSize + '&lt;/MediaSizeType&gt;&lt;/InputMediaSize&gt;&lt;/LayoutAdjustment&gt;&lt;/Input&gt;&lt;Output&gt;&lt;Destination&gt;&lt;DestinationType&gt;Smtp&lt;/DestinationType&gt;&lt;ManualValue&gt;&lt;DocumentFormat&gt;PDF&lt;/DocumentFormat&gt;&lt;EmailOptions&gt;&lt;MessageBody&gt;' + this.scanToEmailBody + '&lt;/MessageBody&gt;&lt;SubjectLine&gt;' + this.scanToEmailSubject + '&lt;/SubjectLine&gt;&lt;From&gt;Kiosk@xerox.com&lt;/From&gt;&lt;Recipients&gt;&lt;To&gt;' + this.recipient + '&lt;/To&gt;&lt;/Recipients&gt;&lt;/EmailOptions&gt;&lt;/ManualValue&gt;&lt;/Destination&gt;&lt;/Output&gt;' +
      '&lt;/JobProcessing&gt;' +
      '&lt;/ScanJobTicket&gt;'

    this.finalScanTicket = '<ScanJobTicketXmlDocument>' + this.scanTicket + '</ScanJobTicketXmlDocument>'

    //Check Fuji or Discovery device
    if (this.pwaSessionService.isFujiDevice) {
      return this.submitScanTicket(deviceUrl, this.finalScanTicket)
    }
    else {
      this.snmpService.snmpGetResponse(deviceUrl, oidCodes.paperInPlaten);
      if (!this.sessionService.isPaperinPlaten) { //paper in feeder
        return this.submitScanTicket(deviceUrl, this.finalScanTicket)
      }
      else { //no paper in feeder
        //If the paper in platen, it's change to simplex
        this.sides = "OneSided";
        this.scanTicket = '&lt;?xml version="1.0" encoding="utf-8"?&gt;' +
          '&lt;ScanJobTicket xmlns:xsi=&quot;http://www.w3.org/2001/XMLSchema-instance&quot; xmlns:xsd=&quot;http://www.w3.org/2001/XMLSchema&quot; xmlns=&quot;http://schemas.xerox.com/enterprise/eipjobmodel/1&quot;&gt;' +
          '&lt;JobProcessing&gt;' +
          '&lt;Input&gt;&lt;ColorMode&gt;' + this.colorMode + '&lt;/ColorMode&gt;&lt;Sides&gt;' + this.sides + '&lt;/Sides&gt;&lt;ImageOptions&gt;&lt;Resolution&gt;' + this.resolution + '&lt;/Resolution&gt;&lt;/ImageOptions&gt;&lt;LayoutAdjustment&gt;&lt;InputOrientation&gt;' + this.originalOrientation + '&lt;/InputOrientation&gt;&lt;InputMediaSize&gt;&lt;MediaSizeType&gt;' + this.originalSize + '&lt;/MediaSizeType&gt;&lt;/InputMediaSize&gt;&lt;/LayoutAdjustment&gt;&lt;/Input&gt;&lt;Output&gt;&lt;Destination&gt;&lt;DestinationType&gt;Smtp&lt;/DestinationType&gt;&lt;ManualValue&gt;&lt;DocumentFormat&gt;PDF&lt;/DocumentFormat&gt;&lt;EmailOptions&gt;&lt;MessageBody&gt;' + this.scanToEmailBody + '&lt;/MessageBody&gt;&lt;SubjectLine&gt;' + this.scanToEmailSubject + '&lt;/SubjectLine&gt;&lt;From&gt;Kiosk@xerox.com&lt;/From&gt;&lt;Recipients&gt;&lt;To&gt;' + this.recipient + '&lt;/To&gt;&lt;/Recipients&gt;&lt;/EmailOptions&gt;&lt;/ManualValue&gt;&lt;/Destination&gt;&lt;/Output&gt;' +
          '&lt;/JobProcessing&gt;' +
          '&lt;/ScanJobTicket&gt;'

        this.finalScanTicket = '<ScanJobTicketXmlDocument>' + this.scanTicket + '</ScanJobTicketXmlDocument>'
        return this.submitScanTicket(deviceUrl, this.finalScanTicket)
      }
    }
  }

  fax(deviceUrl, template) {

    //Fax ticket parameter values
    this.destinationHost = template.DestinationHost;
    this.requestUri = template.RequestUri;
    this.fileSavingLocation = template.FileSavingLocation;
    this.fileName = template.FileName;
    this.faxSides = template.ScanSettings.Sides;
    this.faxOriginalSize = template.ScanSettings.OriginalSize;
    this.faxResolution = template.ScanSettings.Resolution;

    this.faxTicket = '&lt;?xml version="1.0" encoding="utf-8"?&gt;' +
      '&lt;ScanJobTicket xmlns:xsi=&quot;http://www.w3.org/2001/XMLSchema-instance&quot; xmlns:xsd=&quot;http://www.w3.org/2001/XMLSchema&quot; xmlns=&quot;http://schemas.xerox.com/enterprise/eipjobmodel/1&quot;&gt;' +
      '&lt;JobProcessing&gt;' +
      '&lt;Input&gt;&lt;ColorMode&gt;Mono&lt;/ColorMode&gt;&lt;Sides&gt;' + this.faxSides + '&lt;/Sides&gt;&lt;ImageOptions&gt;&lt;Resolution&gt;' + this.faxResolution + '&lt;/Resolution&gt;&lt;/ImageOptions&gt;&lt;LayoutAdjustment&gt;&lt;InputMediaSize&gt;&lt;MediaSizeType&gt;' + this.faxOriginalSize + '&lt;/MediaSizeType&gt;&lt;/InputMediaSize&gt;&lt;/LayoutAdjustment&gt;&lt;/Input&gt;&lt;Output&gt; &lt;Destination&gt; &lt;DestinationType&gt;Https&lt;/DestinationType&gt; &lt;ManualValue&gt; &lt;FriendlyName&gt;ScantoHttp&lt;/FriendlyName&gt; &lt;DocumentName&gt;' + this.fileName + '&lt;/DocumentName&gt; &lt;LoginSource&gt; &lt;LoginSourceType&gt;None&lt;/LoginSourceType&gt; &lt;/LoginSource&gt; &lt;FilingPolicy&gt;Overwrite&lt;/FilingPolicy&gt; &lt;Host&gt;' + this.destinationHost + '&lt;/Host&gt; &lt;Path&gt;' + this.fileSavingLocation + '&lt;/Path&gt; &lt;ScriptPath&gt;' + this.requestUri + '&lt;/ScriptPath&gt; &lt;DocumentFormat&gt;PDF&lt;/DocumentFormat&gt; &lt;/ManualValue&gt; &lt;/Destination&gt; &lt;/Output&gt;' +
      '&lt;/JobProcessing&gt;' +
      '&lt;/ScanJobTicket&gt;'
    this.fullFaxTicket = '<ScanJobTicketXmlDocument>' + this.faxTicket + '</ScanJobTicketXmlDocument>'

    //Check Fuji or Discovery device
    if (this.pwaSessionService.isFujiDevice) {
      return this.submitScanTicket(deviceUrl, this.fullFaxTicket)
    } else {
      this.snmpService.snmpGetResponse(deviceUrl, oidCodes.paperInPlaten);
      if (!this.sessionService.isPaperinPlaten) { //paper in feeder
        return this.submitScanTicket(deviceUrl, this.fullFaxTicket)
      } else { //no paper in feeder
        //If the paper in platen, it's change to simplex
        this.faxSides = "OneSided";

        this.faxTicket = '&lt;?xml version="1.0" encoding="utf-8"?&gt;' +
          '&lt;ScanJobTicket xmlns:xsi=&quot;http://www.w3.org/2001/XMLSchema-instance&quot; xmlns:xsd=&quot;http://www.w3.org/2001/XMLSchema&quot; xmlns=&quot;http://schemas.xerox.com/enterprise/eipjobmodel/1&quot;&gt;' +
          '&lt;JobProcessing&gt;' +
          '&lt;Input&gt;&lt;ColorMode&gt;Mono&lt;/ColorMode&gt;&lt;Sides&gt;' + this.faxSides + '&lt;/Sides&gt;&lt;ImageOptions&gt;&lt;Resolution&gt;' + this.faxResolution + '&lt;/Resolution&gt;&lt;/ImageOptions&gt;&lt;LayoutAdjustment&gt;&lt;InputMediaSize&gt;&lt;MediaSizeType&gt;' + this.faxOriginalSize + '&lt;/MediaSizeType&gt;&lt;/InputMediaSize&gt;&lt;/LayoutAdjustment&gt;&lt;/Input&gt;&lt;Output&gt; &lt;Destination&gt; &lt;DestinationType&gt;Https&lt;/DestinationType&gt; &lt;ManualValue&gt; &lt;FriendlyName&gt;ScantoHttp&lt;/FriendlyName&gt; &lt;DocumentName&gt;' + this.fileName + '&lt;/DocumentName&gt; &lt;LoginSource&gt; &lt;LoginSourceType&gt;None&lt;/LoginSourceType&gt; &lt;/LoginSource&gt; &lt;FilingPolicy&gt;Overwrite&lt;/FilingPolicy&gt; &lt;Host&gt;' + this.destinationHost + '&lt;/Host&gt; &lt;Path&gt;' + this.fileSavingLocation + '&lt;/Path&gt; &lt;ScriptPath&gt;' + this.requestUri + '&lt;/ScriptPath&gt; &lt;DocumentFormat&gt;PDF&lt;/DocumentFormat&gt; &lt;/ManualValue&gt; &lt;/Destination&gt; &lt;/Output&gt;' +
          '&lt;/JobProcessing&gt;' +
          '&lt;/ScanJobTicket&gt;'

        this.fullFaxTicket = '<ScanJobTicketXmlDocument>' + this.faxTicket + '</ScanJobTicketXmlDocument>'
        return this.submitScanTicket(deviceUrl, this.fullFaxTicket)

      }
    }
  }

  private setEmailAttributes(template) {
    return this.emailContentService.get().pipe(
      map(r => this.updateSmtpFields(template, r))
    )
  }
  private updateSmtpFields(template, emailContent) {
    template = template.replace('&lt;From/&gt;', this.getTag('From', emailContent.sender))
    template = template.replace('&lt;SubjectLine/&gt;', this.getTag('SubjectLine', emailContent.subject))
    template = template.replace('&lt;MessageBody/&gt;', this.getTag('MessageBody', this.getMessageBody(emailContent.body, emailContent.signature)))
    return template
  }
  private getTag(name, value) {
    return `&lt;${name}&gt;${value}&lt;/${name}&gt;`
  }
  private getMessageBody(body, signature) {
    return body + '\r\n\r\n' + signature
  }

  /**
   * @summary If the InitiateScanJob call is failed it will retry for 3 times and throws error.
   * @param deviceUrl 
   * @param template :Job ticket
   * @returns Returns success or failure.
   */
  private submitScanTicket(deviceUrl, template) {
    return this.InitiateScanJob(deviceUrl, template).pipe(retryWhen(
      errors => {
        return errors.pipe(
          scan((count, error) => {
            if (count > oidCodes.retryCount) throw (error);
            this.logService.warn("retry InitiateScanJob attempt: " + count);
            return count + 1;
          }, 1),
          delay(1000),
          tap(() => this.logService.warn("retrying InitiateScanJob..."))
        )
      }
    ))
  }

  /**
   * @summary Initiates the Scan/Fax job.
   * @param deviceUrl
   * @param template :Job ticket
   * @returns Returns success or failure.
   */
  private InitiateScanJob(deviceUrl, template) {
    const result = new Promise(function (resolve, reject) {
      xrxScanV2InitiateScanJob(
        deviceUrl,
        template,
        (env, response) => resolve(xrxScanV2ParseInitiateScanJob(response)),
        (env, response, status) => reject({ env, response, status }), 5
      )
    })
    return from(result)
  }
}
