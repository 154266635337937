
/* --------------------------------------------------------------------------------------
   environment.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// Log Levels:- ALL, DEBUG, INFO, WARN, ERROR, FATAL, OFF

export const environment = {
  production: false,
  deviceUrl: 'http://127.0.0.1',
  debugUrl: 'http://192.168.1.98',
  kioskHub: 'DeviceHub',
  accountUrl: 'http://localhost/acct/get_acct',
  purgeUrl: 'http://localhost/acct/purge',
  logLevel: 'ALL',
  appID: '6209f7fd-0cc1-4a3a-a6f0-d37c8a8f1c41'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production
  mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
