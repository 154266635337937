import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { delay, retryWhen, scan, switchMap, tap } from "rxjs/operators";
import { JobManagementService, oidCodes } from "../shared/eip";
import { EnvironmentService } from "./environment.service";
import { SessionService } from "./session.service";
import { LogService } from "./log.service";
import { timeout } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SecurePrintJobService {

  constructor(
    private http: HttpClient,
    private jobManagementService: JobManagementService,
    private environmentService: EnvironmentService,
    private sessionService: SessionService,
    private logService:LogService
  ) { }

  setSecureJobsList() {
    return this.sessionService.deviceInfo.pipe(
      switchMap(s => this.getSecureJobList(s.serial)));
  }

  public getSecureJobList(serialNumber) {
    return this.jobManagementService.getJobMgmtListActiveSecureJob(this.sessionService.deviceUrl)
      .pipe(switchMap((response: any) => {
        return this.setSecureJobsListAPI(serialNumber, response)
          .pipe(retryWhen(
            errors => {
              return errors.pipe(
                scan((count, error) => {
                  if (count > oidCodes.retryCount) throw (error);
                  this.logService.debug("retry setSecureJobsListAPI attempt: " + count);
                  return count + 1;
                }, 1),
                delay(2000),
                tap(() => this.logService.debug("retrying setSecureJobsListAPI...")))
            }
          ))
      }));
  }

  setSecureJobsListAPI(serialNumber, value) {
    const body = {
      "jobs": value
    }
    const endpoint = this.environmentService.kioskServerUrl + "/api/Job/SetSecureJobsList" + '?deviceID=' + serialNumber
    return this.http.post(endpoint, body, { headers: { 'sessionID': sessionStorage.getItem("sessionID"), 'Content-Type': 'application/json' } }).pipe(timeout(5000));
  }
  resumeSecurePrintJob(jobId, pin, jobType) {
    return this.jobManagementService.resumeSecurePrintJob(this.sessionService.deviceUrl, jobType, jobId, pin);
  }

  deleteSecurePrintJob(jobId, pin, jobType) {
    return this.jobManagementService.deleteSecurePrintJob(this.sessionService.deviceUrl, jobType, jobId, pin);
  }
}
