import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { switchMap, timeout } from 'rxjs/operators';
import { SessionService } from ".";
import { EnvironmentService } from "./environment.service";
import { data } from "jquery";

@Injectable({
  providedIn: 'root'
})
export class DeviceAlertService {
  t1

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private environmentService: EnvironmentService
  ) {}

  setDeviceAlert(requestId){
    return  this.sessionService.deviceInfo.pipe(
      switchMap(s=>this.getDeviceAlert(s.serial,requestId)));
  }

  public getDeviceAlert(serialNumber,requestId) {
    const endpoint = 'http://localhost/get-printer-attributes?attributes-charset=utf-8&attributes-natural-language=%s&document-format=application&requested-attributes=alert-status-col'
    return this.http.get(endpoint, { responseType: 'text' })
      .pipe(switchMap((response: any) => {
        return this.setDeviceAlertAPI(serialNumber, response, requestId);
      }));
  }

  public getDeviceAlertPrintFromPC() {
    const endpoint = 'http://localhost/get-printer-attributes?attributes-charset=utf-8&attributes-natural-language=%s&document-format=application&requested-attributes=alert-status-col'
    return this.http.get(endpoint, { responseType: 'text' })
      .pipe(switchMap((response: any) => {
        return response
      }));
  }

  setDeviceAlertAPI(serialNumber,value, requestId) {
    this.t1 = Date.now()
    const endpoint = this.environmentService.kioskServerUrl + "/api/Kiosk/SetDeviceAlerts" + '?deviceID=' + serialNumber + '&requestID=' + requestId
    return this.http.post(endpoint, value, { headers: { 'sessionID': sessionStorage.getItem("sessionID"), 'Content-Type': 'application/xml' } }).pipe(timeout(5 * 1000));
  }
}
