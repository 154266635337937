
/* --------------------------------------------------------------------------------------
   friendly-status.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { SessionService, JobService } from '.';
import { JobType } from '../app.types';

@Injectable({
    providedIn: 'root'
})
export class FriendlyStatusService {
    private deviceModel: string;
    private lastJobStatus: string = 'computingCost'

    constructor(
      private sessionService: SessionService,
      private jobService: JobService
    ) {
        this.sessionService.getDeviceInfo().subscribe(deviceInfo => {
            this.deviceModel = deviceInfo.model.includes('AltaLink') ? 'AltaLink' : 'VersaLink';
        });
    }

    get(jobStatus) {
        if (!jobStatus.status) {
            switch (jobStatus.jobType) {
                case JobType.Scan:
                    return 'MODAL.SCANNING'
                case JobType.Print:
                    return 'MODAL.PRINTING'
                case JobType.Copy:
                    return this.deviceModel == 'AltaLink' ? 'MODAL.PROCESSING' : 'MODAL.SCANNING'
                case JobType.SecurePrint:
                    return 'MODAL.SECUREPRINTING'
            }
        } else if (jobStatus.status.includes('computingCost') || (jobStatus.status.includes('PendingHeld') && !this.jobService.mobileJobWaitingForApproval && !this.jobService.overallStatus.toBeReleased) || jobStatus.status.includes('Converting')) {
            return 'MODAL.CALCULATING_PAYMENT'
        } else if (jobStatus.status.includes('PendingHeld') && this.jobService.mobileJobWaitingForApproval && !jobStatus.isReleased && !jobStatus.cancelled) {
            this.lastJobStatus = jobStatus.status
            return 'MODAL.WAITING_FOR_APPROVAL'
        } else if (jobStatus.cancelled || jobStatus.status.includes('Aborted')) {
            return 'MODAL.CANCELLED'
        } else if ((jobStatus.status.includes('Processing') && !this.lastJobStatus.includes('computingCost')) || (jobStatus.status.includes('PendingHeld') && !this.lastJobStatus.includes('computingCost'))) {
            switch (jobStatus.jobType) {
                case JobType.Scan:
                    return (jobStatus.isReleased || jobStatus.reasons == 'Transferring') ? 'MODAL.SENDING' : 'MODAL.SCANNING'
                case JobType.Print:
                    return 'MODAL.PRINTING'
                case JobType.Copy:
                    return this.deviceModel == 'AltaLink' ? 'MODAL.PROCESSING' : ((jobStatus.isReleased || jobStatus.reasons == 'Printing') ? 'MODAL.PRINTING' : 'MODAL.SCANNING')
                case JobType.Fax:
                    return (jobStatus.isReleased || jobStatus.reasons == 'Transferring') ? 'MODAL.SENDING' : 'MODAL.SCANNING'
                case JobType.SecurePrint:
                    return 'MODAL.PRINTING'
            }
        } else if (jobStatus.status.includes('Completed')) {
            return 'MODAL.JOB_COMPLETED'
        }
        else {
            return jobStatus.status
        }
    }
}
