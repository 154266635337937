import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { SnmpService } from 'src/app/shared/eip/snmp.service';
import { EnergysaverConfigService } from 'src/app/services/energysaver-config.service';
import { oidCodes } from 'src/app/shared/eip';
import { SessionService, DeviceConfigService } from 'src/app/services';
declare var $: any;

@Component({
  selector: 'app-check-kiosk-settings',
  templateUrl: './check-kiosk-settings.component.html',
  styleUrls: ['./check-kiosk-settings.component.scss']
})
export class CheckKioskSettingsComponent implements OnInit, OnDestroy {

  settingsForm: FormGroup;
  isAuthenticationError = false;
  isSNMPError = false;
  getAdminUserName: boolean = false
  getCredentials: boolean = false
  getPassword: boolean = false
  title: string
  description: string
  
  private infoRequired: boolean = false
  private settingsMismatch: boolean = false
  private logMsg = []
  private errorLog = []

  @ViewChild('device_admin_password', { static: true }) deviceAdminPassword: ElementRef;
  @ViewChild('device_admin_name', { static: true }) deviceAdminName: ElementRef;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private sessionService: SessionService,
    private energysaverConfigService: EnergysaverConfigService,
    private snmpService: SnmpService,
    private deviceConfigService: DeviceConfigService

  ) {
    this.formValidation();
  }

  ngOnInit() {
    this.sessionService.progressIndicator.next(20)
    this.snmpService.checkKioskSettings = true
    this.sessionService.getKioskSettings()
    this.sessionService.onSubmitNotification.subscribe(() => this.onSubmit())
    $('.xrx-switch').switch();
    //when there is no value in the localStorage
    if (this.sessionService.infoRequired) {
      this.sessionService.progressIndicator.next(25)
      this.infoRequired = true
      this.logInLocalStorage("Kiosk settings is empty prompting user for username, password")
      this.promptAdminUserNameandPassword()
      //this.checkSNMPstring(false)
    }
    else {
      this.sessionService.progressIndicator.next(27)
      this.settingsMismatch = true
      if(!this.sessionService.userName) this.sessionService.userName = "admin"
      this.checkKioskSettings(false)
    }
  }

  /**
  * This function Validates the admin user name and password after clicking OK.
  */
  onSubmit() {
    var snmpWriteString;
    if (this.settingsForm.controls['deviceAdminPassword'].valid || this.settingsForm.controls['deviceAdminName'].valid) {
      var admin = this.settingsForm.get('deviceAdminName').value;
      var adminPassword = this.settingsForm.get('deviceAdminPassword').value;
 
      if (!this.sessionService.snmpWriteString) snmpWriteString = 'private'
      if (this.sessionService.snmpWriteString) snmpWriteString = this.sessionService.snmpWriteString
      if (!adminPassword) adminPassword = this.sessionService.password

      var settings = {
        admin: admin,
        password: adminPassword,
        writeString: snmpWriteString,
        readString: snmpWriteString
      }
      localStorage.setItem('kioskSettings', JSON.stringify(settings))
      this.sessionService.getKioskSettings()
      this.checkKioskSettings(true)
    }
    else {
      Object.keys(this.settingsForm.controls).forEach(field => {
        const control = this.settingsForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  onEnterKeyClicked(keyboardEvent: KeyboardEvent): void {
    switch (keyboardEvent.currentTarget) {
      case this.deviceAdminPassword.nativeElement:
        this.deviceAdminName.nativeElement.focus();
        break;
    }
  }

  /**
  * This function displays the error message when admin user name or password is incorrect after clicking OK.
  * @param {boolean}  passwordIncorrect   Make it true when password is incorrect.
  * @param {boolean}  stringIncorrect     Make it true when admin user name string is incorrect.
  */
  private validationFailed(passwordIncorrect: boolean, stringIncorrect: boolean) {
    if (passwordIncorrect && this.getPassword) this.isAuthenticationError = true
    if (stringIncorrect && this.getAdminUserName) this.isAuthenticationError = true
  }

  /**
  * This function Validates the password.
  * @param {boolean}  afterSubmit Make it true while calling checkKioskSettings after clicking ok.
  */
  private checkKioskSettings(afterSubmit: boolean) {
    this.deviceConfigService.checkAuthentication(this.sessionService.password).toPromise().then(
      (result) => {
        this.snmpService.snmpGet(this.sessionService.deviceUrl, oidCodes.systemTimeoutFromCWIS).toPromise().then(
          (res) => {
            this.sessionService.progressIndicator.next(30)
            this.logInLocalStorage("SNMPget successful for system timer value = " + res.returnValue)
            localStorage.setItem('SystemTimer', res.returnValue)
            // To get SystemTimer value from CWIS -- ends here
            this.energysaverConfigService.setSystemTimer(20, true);
          },
          (onRejected) => {
            this.energysaverConfigService.setSystemTimer(20, true);
            this.errorLogger("check-kiosk-settings: SNMP get failed for system timer, snmpReadString = " + this.sessionService.snmpReadString, JSON.stringify(onRejected))
          })
      },
      (error) => {
        var errorReason = this.deviceConfigService.parseErrorResponse(error);
        this.errorLogger("Error in check-kiosk-settings error: " , JSON.stringify(error))

        if(errorReason == "FailedAuthentication" && !afterSubmit) {
          this.getCredentials = true
          this.getAdminUserName = true
          this.getPassword = true
          this.title = 'KIOSK_ERROR.TITLE.SETTINGS_MISMATCH'
          this.description = 'CHECK_KIOSK_SETTINGS.PASSWORD_MISMATCH'
        }
        if (afterSubmit) {
          this.validationFailed(true, false)
        }

      }
    )

    this.energysaverConfigService.setSysTimerSuccess.subscribe(() => {
      this.sessionService.progressIndicator.next(35)
      this.router.navigate(['landing'], { skipLocationChange: true })
    })

    this.energysaverConfigService.setSysTimerFailed.subscribe((res) => {
      this.errorLogger("check-kiosk-settings: SNMP set failed for system timer, snmpWriteString = " + this.sessionService.snmpWriteString + ", Password = " + this.sessionService.password, JSON.stringify(res))
      this.router.navigate(['landing'], { skipLocationChange: true })
    })
  }

  formValidation() {
    this.settingsForm = this.fb.group({
      deviceAdminPassword: ['', Validators.required],
      deviceAdminName: ['', Validators.required]
    })
  }

  /**
   * This function prompt the user for admin user name and password.
   */
  private promptAdminUserNameandPassword() {
    this.getCredentials = true
    this.getAdminUserName = true
    this.getPassword = true
    this.title = 'CHECK_KIOSK_SETTINGS.INFO_REQUIRED'
    this.description = 'CHECK_KIOSK_SETTINGS.INFO_REQUIRED_DESC'
  }

  /**
   * Stores the log messages in local storage
   * @param msg log message
   */
  private logInLocalStorage(msg) {
    var errLog = [msg]
    this.logMsg.push(errLog)
    localStorage.setItem("checkKioskSettingsLogs", JSON.stringify(this.logMsg))
  }

  /**
   * Stores the error log messages in local storage
   * @param msg log message
   * @param exception exception message
   */
  private errorLogger(msg, exception?) {
    var data = {
      DeviceTime: Date.now(),
      Message: msg,
      ExceptionMessage: (exception) ? exception : "null"
    }
    this.errorLog.push(data)
    localStorage.setItem("checkKioskSettingsErrorLogs", JSON.stringify(this.errorLog))
  }

  ngOnDestroy(): void { }

}
