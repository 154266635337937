/* --------------------------------------------------------------------------------------
   eip.const.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export const eipconst = {
    XRX_SOAPSTART: '<?xml version="1.0" encoding="UTF-8"?>' +
                        '<SOAP-ENV:Envelope' +
                        ' xmlns:SOAP-ENV="http://www.w3.org/2003/05/soap-envelope"' +
                        ' xmlns:SOAP-ENC="http://www.w3.org/2003/05/soap-encoding"' +
                        ' xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"' +
                        ' xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
                        ' <SOAP-ENV:Body>',
    XRX_SOAP11_SOAPSTART: '<?xml version="1.0" encoding="utf-8"?>'
                        + '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" '
                        + 'xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:wsa="http://schemas.xmlsoap.org/ws/2004/08/addressing" '
                        + 'xmlns:wsse="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd" '
                        + 'xmlns:wsu="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd">'
                        + '<soap:Body>',
    XRX_SOAPEND: '</SOAP-ENV:Body></SOAP-ENV:Envelope>',
    XRX_SOAP11_SOAPEND: '</soap:Body></soap:Envelope>'

}

export const RestrictedUsers = ["EIP User", "Guest User"];
export const EnergySaver = { Scheduled: "Scheduled" }
export const SysTimerDiscovery = { MinThreshold : 3480 , MaxThreshold : 3600 };