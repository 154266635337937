import { Injectable } from '@angular/core';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { LogService, SessionService } from '.';
import { JobType } from '../app.types';
import { JobManagementService, PrintService } from '../shared/eip';

@Injectable({
  providedIn: 'root'
})
export class ReceiptService {
  receipt: Subject<any> = new Subject<any>()
  private statusTimer$: Observable<number> = interval(1000)
  private receiptJob$: Observable<any> = new Observable<any>()
  private pollJobStatusSubscription: Subscription
  private jobType: JobType
  private jobId: string

  constructor(
    private printService: PrintService,
    private sessionService: SessionService,
    private jobManagementService: JobManagementService,
    private logService: LogService,
  ) { }

  printReceipt(jobType: JobType, template: string, url: string, deviceUrl: string) {
    this.receiptJob$ = this.printService.print(deviceUrl, url, template)
    this.receiptJob$.subscribe(
      jobId => this.followReceiptStatus(jobId, jobType),
      error => this.handleReceiptError(jobType, error, 'Receipt job', template))
  }

  /**
   * @summary This function polls the getJobDetails every second and returns the receipt status.
   * @param jobId
   * @param jobType
   * @throws If error does not include "TIMEOUT" or empty value. This function throws error to "updateReceiptCurrentStatus"
   * @returns Returns receipt status
   */
  private followReceiptStatus(jobId: string, jobType: JobType) {
    let processingJobId
    // For Alexandra JobId conversion is not required
    if (jobId.includes("job:")) {
      this.jobId = jobId
    }
    // For Fuji controller devices JobId conversion is required
    else {
      processingJobId = parseInt(jobId, 16)
      this.jobId = processingJobId.toString()
    }
    this.pollJobStatusSubscription = this.pollDeviceJobStatus(jobId, jobType)
      .subscribe({
        next: (status: any) => this.updateReceiptCurrentStatus(status.status, status.reasons),
        error: error => {
          if ((error == undefined) || this.parseTimeoutError(error).includes("TIMEOUT") || (error == "")) {
            setTimeout(()=>this.updateReceiptCurrentStatus('Completed', "JobCompletedSuccessfully"), 2000)
          }
          else this.updateReceiptCurrentStatus('Completed', error.reasons)
          this.logService.error("Error occured in followReceiptStatus error=", JSON.stringify(error))
        }
      })
    return this.pollJobStatusSubscription
  }

  private pollDeviceJobStatus(jobId: string, jobType: JobType) {
    return this.statusTimer$.pipe(
      flatMap(() => this.jobManagementService.getJobDetails(
        this.sessionService.deviceUrl, 'Print', jobId))
    )
  }

  private updateReceiptCurrentStatus(status, reason) {
    if (status == 'Completed') {
      this.pollJobStatusSubscription.unsubscribe()
      this.receipt.next({ status: 'Completed' ,reason: reason})
    } else if (status == 'error') {
        this.receipt.next({ status: 'error' })
    }
  }

  private xrxCancelJob(jobId) {
    this.logService.debug('JobService: xrxCancelJob trigger')
    this.jobManagementService.cancelJob(this.sessionService.deviceUrl, 'Print', jobId).subscribe({
      error: error => this.handleReceiptError(this.jobType, error, 'XRX Cancelling receipt job')
    })
  }

  private handleReceiptError(jobType, error, deviceStatus?, template?) {
    let errorDetail = {
      jobType: jobType,
      deviceStatus: deviceStatus,
      error: error,
      template: template
    }
    this.logService.error('ReceiptService handleError ', JSON.stringify(errorDetail))
    this.receipt.next({ status: 'error' })
  }

  /**
   * 
   * @param errResponse Error response
   * @returns Returns the parsed response
   */
  private parseTimeoutError(errResponse): string {
    var response = JSON.stringify(errResponse)
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(response, 'application/xml');

    // Get the error message from the parsed XML
    const errorNode = xmlDoc.querySelector('comm_error');

    if (errorNode) {
      var resp = errorNode.textContent || '';
      return resp
    } else {
      return 'Error node not found in XML response';
    }
  }
}
