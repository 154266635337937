/* --------------------------------------------------------------------------------------
   thankyou-model.component.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Component, OnInit ,ViewChild,Output,EventEmitter, Input} from '@angular/core';
import { NgbModal,NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
declare function ExitCUIMode();

@Component({
  selector: 'app-thankyou-modal',
  templateUrl: './thankyou-modal.component.html',
  styleUrls: ['./thankyou-modal.component.scss']
})

export class ThankyouModalComponent implements OnInit {
@ViewChild('content', { static: false }) content;

@Output() dismiss = new EventEmitter();
@Output() closed = new EventEmitter();

@Input() title: string;
@Input() description: string;
@Input() closeText: string;
@Input() showClose = true;
@Input() showTitle = true;
@Input() showDescription = false;
@Input() receiptImage = false;
@Input() descriptionSize = false;
@Input() loadingIcon = false;

private ref: NgbModalRef;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  openWithContent(title:string, description:string) {
    this.title = title
    this.description = description;
    this.ref = this.modalService.open(this.content, { ariaLabelledBy: 'modal-basic-title', centered: true});

    this.ref.result.then((result) => {
      this.closed.emit(result);
    }, (reason) => {
      this.dismiss.emit(reason);
    });
  }

  close() {
    if (this.ref) {
      this.ref.close();
    }
  }

  exitApp() {
    try {
      ExitCUIMode()
    } catch {
      console.log("Exit CUI embedded function not found, switching to empty state instead")
      window.location.href= "about:blank"
    }
  }
}
