export const supportedCountries = [
  'en', 'es', 'de', 'it', 'fr', 'nl', 'fi', 'sk'
]

export const supportedCountryCodes = {
  default: 'en',
  Spain: 'es',
  German: 'de',
  Italy: 'it',
  French: 'fr',
  Netherlands: 'nl',
  Finland: 'fi',
  Slovak: 'sk'
}
