/* --------------------------------------------------------------------------------------
   kiosksession-request.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { switchMap, map, catchError, delay, retryWhen, scan, tap } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class KiosksessionRequestService {

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private logService: LogService
    ) { }

  doKioskSessionRequestEnded(deviceId: string, sessionId: string): Observable<any> {
    const endpoint = this.environmentService.kioskServerUrl + '/JobLimitsAppServer.asmx';
    return this.http.post(endpoint, this.getRequestPayload('ended', deviceId),
      {
        headers: {
          soapaction: 'http://xml.namespaces.xerox.com/enterprise/JobLimitsDeviceClient/1/KioskSession',
          'content-type': 'text/xml; charset=UTF-8',
          'sessionID': sessionId
        },
        responseType: 'text'
      }
    )
  }

  doKioskSessionRequestInitialize(deviceId: string): Observable<any> {
    const endpoint = this.environmentService.kioskServerUrl + '/JobLimitsAppServer.asmx';
    const headers = {
      soapaction: 'http://xml.namespaces.xerox.com/enterprise/JobLimitsDeviceClient/1/KioskSession',
      'content-type': 'text/xml; charset=UTF-8',
      'PBSecretKey': this.environmentService.pbSecretKey
    }
    return this.http.post(endpoint, this.getRequestPayload('initialize', deviceId),
      {
        headers: headers,
        responseType: 'text'
      }
    ).pipe(map(res => {
      return { sessionKey: this.parseSessionKey(res), sessionID: this.parseSessionId(res) }
    })).pipe(catchError(err => this.handleConfigError(err)))
  }

  private getRequestPayload(request: string, deviceId: string): string {
    if (request == 'ended') {
      return '<?xml version="1.0" encoding="utf-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"  xmlns:tns="http://www.xerox.com/webservices/JobLimitsDeviceClient/1" xmlns:tm="http://microsoft.com/wsdl/mime/textMatching/"><soap:Body><KioskSessionRequest xmlns="http://www.xerox.com/webservices/JobLimitsDeviceClient/1"><DeviceId>' + deviceId + '</DeviceId><SessionKeyword>ended</SessionKeyword></KioskSessionRequest></soap:Body></soap:Envelope>';
    }
    return '<?xml version="1.0" encoding="utf-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"  xmlns:tns="http://www.xerox.com/webservices/JobLimitsDeviceClient/1" xmlns:tm="http://microsoft.com/wsdl/mime/textMatching/"><soap:Body><KioskSessionRequest xmlns="http://www.xerox.com/webservices/JobLimitsDeviceClient/1"><DeviceId>' + deviceId + '</DeviceId><SessionKeyword>initialize</SessionKeyword><sessionType>paid</sessionType></KioskSessionRequest></soap:Body></soap:Envelope>';
  }

  private parseSessionKey(body: string) {
    return body
      .split("sessionKey")[1]
      .replace('&gt;', '')
      .replace('&lt;/', '');
  }

  private parseSessionId(body: string) {
    return body
      .split("sessionID")[1]
      .replace('&gt;', '')
      .replace('&lt;/', '');
  }

  private handleConfigError(error) {
    const err = JSON.stringify(error)
    const errorMessage = err
      .split("ErrorMessage")[1]
      .replace('>', '')
      .replace('</', '');
    switch (errorMessage) {
      case 'deviceNotRegistered':
        return throwError('deviceNotOnboarded');
      case 'countryNotSupported':
        return throwError('countryNotSupported')
      case 'hostAccountNotSpecified':
        return throwError('hostAccountNotSpecified');
      case 'kioskNotOnBoarded':
        return throwError('kioskNotOnBoarded');
      default:
        return throwError(error)
    }
  }

  public getCarouselImageNames() {
    return this.http.get('/carouselimagenames');
  }
}
