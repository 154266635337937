
/* --------------------------------------------------------------------------------------
   kiosk-status.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, of, throwError } from 'rxjs';
import { flatMap, retryWhen, timeout } from 'rxjs/operators';
import { JobType } from '../app.types';
import { EnvironmentService } from './environment.service';

@Injectable({
    providedIn: 'root'
})
export class KioskStatusService {
    private deviceId;
    constructor(
        private http: HttpClient,
        private environmentService: EnvironmentService
    ) {
    }

    updateKioskStatus(jobStatus) {
        const { status, reasons, jobType } = jobStatus;
        const state = this.getKioskJobState(status, reasons, jobType)
        let jobStateReason;
        if ((state == 'deviceFaultCleared' || state == 'jobProcessing') && (reasons == 'CancelByUser' || reasons == 'CauseBySystem')) {
            jobStateReason = null
        } else {
            jobStateReason = (status == 'PendingHeld' || status == 'ProcessingStopped') ? status : reasons;
        }
        const stateDetails = {
            'jobName': 'PortBay Job',
            'jobType': this.getKioskJobTypeString(jobType),
            'jobSource': 'mobile',
            'jobStateReason': jobStateReason
        }
        const kioskStatusUpdate = this.updateKioskStatusRest(state, stateDetails);
        return kioskStatusUpdate
    }
    getKioskJobState(deviceJobStatus: string, reasons: string, jobType: JobType) {
        if (deviceJobStatus == 'computingCost' || reasons == 'WaitingForSide2') return 'computingCost'
        if (deviceJobStatus == 'Completed') return 'jobComplete'
        if (deviceJobStatus == 'deviceFault') return deviceJobStatus
        if (deviceJobStatus == 'deviceFaultCleared') return 'deviceFaultCleared'
        return 'jobProcessing'
    }
    getKioskJobTypeString(jobType: JobType) {
        return JobType[jobType].toLowerCase()
    }

    setDeviceId(deviceId: string) {
        this.deviceId = deviceId
    }

    updateKioskStatusRest(state, stateDetails) {
      const endpoint = this.environmentService.kioskServerUrl + "/api/Kiosk/UpdateKioskStatus" + '?deviceID=' + this.deviceId + '&state=' + state
      const payload = JSON.stringify(stateDetails)
      return this.http.post(endpoint, payload,
          {
              headers: { 'sessionID': sessionStorage.getItem("sessionID"), 'Content-Type': 'application/json' }
          }).pipe(timeout(10 * 1000))
          .pipe(retryWhen(_ => {
              return interval(1000).pipe(
                  flatMap(count => count == 3 ? throwError("updateKioskStatus call failed") : of(count))
              )
          }))
    }
}
