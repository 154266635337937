
/* --------------------------------------------------------------------------------------
   signalr.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { from, Subject } from 'rxjs';
import { switchMap, retryWhen, scan, tap, delay } from 'rxjs/operators';
import { DeviceInfoService } from '../shared/eip';
import { JobService } from './job.service';
import { ReceiptService } from './receipt.service';
import { JobType } from '../app.types';
import { Router } from '@angular/router';
import { SessionService, CheckoutService, KiosksessionRequestService } from '.';
import { LogService } from './log.service';
import { DeviceConfigService } from './device-config.service';
import { KioskStatusService } from './kiosk-status.service';
import { DeviceAlertService } from './device-alert.service';
import { EnvironmentService } from './environment.service';
import { SecurePrintJobService } from './secure-print-job.service';
import { EnergysaverConfigService } from './energysaver-config.service';
import { SnmpService } from '../shared/eip/snmp.service';
import { oidCodes } from '../shared/eip/constants/oid-codes.const';
import { PwaSessionService } from '.';

declare var $: any;
declare function EipGetHeapAvailable()
declare function EipGetHeapAllocated()

@Injectable({
  providedIn: 'root'
})

export class SignalrService {
  hubProxy: SignalR.Hub.Proxy;
  serial: string
  sessionId: string
  registrationFailureNotification: Subject<any> = new Subject<any>()
  sessionNotification: Subject<any> = new Subject<any>()
  webSocketNotificationLanding: Subject<any> = new Subject<any>()
  webSocketNotificationMain: Subject<any> = new Subject<any>()
  verifyKioskNotification: Subject<any> = new Subject<any>()
  connection: SignalR.Hub.Connection;
  isDisconnected: boolean = false;
  isSessionStarted: boolean = false;
  isSignalRStarted: boolean = false;
  connectionId: string;
  index: number;
  lastAcceptedMsgIndex: number = 0;
  isAppSuspended: boolean = false;

  constructor(
    private router: Router,
    private jobService: JobService,
    private sessionService: SessionService,
    private checkoutService: CheckoutService,
    private kiosksessionRequestService: KiosksessionRequestService,
    private logService: LogService,
    private deviceConfigService: DeviceConfigService,
    private receiptService: ReceiptService,
    private kioskStatusService: KioskStatusService,
    private deviceAlertService: DeviceAlertService,
    private environmentService: EnvironmentService,
    private securePrintJobService: SecurePrintJobService,
    private energysaverConfigService: EnergysaverConfigService,
    private snmpService: SnmpService,
    private pwaSessionService: PwaSessionService
  ) { 
  }

  init(sessionId: string) {
    this.checkoutService.setSessionId(sessionId)
    this.sessionId = sessionId
    return from(this.getHubProxy(sessionId))
      .pipe(
        switchMap(() => this.startConnection()),
        switchMap(() => this.sessionService.getDeviceInfo()),
        switchMap(info => this.registerConnection(info.serial))
      )
  }

  startConnection() {
    this.hubProxy.on('loginUser', (userID, isStaff, index) => {
      if (!this.sendAckAndCheckForDuplicate(index)) {
        this.onLogin()
      }
    })
    return this.hubProxy.connection.start().done(() => {
        this.connectionId = this.hubProxy.connection.id
        this.isSignalRStarted = true
    })
  }

  stopConnection() {
    this.isSignalRStarted = false
    return this.hubProxy.connection.stop()
  }

  registerConnection(serial: string) {
    this.checkoutService.setDeviceId(serial)
    this.kioskStatusService.setDeviceId(serial)
    this.logService.setSerialNumber(serial)
    this.attachHubMethods(serial)
    return this.hubProxy.invoke('registerConnection', serial)
  }

  attachHubMethods(serial: string) {
    this.serial = serial
    this.hubProxy.on('submitCopyJob', (template, index) => {
      if (!this.sendAckAndCheckForDuplicate(index))
        this.jobService.startJob(JobType.Copy, template)
    })
    this.hubProxy.on('submitScanJob', (template, index) => {
      if (!this.sendAckAndCheckForDuplicate(index)) {
        this.jobService.startJob(JobType.Scan, template)
      }
    })
    this.hubProxy.on('submitPrintJob', (url, template, index) => {
      if (!this.sendAckAndCheckForDuplicate(index)) {
        this.doSubmitPrintJob(url, template)
      }
    })
    this.hubProxy.on('submitFaxJob', (template, index) => {
      if (!this.sendAckAndCheckForDuplicate(index))
        this.jobService.startJob(JobType.Fax, template)
    })
    this.hubProxy.on('releasePausedJob', (jobID, jobType, jobToken, index) => {
      if (!this.sendAckAndCheckForDuplicate(index)) {
        this.jobService.releaseJob(jobID, jobType, jobToken)
      }
    })
    this.hubProxy.on('cancelPausedJob', (jobID, jobType, jobToken, index) => {
      if (!this.sendAckAndCheckForDuplicate(index)) {
        this.jobService.cancelJob(jobID, jobType, jobToken)
      }
    })
    this.hubProxy.on('appEvent', (result, eventDetails, index) => {
      if (!this.sendAckAndCheckForDuplicate(index)) {
        this.appEventHandler(result, eventDetails)
      }
    })
    this.hubProxy.on('logout', (index) => {
      if (!this.sendAckAndCheckForDuplicate(index)) {
        this.setLogout()
      }
    })
    this.hubProxy.on('sendTrayInfo', (index) => {
      if (!this.sendAckAndCheckForDuplicate(index)) {
        this.setTrayInfo()
      }
    })
    this.hubProxy.on('sendDeviceConfig', (index) => {
      if (!this.sendAckAndCheckForDuplicate(index)) {
        this.doSendDeviceConfig()
      }
    })
    this.hubProxy.on('sendDeviceAlerts', (requestID, index) => {
      if (!this.sendAckAndCheckForDuplicate(index)) {
        this.doSetDeviceAlert(requestID)
      }
    })
    this.hubProxy.on('sendSecurePrintJobsList', (index) => {
      if (!this.sendAckAndCheckForDuplicate(index)) {
        this.doSecurePrintJobList()
      }
    })
    this.hubProxy.on('ReleaseSecurePrintJob', (jobId,pin,index) => {
      if (!this.sendAckAndCheckForDuplicate(index)) {
        this.jobService.setsecureJobPin(pin);
        this.releaseSecureJob(jobId,pin)
      }
    })
    this.hubProxy.on('DeleteSecurePrintJob', (jobId,pin,index) => {
      if (!this.sendAckAndCheckForDuplicate(index)) {
        this.jobService.setsecureJobPin(pin);
        this.deleteSecurePrintJob(jobId,pin)
      }
    })
  }

  getHubProxy(sessionId: string) {
    const result = new Promise((resolve, _) => {
      const signalRUrl = this.environmentService.kioskServerUrl + '/signalr'
      this.connection = $.hubConnection(signalRUrl, { useDefaultPath: false })
      this.hubProxy = this.connection.createHubProxy(environment.kioskHub)
      this.connection.qs = { sessionID: sessionId }
      resolve(this.hubProxy);
    })
    this.connection.connectionSlow(() => {
      this.captureWebSocketEvent("connectionSlow")
    })
    this.connection.stateChanged((change) => {
      if (change.newState === $.signalR.connectionState.reconnecting) {
        this.captureWebSocketEvent("reconnecting")
      }
      else if (change.newState === $.signalR.connectionState.connected) {
        this.captureWebSocketEvent("connected")
        if (this.isDisconnected) {
          this.isDisconnected = false
          this.handleWebSocketReconnection()
        }
      }
      else if (change.newState === $.signalR.connectionState.disconnected) {
        this.captureWebSocketEvent("disconnected")
        this.isDisconnected = true
        if(!this.isAppSuspended){
          setTimeout(() => this.handleDisconnection(), 5000)
        }
      }
    })
    this.connection.reconnected(() => {
      this.captureWebSocketEvent("reconnected")
      this.handleWebSocketReconnection()
    })
    return from(result)
  }

  handleDisconnection() {
    this.hubProxy.connection.start()
    // Reloading the app when the signalr is reconnected only outside the session and not inside the session.
    if(!this.isSessionStarted && !this.sessionService.isSessionStarted)
      this.reloadApp();
  }

  handleWebSocketReconnection() {
    this.hubProxy.invoke('registerConnection', sessionStorage.getItem("serialNumber")).done((res) => {
      // registration fails so send a notification to sessionerrorhandler
      if(res != 'success'){
        this.registrationFailureNotification.error(Error(res))
      }
    })
  }

  captureWebSocketEvent(event) {
    this.logService.debug('SignalrService: ' + event + ' occurred connectionId = ' + this.connectionId)
    if (this.isSessionStarted) {
      this.webSocketNotificationMain.next(event)
    }
    else {
      this.webSocketNotificationLanding.next(event)
    }
  }

  doSubmitPrintJob(url, template) {
    if (!this.sessionService.getSessionState().checkoutInitiated)
      this.jobService.startJob(JobType.Print, template, url)
    else {
      this.sessionService.setPrintJobSubmiited()
      this.receiptService.printReceipt(JobType.Print, template, url, this.sessionService.deviceUrl)
    }
  }

  private onLogin() {
    this.kioskStatusService.updateKioskStatusRest('waitingForUser', {}).subscribe();
  }

  private onSessionStarted() {
    //user session starts here; After scanning qr code
    this.router.navigate(['main'], { skipLocationChange: true })
    this.isSessionStarted = true;
    this.sessionService.isSessionStarted = true;
    if (!this.sessionService.isSessionEnded) {
      this.energysaverConfigService.startSystemTimer();
    }
  }

  private onPrintJobSubmission() {
    this.sessionService.setConvertionStarted()
    this.jobService.startPrintJobConversion()
  };

  appEventHandler(event, eventDetails) {
    switch (event) {
      case 'webAppDisconnected':
        this.sessionService.setWebAppDisconnected()
        break;
      case 'webAppConnected':
        this.sessionService.setWebAppConnected()
        break;
      case 'kioskNotAvailable':
        this.verifyKioskNotification.next(eventDetails)
        break;
      case 'checkoutCompleted':
        setTimeout(()=> this.endSession(this.serial), 5000)
        break;
      case 'checkoutInitiated':
        this.sessionService.setInitiateCheckoutStarted()
        this.sessionService.setCheckoutDetails(eventDetails)
        break;
      case 'sessionStarted':
        this.kioskStatusService.updateKioskStatusRest('sessionStarted', {}).subscribe()
        this.onSessionStarted()
        break;
      case 'printJobSubmissionInProgress':
        this.onPrintJobSubmission();
        break;
      case 'printJobSubmissionAborted':
        this.sessionService.setPrintJobSubmissionAborted(eventDetails);
        this.jobService.printJobConversionError();
        break;
      case 'mobileJobWaitingForApproval':
        this.logService.debug("Received AppEvent " + event)
        this.jobService.mobileJobWaitingForApproval = true
        break;
    }
  }

  endSession(serial: string) {
    var heapAvailable = EipGetHeapAvailable() / 1000000
    this.logService.info("signal.service: endSession heapAvailable = " + heapAvailable)
    this.sessionService.isSessionEnded = true     //When user clicks on checkout in PWA
    let getSystemTimer = localStorage.getItem('SystemTimer');     //Get SystemTimer from localStorage
    this.logService.debug("SystemTimer value after user session ended is " + getSystemTimer)
    this.energysaverConfigService.setSystemTimer(getSystemTimer)
    // If it is discovery device and available memory availble is lesss than memoryThreshold we will display the message and restart MFD.
    if ((this.pwaSessionService.GetDeviceControler() == "Discovery Controller") && (heapAvailable < this.environmentService.memoryThreshold) && (heapAvailable != 0)) {
      this.sessionNotification.next("lowMemory")
      this.logService.error("Available memory ("+heapAvailable+")MB is lesss than the threshold value ("+this.environmentService.memoryThreshold+")MB. Restarting the device", "")
      this.kiosksessionRequestService.doKioskSessionRequestEnded(serial, this.sessionId).subscribe()
      this.hubProxy.invoke('removeConnection', serial).done(() => {
        this.hubProxy.connection.stop()
        setTimeout(() => this.deviceConfigService.restartMFD(this.sessionService.snmpWriteString), 5000)
      })
      return
    }
    else if (this.sessionService.getSessionState().logout) {
      this.sessionNotification.next("abnormal")
    }
    else if (!this.sessionService.getSessionState().isPrintJobSubmitted) {
      this.sessionNotification.next("endSession")
    }

    if (!this.sessionService.getSessionState().isPrintJobSubmitted) {
      this.triggerEndSessionRequest(serial)
    }
  }

  triggerEndSessionRequest(serial) {
      this.kiosksessionRequestService.doKioskSessionRequestEnded(serial, this.sessionId).subscribe()
      this.hubProxy.invoke('removeConnection', serial).done(() => {
        this.hubProxy.connection.stop()
        this.reloadApp()
      })
  }

  private reloadApp() {
    this.logService.debug('SignalrService: reloading the App')
    setTimeout(() => location.reload(),5000)
  }

  private setLogout() {
    this.sessionService.setLogoutInitiate()
    if (this.sessionService.getConversionStarted()) {
      this.jobService.cancelPrintJobConversion()
    }
  }
  private setTrayInfo() {
    this.deviceConfigService.setTrayInformationAPI().subscribe(
      {
        next: n => this.logService.debug('setTrayInfo response: ' + n),
        error: e => {
          this.sessionService.isSetNewTrayValue = false
          this.logService.debug('setTrayInfo error: ' + e)
        }
      }
    )
  }

  private doSendDeviceConfig(){
    this.deviceConfigService.doSetDeviceConfig()
    .subscribe({
      next: n => this.logService.debug('setDeviceConfig response: ' + n),
      error: e => this.logService.debug('setDeviceConfig error: ' + e)
    })
  }

  private doSetDeviceAlert(requestId) {
    this.deviceAlertService.setDeviceAlert(requestId)
      .subscribe({
        next: n => this.logService.debug('setDeviceAlert response: ' + n),
        error: e => this.logService.debug('setDeviceAlert error: ' + e)
      })
  }

  private sendAckAndCheckForDuplicate(msgIndex) {
    this.sendMsgACK(msgIndex)
    if (msgIndex > this.lastAcceptedMsgIndex) {
      this.lastAcceptedMsgIndex = msgIndex
      return false
    }
    return true
  }

  private sendMsgACK(msgIndex) {
    var currentTime = new Date().toISOString().replace("T", " ").replace("Z", "")
    this.logService.debug('SignalrService: sendMsgACK msgIx: ' + msgIndex + " TimeStamp = " + currentTime)
    this.hubProxy.invoke('msgACK', this.serial, msgIndex)
  }

  private doSecurePrintJobList() {
    this.securePrintJobService.setSecureJobsList().subscribe({
      next: n => this.logService.debug('SignalrService SendSecure Job List : ' + JSON.stringify(n)),
      error: e => this.logService.error('SignalrService SendSecure Job List : ' , e)
    })
  }
  private releaseSecureJob(jobId, pin) {
    this.logService.debug('SignalrService Release Secure Job JobID : ' + jobId)
    this.securePrintJobService.resumeSecurePrintJob(jobId, pin, "SecurePrint")
      .pipe(retryWhen(
        errors => {
          return errors.pipe(
            scan((count, error) => {
              if (count >= oidCodes.retryCount) throw (error);
              this.logService.debug("retry resumeSecurePrintJob attempt: " + count);
              return count + 1;
            }, 1),
            delay(2000),
            tap(() => this.logService.debug("retrying resumeSecurePrintJob...")))
        }
      )).subscribe({
        next: n => this.jobService.startJob(JobType.SecurePrint, jobId),
        error: e => {
          this.logService.debug('SignalrService Release Secure Job Error : ' + e + 'JobID:' + jobId);
          this.handlePinAunthenticationFailure(e, jobId)
        }
      })
  }

  private deleteSecurePrintJob(jobID,pin) {
    this.securePrintJobService.deleteSecurePrintJob(jobID,pin,"SecurePrint").subscribe({
      next: n => this.updateDeleteKioskStatus('jobComplete','CancelByUser'),
      error: e => this.handlePinAunthenticationFailure(e,jobID)
    })
  }

  private handlePinAunthenticationFailure(error,jobID) {
    var errorName = this.deviceConfigService.parseErrorResponse(error)
    if (errorName === 'FailedPINAuthentication') {
      this.updateDeleteKioskStatus('jobComplete', 'failedPINAuthentication');
    }
    else if (errorName === 'JobNotFound') {
      this.updateDeleteKioskStatus('jobComplete', 'SecureJobExpired')
    }
  }

  updateDeleteKioskStatus(status,reason){
    this.kioskStatusService.updateKioskStatusRest(status,{
      'jobName': 'PortBay Job',
      'jobType': 'SecurePrint',
      'jobSource': 'mobile',
      'jobStateReason': reason
    }).pipe(switchMap(()=> this.kioskStatusService.updateKioskStatusRest('waitingForUser', {}))).subscribe()
  }

}

