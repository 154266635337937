
/* --------------------------------------------------------------------------------------
   landing.component.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { ChangeDetectorRef, HostListener, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EnergySaver } from 'src/app/shared/eip/eip.const';

import {
  LogService,
  PwaSessionService,
  SignalrService,
  DeviceRegistrationService,
  AccountconfigService,
  DeviceConfigService,
  KiosksessionRequestService,
  SessionService,
  AliveService,
  JobService
} from 'src/app/services';
import { DeviceAlertService } from 'src/app/services/device-alert.service';
import { DeviceconfigParamsService } from 'src/app/services/deviceconfig-params.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { XrxModalComponent } from 'src/app/shared/components/xrx-modal/xrx-modal.component';
import { FullScreenModalComponent } from 'src/app/shared/components/full-screen-modal/full-screen-modal.component';
import { DeviceCapabilitiesService, DeviceTestService, oidCodes } from 'src/app/shared/eip';
import { EnergysaverConfigService } from 'src/app/services/energysaver-config.service';
import { SnmpService } from 'src/app/shared/eip/snmp.service';
declare function EipGetHeapAvailable()
declare function ExitCUIMode()

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, AfterViewInit, OnDestroy {
  viewInitialized: boolean = false
  sessionUrl$: Observable<string> = this.pwaSessionService.getSessionUrl();
  sessionOk: boolean;
  timeoutSubscription: Subscription
  deviceErrors = [];
  private isNetworkErrorDialogShown: boolean = false;
  webSocketStatus: string = '';
  disabledServices: number;
  isQrcodeUrlGenerated: boolean = false;
  isEmailNotConfig: boolean = false;
  webServiceNotEnabled: boolean = false;
  iskioskNotOnBoarded: boolean = false;
  qrcodeUrl: string = '';
  deviceType: string = '';
  deviceSerial: string = '';
  slideConfig = { infinite: true, dots: true, slidesToShow: 1, slidesToScroll: 1, autoplay: true, autoplaySpeed: 10000 };
  carouselImageNames = [];
  prefix: string = '1024_';
  lang: string;
  idleSubscription: Subscription
  EipSoftwareVersion: any;
  deviceModel: string;
  canvasWidth: any;
  canvasHeight: any;
  isSmallerCanvas: boolean;
  invalidConfiguration: boolean = false;
  showSettingMismatch: boolean = false;
  sessionError: string
  heapAvailable: number;
  private count: number = 0

  @ViewChild('servicesModal', { static: true }) servicesModal: XrxModalComponent;
  @ViewChild('errorModal', { static: true }) errorModal: XrxModalComponent;
  @ViewChild('registrationErrorModal', { static: true }) registrationErrorModal: XrxModalComponent;
  @ViewChild('verifyErrorModal', { static: true }) verifyErrorModal: XrxModalComponent;
  @ViewChild('webSocketErrorModal', { static: true }) webSocketErrorModal: XrxModalComponent;
  @ViewChild('settingsMismatchModal', { static: true }) settingsMismatchModal: FullScreenModalComponent;

  constructor(
    private cdRef: ChangeDetectorRef,
    private pwaSessionService: PwaSessionService,
    private signalRService: SignalrService,
    private deviceTestService: DeviceTestService,
    private logService: LogService,
    private deviceRegistrationService: DeviceRegistrationService,
    private deviceCapabilitiesService: DeviceCapabilitiesService,
    private accountService: AccountconfigService,
    private deviceConfigService: DeviceConfigService,
    private deviceAlertService: DeviceAlertService,
    private deviceconfigParamsService: DeviceconfigParamsService,
    private kiosksessionRequestService: KiosksessionRequestService,
    private environmentService: EnvironmentService,
    public sessionService: SessionService,
    private aliveService: AliveService,
    private energysaverConfigService: EnergysaverConfigService,
    private snmpService: SnmpService,
    private jobService: JobService
  ) { }

  ngOnInit(): void {
    this.sessionService.progressIndicator.next(40)
    //Bug:92518- Check the discovery controller devices device capabilities and information values.
    if (this.pwaSessionService.GetDeviceControler() == "Discovery Controller") {
      this.energysaverConfigService.isFirstAppLoadOnDeviceActivity()
    } 
    else {
      // Bug:96127 - during wakeup we manually reload the app and after reload the app then we check account information
      localStorage.getItem("onWakeup") == "true" ? this.energysaverConfigService.task() : null
    }
    this.lang = this.sessionService.currentLanguage;
    this.snmpService.checkKioskSettings = false
    this.sessionService.getKioskSettings()
    this.heapAvailable = EipGetHeapAvailable() / 1000000
    this.deviceCapabilitiesService.getServiceSupported()
      .pipe(switchMap(c => this.deviceTestService.getUnavailableServices(c)))
      .subscribe({
        next: us => {
          this.sessionService.progressIndicator.next(45)
          this.unavailableServicesResponseHandler(us)
        },
        error: e => {
          this.logService.error("Error occurred in getServiceSupported()", e)
          this.reloadApp(e)
        }
      })

    this.pwaSessionService.sessionId
      .subscribe(s => this.signalRInitialization(s))
      
    this.pwaSessionService.sessionId
      .pipe(switchMap(s => this.deviceConfigService.doSetDeviceConfig()))
      .subscribe({
        next: n => {
          this.sessionService.isSetDeviceConfig = true
          this.sessionService.progressIndicator.next(70)
          var t2 = Date.now()
          this.logService.debug("Save CWIS SystemTimer in localstorage " + localStorage.getItem('SystemTimer'));
          this.logService.debug("Time elapsed for doSetDeviceConfig = " + Math.abs(this.deviceConfigService.t1 - t2))
          this.initiateDeviceRegistration();
          this.logPreviousSessionLogs()
        },
        error: e => {
          var t2 = Date.now()
          this.logService.error('Error occurred in doSetDeviceConfig. Time elapsed = ' + Math.abs(this.deviceConfigService.t1 - t2) + " MS", e)
          window.location.reload()
        }
      });

    this.sessionUrl$.subscribe({
      next: s => this.sessionSuccessHandler(s),
      error: e => {
        this.logService.error("Error occurred in session URL error = ", e)
        this.reloadApp(e)
        this.sessionError = "sessionUrl"
        this.sessionErrorHandler(e)
      }
    });

    this.signalRService.webSocketNotificationLanding.subscribe({
      next: result => this.showWebSocketMessageLanding(result)
    })

    /**
     * Bug:93189.
     * Discover devices: Validates accounting config on every app load.
     * Fuji devices: Validates accounting config on the first app load for each day.
     */
    this.pwaSessionService.sessionId.subscribe({
      next: () => {
        this.energysaverConfigService.checkAccountingConfig.subscribe({
          next: n => this.getAccountConfiguration()
        })
      }
    })

    this.pwaSessionService.sessionId
      .pipe(switchMap(s => this.deviceAlertService.setDeviceAlert(null)))
      .subscribe({
        next: n => {
          this.sessionService.progressIndicator.next(60)
          var t2 = Date.now()
          this.logService.debug('Time elapsed for setDeviceAlert = ' + Math.abs(this.deviceAlertService.t1 - t2))
        },
        error: e => {
          var t2 = Date.now()
          this.logService.error('Error occurred in setDeviceAlert. Time elapsed = ' + Math.abs(this.deviceAlertService.t1 - t2) + " MS.", e)
          this.reloadApp(e)
          this.logService.error('SetDeviceAlert Error in Landing component:: ', e)
        }
      });

    this.deviceCapabilitiesService.getServiceSupported().subscribe({
      next: s => {
        this.deviceType = this.pwaSessionService.getDeviceModel(s);
        this.kiosksessionRequestService.getCarouselImageNames().subscribe((response) => {
          if (response) {
            this.sessionService.progressIndicator.next(43)
            this.logService.writeToLocalStorage("Get carousel images " , JSON.stringify(response) , true)
            let carouselImages = response.toString().split(',');
            if (parseInt(this.canvasWidth) <= 800) {
              this.isSmallerCanvas = true
              this.carouselImageNames = (this.deviceType === 'p') ? response.toString().split(',', 2) : response.toString().split(',')
            }
            else {
              this.logService.writeToLocalStorage("Get carousel images " , JSON.stringify(response) , true,)
              this.isSmallerCanvas = false
              if (this.deviceType === 'p' || this.deviceType === 'm') {
                carouselImages.forEach((image) => {
                  let newImage = this.prefix.concat(image)
                  this.carouselImageNames.push(newImage)
                })
              }
            }
          }
        });
      },
      error: e => {
        this.logService.error("Error occurred in getServiceSupported error = ", e)
        this.reloadApp(e)
      }
    });
    //getDeviceInfo to identify Fuji or Discovery products
    this.sessionService.getDeviceInfo().subscribe({
      next: deviceInfo => {
        this.deviceSerial = deviceInfo.serial
        let majorVersion = deviceInfo.EIPSoftware.split('.')
        this.EipSoftwareVersion = majorVersion[0]
        this.deviceModel = this.pwaSessionService.getDeviceType(deviceInfo.model)
        //get Canvas Size of the device
        this.canvasWidth = deviceInfo.canvasWidth
        this.canvasHeight = deviceInfo.canvasHeight
      },
      error: e => {
        this.logService.error("Error occurred in getDeviceInfo error = ", e)
        this.reloadApp(e)
      }
    }
    )
    // Differentiate b/w Fuji and Discovery based products
    this.pwaSessionService.sessionId.subscribe({
      next: () => {
        if ((this.deviceModel == 'v' || this.deviceModel == 'vr') && (parseInt(this.EipSoftwareVersion) < 5)) {
          this.sessionService.isFujiBasedProduct = true
        }
        else {
          this.sessionService.isFujiBasedProduct = false
        }
      }
    })

    this.signalRService.registrationFailureNotification.subscribe({
      error: e => {
        this.sessionError = "registrationFailureNotification"
        this.sessionErrorHandler(e)
      }
    })

    // get wakeup/Sleep settings
    this.pwaSessionService.sessionId.subscribe({
      next: () => {
        if (this.sessionService.isFujiBasedProduct) {
          this.energysaverConfigService.isFirstUpdateLowPowerMode = false;
          this.energysaverConfigService.checkPowerSaveStatus()
        }
      }
    })
    // handleVerifyKioskRegistration after scanning the QR code
    this.signalRService.verifyKioskNotification.subscribe({
      next: (result) => { this.handleVerifyKioskRegistration(result) }
    })

    // unexpectedly terminated while doing a copy job. Manually delete those records.
    this.jobService.getListActiveQueue().subscribe({
      next: n => this.logService.debug('getListActiveQueue success' + n),
      error: e => this.logService.warn('getListActiveQueue Error: ', e)
    });

  }

  ngAfterViewInit(): void {
    this.viewInitialized = true
  }

  @HostListener('document:suspend', ['$event'])
  onSuspend(ev: Event) {
    this.signalRService.isAppSuspended = true
    this.timeoutSubscription.unsubscribe()
    if (this.signalRService.isSignalRStarted && !this.signalRService.isSessionStarted) {
      this.signalRService.stopConnection()
    }
  }

  @HostListener('document:resume', ['$event'])
  onResume(ev: Event) {
    this.signalRService.isAppSuspended = false
    if (this.isNetworkErrorDialogShown) {
      this.isNetworkErrorDialogShown = false
      window.location.reload()
    }

    if (!this.signalRService.isSignalRStarted && !this.signalRService.isSessionStarted) {
      this.signalRService.startConnection()
    }

    this.aliveService.get().subscribe({
      next: n => this.logService.debug('Language settings enabled: ' + n),
      error: e => this.logService.debug('Unable to read the language settings: ' + e)
    })
  }

  private checkAndReload() {
    if (this.viewInitialized) this.logService.info("Landing component loaded successfully")
    else {
      this.logService.error("Landing component: Failed to load in previous session, Reloading the app", "")
      this.logService.warn("Landing component: Failed to load, Reloading the app")
      setTimeout(() => window.location.reload(), 300)
    }
  }

  private initiateDeviceRegistration() {
    return this.pwaSessionService.sessionId
      .pipe(switchMap(s => this.deviceRegistrationService.registerDevice(s)))
      .subscribe({
        next: r => {
          if (this.deviceRegistrationService.startTime) {
            var endTime = Date.now()
            this.logService.debug("Time elapsed for RegDevice: " + Math.abs(this.deviceRegistrationService.startTime - endTime) + " Ms")
          }
          this.handleRegistrationSuccess(r);
          this.initiateVerifyKioskRegistration();
        },
        error: (e) => {
          var endTime = Date.now()
          this.logService.error("Error occurred in registerDevice. Time elapsed = " + Math.abs(this.deviceRegistrationService.startTime - endTime) + " Ms", e)
          this.reloadApp(e)
          this.registrationErrorModal.open()
        }
      });
  }
  private initiateVerifyKioskRegistration() {
    return this.pwaSessionService.sessionId
      .pipe(switchMap(s => this.deviceRegistrationService.verifyKioskRegistration(s)))
      .subscribe({
        next: s => {
          this.sessionService.progressIndicator.next(100)
          var t2 = Date.now()
          this.logService.debug("Elapsed time for VerifyKioskRegistration call = " + Math.abs(this.deviceRegistrationService.t1 - t2) + " MS" + " Response = " + s)
          this.handleVerifyKioskRegistration(s)
        },
        error: e => {
          var t2 = Date.now()
          this.logService.error("Error occurred in VerifyKioskRegistration. Time elapsed =  " + Math.abs(this.deviceRegistrationService.t1 - t2) + " MS," + " Error: ", e)
          if (JSON.stringify(e).includes('Timeout')) {
            this.reloadApp(e)
          }
          else {
            this.handleVerifyKioskRegError()
          }
        }
      });
  }
  private handleRegistrationSuccess(result: any) {
    if (result == 'success')
      localStorage.setItem('regInfo', 'success')
  }
  private handleVerifyKioskRegistration(result: any) {
    let regResultTitle = {
      invalidAppInstalled: 'INVALID_APP_INSTALLED', deviceNotRegistered: "DEVICE_NOT_REGISTERED", hostAccountNotActive: "ACCOUNT_NOT_ACTIVE", pricingNotConfigured: "PRICING_NOT_CONFIGURED", XWPCGuidNullInDatabase: "DEVICE_NOT_REGISTERED",
      countryNotSupported: "COUNTRY_NOT_SUPPORTED", merchantAccountNotSpecified: "UNABLE_TO_PROCESS_PAYMENT", hostAccountNotSpecified: "UNABLE_TO_PROCESS_PAYMENT", platformAccountNotSpecified: "UNABLE_TO_PROCESS_PAYMENT", cannotProcessPayments: "UNABLE_TO_PROCESS_PAYMENT",
      cannotAcceptRevenueShare: "UNABLE_TO_PROCESS_PAYMENT", taxNotConfigured: "PRICING_NOT_CONFIGURED", configurationNotValid: "CONFIGURATION_NOT_VALID", receiptNotConfigured: "RECEIPT_NOT_CONFIGURED", revenueNotSupportedInDemoMode: "REVENUE_NOT_SUPPORTED_IN_DEMO_MODE"
    }
    let regResultBody = {
      invalidAppInstalled: 'INVALID_APP_INSTALLED', deviceNotRegistered: "DEVICE_NOT_REGISTERED", hostAccountNotActive: "ACCOUNT_NOT_ACTIVE", pricingNotConfigured: "PRICING_NOT_CONFIGURED", XWPCGuidNullInDatabase: "DEVICE_NOT_REGISTERED_PROPERLY",
      countryNotSupported: "COUNTRY_NOT_SUPPORTED", merchantAccountNotSpecified: "MERCHANT_ACCOUNT_NOT_SPECIFIED", hostAccountNotSpecified: "HOST_ACCOUNT_NOT_SPECIFIED", platformAccountNotSpecified: "PLATFORM_ACCOUNT_NOT_SPECIFIED", cannotProcessPayments: "CANNOT_PROCESS_PAYMENTS",
      cannotAcceptRevenueShare: "CANNOT_ACCEPT_REVENUE_SHARE", taxNotConfigured: "TAX_NOT_CONFIGURED", configurationNotValid: "CONFIGURATION_NOT_VALID", receiptNotConfigured: "RECEIPT_NOT_CONFIGURED", revenueNotSupportedInDemoMode: "REVENUE_NOT_SUPPORTED_IN_DEMO_MODE"
    }
    if (result == 'OK') {
      localStorage.setItem('appSubscription', 'subscribed');
    }
    else if (result !== 'AppSubscribed' && result !== 'receiptNotConfigured' && !this.invalidConfiguration) {
      this.showVerifyKioskRegErrorMessage(regResultTitle[result], regResultBody[result]);
      if (result == 'XWPCGuidNullInDatabase')
        localStorage.removeItem('regInfo');
    }
    else if (result == 'receiptNotConfigured') {
      this.isEmailNotConfig = true;
    }
  }

  private handleVerifyKioskRegError() {
    this.showVerifyKioskRegErrorMessage('INVALID_KIOSK_REGISTRATION', 'INVALID_KIOSK_REGISTRATION');
  }

  private showVerifyKioskRegErrorMessage(title, body) {
    const messageTitle = 'KIOSK_ERROR.TITLE.' + title;
    const description = 'KIOSK_ERROR.BODY.' + body;
    this.verifyErrorModal.openWithContent(messageTitle, description);
  }

  unavailableServicesResponseHandler(us) {
    this.logService.writeToLocalStorage("unavailableServicesResponseHandler is called" ,JSON.stringify(us) ,true)
    this.deviceErrors = us;
    this.disabledServices = this.deviceErrors.length;
    if (us.length) {
      this.webServiceNotEnabled = true
    }
  }

  signalRInitialization(s) {
    this.signalRService.init(s)
      .subscribe({
        next: r => this.signalRResponseHandler(r,s),
        error: e => {
          this.sessionError = "this.signalRService.init"
          this.sessionErrorHandler(e)
        }
      })
  }

  signalRResponseHandler(response,sessionId) {
    this.checkAndReload()
    this.sessionService.progressIndicator.next(80)
    if (response === 'success') {
      this.sessionOk = true;
      this.logService.debug("landing.component.ts signalRResponseHandler function() sessionOk= " + this.sessionOk)
      this.cdRef.detectChanges();
    }
    else {
      //Retry signalRInitialization for three times, when the response is failed
      if (this.count < 3) {
        this.logService.warn("Retry SignalRInitialization attempt: " + this.count + " " + "Response: " + response +" " + "SessionId: " + sessionId);
        this.signalRInitialization(sessionId)
        this.count = this.count + 1
      }
      else 
        this.sessionErrorHandler('signalR response not authorized');
    }
  }

  sessionSuccessHandler(sessionUrl) {
    this.sessionService.progressIndicator.next(50)
    this.qrcodeUrl = sessionUrl
    this.isQrcodeUrlGenerated = true;
    this.logService.debug("landing.component.ts sessionSuccessHandler function() sessionSuccessHandler= " + this.isQrcodeUrlGenerated)
    this.jobService.validateJBADataAndContinue()
    this.cdRef.detectChanges();
  }

  sessionErrorHandler(error?: any) {
    this.checkAndReload()
    switch (error) {
      case 'deviceNotOnboarded':
        this.errorModal.openWithContent('KIOSK_ERROR.TITLE.DEVICE_NOT_REGISTERED', 'KIOSK_ERROR.BODY.DEVICE_NOT_REGISTERED');
        break;
      case 'countryNotSupported':
        this.errorModal.openWithContent('KIOSK_ERROR.TITLE.COUNTRY_NOT_SUPPORTED', 'KIOSK_ERROR.BODY.COUNTRY_NOT_SUPPORTED');
        break;
      case 'hostAccountNotSpecified':
        this.errorModal.openWithContent('KIOSK_ERROR.TITLE.UNABLE_TO_PROCESS_PAYMENT', 'KIOSK_ERROR.BODY.HOST_ACCOUNT_NOT_SPECIFIED')
        break;
      case 'kioskNotOnBoarded':
        this.iskioskNotOnBoarded = true;
        break;
      default:
        this.logService.error('Error in session occurred at Landing Component:sessionErrorHandler: ' + this.sessionError, JSON.stringify(error))
        this.isNetworkErrorDialogShown = true
        this.timeoutSubscription = timer(15000).subscribe(r => {
          this.timeoutSubscription.unsubscribe()
          window.location.reload()
        })
        this.errorModal.openWithContent('FAULT.TITLE.CONNECTION_ERROR', 'FAULT.BODY.PROBLEM_CONNECTING_SERVER');
        break;
    }
    this.qrcodeUrl = null;
    try {
      this.logService.error('Error in session occurred at Landing Component: ', JSON.stringify(error))
    } catch (error) {
      console.log(error)
    }
  }

  getAccountConfiguration() {
    this.accountService.getAccountConfiguration().subscribe({
      next: s => {
        var t2 = Date.now()
        this.logService.debug("Time elapsed for getAccountConfiguration: " + Math.abs(this.accountService.t1 - t2) + " Ms")
        this.handleAccountConfigurationDetails(s)
        this.logService.debug("Account Configuration" + JSON.stringify(s))
      },
      error: (e) => {
        var t2 = Date.now()
        this.invalidConfiguration = true;
        this.logService.error("Fetching joblimitserverurl from device failed! Time elapsed = " + Math.abs(this.accountService.t1 - t2) + " Ms ", e);
        this.reloadApp(e)
        this.errorModal.openWithContent('KIOSK_ERROR.TITLE.CONFIGURATION_NOT_VALID', 'KIOSK_ERROR.BODY.CONFIGURATION_NOT_VALID')
      }
    });
  }

  handleAccountConfigurationDetails(accountDetails) {
    let isServicesEnabled = false;
    if (this.deviceType == 'm' && accountDetails !== undefined && accountDetails.copy == 'PreAuth' && accountDetails.print == 'PreAuth' && accountDetails.acctauthoriValue[0] == 'PreAuth' && accountDetails.acctauthoriValue[1] == 'PreAuth') {
      isServicesEnabled = true;
    } else if (this.deviceType == 'p' && accountDetails !== undefined && accountDetails.print == 'PreAuth') {
      isServicesEnabled = true;
    }
    if (accountDetails !== undefined) {
      if (!(accountDetails.accMethod == "true" && accountDetails.serURL == this.environmentService.customDomainUrl + '/JobLimitsAppServer.asmx' && accountDetails.acctjbadefult[0] == this.deviceSerial && accountDetails.acctjbadefult[1] == this.deviceSerial && isServicesEnabled)) {
        if (this.count < 3) {
          this.count++
          this.logService.debug("retry handleAccountConfigurationDetails attempt: " + this.count + " " + "accountDetails:" + accountDetails);
          this.getAccountConfiguration()
        } else {
          this.invalidConfiguration = true;
          this.logService.error("For app: " + this.sessionService.appName + " Joblimitserverurl configured " + accountDetails.serURL + ' is not expected; Expected url is: ' + this.environmentService.customDomainUrl + '/JobLimitsAppServer.asmx', '')
          this.errorModal.openWithContent('KIOSK_ERROR.TITLE.CONFIGURATION_NOT_VALID', 'KIOSK_ERROR.BODY.CONFIGURATION_NOT_VALID');
        }
      }
    }

  }

  // Show error popup while reconnecting
  // Hide error popup while reconnected or connected received
  private showWebSocketMessageLanding(messageType) {
    this.webSocketStatus = messageType
    if (messageType == "reconnecting")
      this.webSocketErrorModal.open()
    else if (messageType == "reconnected") {
      //Realoading the app when the websocket is reconnected.
      if(!this.signalRService.isSessionStarted) {
        this.reloadAppOnReconnect();
      }
    }
    else if(messageType == "connected") {
      this.webSocketErrorModal.close()
    }
  }  
  
  private reloadAppOnReconnect() {
    this.logService.warn('Landing Component: reloading the App from onReconnect');
    this.webSocketErrorModal.close()
    location.reload();
  }

  /**
   * This function logs the previous session logs.
   */
  private logPreviousSessionLogs() {
    this.logService.info("heapAvailable when app launched = " + this.heapAvailable)
    const landingComponentLogs = (localStorage.getItem('landingComponentLogs') !== null);
    if (landingComponentLogs) {
      this.logService.info("Landing component " + localStorage.getItem('landingComponentLogs'))
      localStorage.removeItem('landingComponentLogs')
    }
    const checkKioskSettingsError = (localStorage.getItem('checkKioskSettingsErrorLogs') !== null);
    if (checkKioskSettingsError) {
      this.logService.error("Error in checkKioskSettings component ", localStorage.getItem('checkKioskSettingsErrorLogs'))
      localStorage.removeItem('checkKioskSettingsErrorLogs')
    }
    const checkKioskSettingsLogs = (localStorage.getItem('checkKioskSettingsLogs') !== null);
    if (checkKioskSettingsLogs) {
      this.logService.debug("checkKioskSettings: " + localStorage.getItem('checkKioskSettingsLogs'))
      localStorage.removeItem('checkKioskSettingsLogs')
    }
    const timeoutError = (localStorage.getItem('timeoutlog') !== null)
    if (timeoutError) {
      this.logService.error("Timeout occurred in previous session", localStorage.getItem('timeoutlog'))
      localStorage.removeItem("timeoutlog")
    }
    const errorLog = (localStorage.getItem('errorlog') !== null)
    if (errorLog) {
      this.logService.error("Error occurred in previous session", localStorage.getItem('errorlog'))
      localStorage.removeItem("errorlog")
    }
  }

  /**
  * This function reloads the app when timeout error occurred.
  * @param {string}	error   Error message
  */
  private reloadApp(error: any) {
    if (JSON.stringify(error).toUpperCase().includes('TIMEOUT')) {
      setTimeout(() => window.location.reload(), 500)
    }
  }

  ngOnDestroy(): void {
    try {
      if (this.timeoutSubscription && !this.timeoutSubscription.closed) {
        this.timeoutSubscription.unsubscribe()
      }
      if (this.idleSubscription && !this.idleSubscription.closed) {
        this.idleSubscription.unsubscribe()
      }
    } catch (err) {
      this.logService.error("Exception in landing component in ngOnDestroy ", JSON.stringify(err));
    }
  }

  exitApp() {
    try {
      ExitCUIMode()
    } catch {
      console.log("Exit CUI embedded function not found, switching to empty state instead")
      window.location.href = "about:blank"
    }
  }
}
