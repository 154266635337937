<!--
   landing.component.html
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
-->

<div class="landing-container">
  <div class="landing_topnav">
    <ul class="landing_padding">
      <li><img class="xerox-logo" src="/assets/images/navbar_-_xerox_logo_u2160.svg" alt=""></li>
      <li>
        <h1>{{ 'LANDING.TITLE' | translate }}</h1>
      </li>
    </ul>
  </div>

  <div class="main-container">
    <div class="split left">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let image of carouselImageNames" class="slide">
          <img src="/assets/images/{{ lang }}/{{ image }}" alt="" width="100%">
        </div>
      </ngx-slick-carousel>
    </div>

    <div class="split right">
      <div class="landing-content">
        <div class="qr-wrapper">
          <div class="qr-code-wrapper">
            <div *ngIf="isQrcodeUrlGenerated && sessionOk && this.sessionService.isSetDeviceConfig; else loading">
              <qrcode *ngIf="isSmallerCanvas" [qrdata]="qrcodeUrl" [width]="245" [margin]="2">
              </qrcode>
              <qrcode *ngIf="!isSmallerCanvas" [qrdata]="qrcodeUrl" [width]="305" [margin]="2">
              </qrcode>
            </div>
            <ng-template #loading>
              <div class="loading">
                <img class="loading-img" src="assets/images/loading.gif" />
                <div class="loading-text">{{ 'LANDING.LOADING_TEXT' | translate }}</div>
              </div>
            </ng-template>
          </div>
          <div>
            <h5 class="scan-qr-text">{{ 'LANDING.USE_YOUR_CAMERA' | translate }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="webServiceNotEnabled">
  <app-full-screen-modal [webservicesDisabled]="true" [showClose]="true">
    <div class="component">
      <p>{{ 'FAULT.BODY.TO_USE_APP_WEB_SERVICES_ENABLED' | translate }}</p>
      <div class="error_body">        
          <span *ngFor="let x of deviceErrors" class="error_list">{{ x | translate }}</span>        
      </div>
      <span class="last">{{ 'FAULT.BODY.CONTACT_ADMIN_ENABLE_SERVICES' | translate }}</span>
    </div>
  </app-full-screen-modal>
</div>

<app-xrx-modal #errorModal [showClose]="false" [showExit]="true" [showDescription]="true"></app-xrx-modal>
<app-xrx-modal #registrationErrorModal [showClose]="false" [showExit]="true"
  title="{{ 'FAULT.TITLE.DEVICE_REGISTRATION_ERROR' | translate }}">
  <div>
    <!--p>{{error}}</p-->
    <p>{{ 'FAULT.BODY.UNABLE_TO_REGISTER_DEVICE' | translate }}</p>
  </div>
</app-xrx-modal>

<app-xrx-modal #webSocketErrorModal title="{{ 'FAULT.TITLE.WEBSOCKET_DISCONNECT' | translate }}">
  <div>
    <p>{{ 'FAULT.BODY.WEBSOCKET_DISCONNECT' | translate }}</p>
  </div>
</app-xrx-modal>

<app-xrx-modal #verifyErrorModal [showClose]="false" [showExit]="true" [showDescription]="true"></app-xrx-modal>

<div *ngIf="isEmailNotConfig">
<app-full-screen-modal [showEmailNotConfig]="true" [showClose]="true"></app-full-screen-modal>
</div>

<div *ngIf="iskioskNotOnBoarded">
  <app-full-screen-modal [showkioskNotOnBoarded]="true" [showClose]="true"></app-full-screen-modal>
</div>
