import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, timeout } from 'rxjs/operators';
import { supportedCountries } from 'src/app/model/supported-country';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  kioskServerUrl: string;
  pwaUrl: string;
  pbSecretKey: string;
  logLevel: string;
  customDomainUrl: string;
  workplacekioskappversion: string;
  resetSystemTimer: number;
  currSystemTimer: number;
  newSystemTimer: number;
  currDiscoverySysTimerInSecs: any;
  resetDiscoverySysTimerInSecs: number;
  currPSTimer: number;
  newPSTimer: number;
  memoryThreshold: number;
  timeOutValue: number;
  snmpTimeOutValue: number;
  progressIndicator: any

  constructor(
    private http: HttpClient
  ) { }

  getServerUrl() {
    return this.http.get("/sessionrequest", { headers: { 'appID': environment.appID } }
    ).pipe(timeout(10 * 1000))
      .pipe(tap(result => this.setServerUrlValues(result)))
  }

  setServerUrlValues(result) {
    var sessionDetails = JSON.parse(JSON.stringify(result))
    this.kioskServerUrl = sessionDetails.kioskServerUrl
    this.pwaUrl = sessionDetails.pwaUrl
    this.pbSecretKey = sessionDetails.pbSecretKey
    this.logLevel = sessionDetails.logLevel
    this.customDomainUrl = sessionDetails.customDomainUrl
    this.workplacekioskappversion = sessionDetails.workplacekioskappversion
    this.newSystemTimer = sessionDetails.newSystemTimer
    this.currSystemTimer = sessionDetails.currSystemTimer
    this.currDiscoverySysTimerInSecs = sessionDetails.currDiscoverySysTimerInSecs
    this.currPSTimer = sessionDetails.currPSTimer
    this.newPSTimer = sessionDetails.newPSTimer
    this.memoryThreshold = sessionDetails.memoryThreshold
    this.progressIndicator = sessionDetails.progressIndicator
    this.timeOutValue = sessionDetails.timeOutValue
    this.snmpTimeOutValue = sessionDetails.snmpTimeOutValue
  }
}
