import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceConfigService } from 'src/app/services';
import { XrxModalComponent } from 'src/app/shared/components/xrx-modal/xrx-modal.component';
import { ThankyouModalComponent } from 'src/app/shared/components/thankyou-modal/thankyou-modal.component';
import { SessionService } from 'src/app/services';
declare var $: any;

@Component({
  selector: 'app-kiosk-settings',
  templateUrl: './kiosk-settings.component.html',
  styleUrls: ['./kiosk-settings.component.scss']
})
export class KioskSettingsComponent implements OnInit {

  settingsForm: FormGroup;
  isAuthenticationError = false;
  isSNMPError = false;
  private communityWriteString: string;

  @ViewChild('errorModal', { static: true }) errorModal: ThankyouModalComponent;
  @ViewChild('device_admin_name', { static: true }) deviceAdminName: ElementRef;
  @ViewChild('device_admin_password', { static: true }) deviceAdminPassword: ElementRef;
  @ViewChild('snmp_community_read_string', { static: true }) snmpCommunityReadString: ElementRef;
  @ViewChild('snmp_community_write_string', { static: true }) snmpCommunityWriteString: ElementRef;
  @ViewChild('reset_button', { static: true }) resetButton: ElementRef;
  @ViewChild('savingSettings', { static: true }) savingSettings: ThankyouModalComponent ;
  @ViewChild('restartMFD', { static: true }) restartMFD: XrxModalComponent;
  @ViewChild('invalidSettingsModal', { static: true }) verifyErrorModal: XrxModalComponent;

  constructor(
    private fb: FormBuilder,
    private deviceConfigService: DeviceConfigService,
    private sessionService: SessionService
  ) {
    this.formValidation();
  }

  ngOnInit() {
    $('.xrx-switch').switch();
    this.settingsForm.get('deviceAdminName').setValue(this.sessionService.adminName)
    this.settingsForm.get('snmpCommunityReadString').setValue('public');
    this.settingsForm.get('snmpCommunityWriteString').setValue('private');
    this.deviceConfigService.convertToKioskError.subscribe({
      next: e => this.handleDeviceConfigFailure(e)
    })
  }

  onSubmit() {
    if (this.settingsForm.valid) {
      this.savingSettings.openWithContent("", 'SETTINGS.SAVING_SETTINGS')
      var admin = this.settingsForm.get('deviceAdminName').value;
      var adminPass = this.settingsForm.get('deviceAdminPassword').value;
      var snmpWriteString = this.settingsForm.get('snmpCommunityWriteString').value;
      var snmpReadString = this.settingsForm.get('snmpCommunityReadString').value;
      var settings = {
        admin: admin,
        password: adminPass,
        writeString: snmpWriteString,
        readString: snmpWriteString
      }
      localStorage.setItem('kioskSettings', JSON.stringify(settings))
    
      this.deviceConfigService.convertToKiosk(admin, adminPass, snmpWriteString)
        .subscribe(
          {
            error: e => this.handleDeviceConfigFailure(e)
          });

      this.deviceConfigService.notification.subscribe({next: result => this.handleDeviceConfigSuccess(result, snmpWriteString)})
    } else {
      Object.keys(this.settingsForm.controls).forEach(field => {
        const control = this.settingsForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  private handleDeviceConfigSuccess(result, snmpWriteString) {
    if(result == "DeviceConfigComplete"){
      this.savingSettings.close()
      this.restartMFD.open()
      this.communityWriteString = snmpWriteString
    }
  }

  private handleDeviceConfigFailure(error) {
    if (error == 'SNMPInvalidCommunityString' || error == 'SNMPTimeOutFault') {
      this.savingSettings.close()
      this.isAuthenticationError = false
      this.isSNMPError = true
    }
    else {
      var errorName = this.deviceConfigService.parseErrorResponse(error)
      this.resetToDefault();
      if (errorName == 'FailedAuthentication' || errorName.includes("User authentication failed")) {
        this.savingSettings.close()
        this.isAuthenticationError = true
      }
      else if (errorName == 'SNMPTimeOutFault') {
        this.savingSettings.close()
        this.isSNMPError = true
      }
      else {
        this.savingSettings.close()
        this.restartMFD.close()
        this.isAuthenticationError = false
        this.isSNMPError = false
        this.errorModal.openWithContent('SETTINGS.ERROR_TITLE', 'SETTINGS.ERROR_BODY');
      }
    }
  }

  restartDevice(){
    this.deviceConfigService.restartMFD(this.communityWriteString)
  }

  resetToDefault() {
    this.settingsForm.get('deviceAdminName').markAsUntouched();
    this.settingsForm.get('deviceAdminName').markAsPristine();
    this.settingsForm.get('deviceAdminPassword').markAsUntouched();
    this.settingsForm.get('deviceAdminPassword').markAsPristine();

    this.settingsForm.get('deviceAdminName').setValue(this.sessionService.adminName);
    this.settingsForm.get('deviceAdminPassword').setValue('');
    this.settingsForm.get('snmpCommunityReadString').setValue('public');
    this.settingsForm.get('snmpCommunityWriteString').setValue('private');
    this.settingsForm.get('enableKiosk').setValue(false);
  }

  formValidation() {
    this.settingsForm = this.fb.group({
      deviceAdminName: ['', Validators.required],
      deviceAdminPassword: ['', Validators.required],
      snmpCommunityReadString: ['', Validators.required],
      snmpCommunityWriteString: ['', Validators.required],
      enableKiosk: [false, Validators.requiredTrue]
    })
  }

  /**
   * Triggers when users click on enter in the keyboard and navigates to next textbox field.
   * @param keyboardEvent - keyboard event
   */
  onEnterKeyClicked(keyboardEvent: KeyboardEvent): void {
    switch (keyboardEvent.currentTarget) {
      case this.deviceAdminName.nativeElement:
        this.deviceAdminPassword.nativeElement.focus();
        break;
      case this.deviceAdminPassword.nativeElement:
        this.snmpCommunityReadString.nativeElement.focus();
        break;
      case this.snmpCommunityReadString.nativeElement:
        this.snmpCommunityWriteString.nativeElement.focus();
        break;
      case this.snmpCommunityWriteString.nativeElement:
        this.resetButton.nativeElement.focus();
    }
  }

}
