
/* --------------------------------------------------------------------------------------
   session.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { DeviceInfoService } from '../shared/eip';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CheckoutService } from './checkout.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  deviceUrl: string;
  deviceInfo: ReplaySubject<any>;
  onSubmitNotification: Subject<any> = new Subject<any>()
  progressIndicator: Subject<any> = new Subject<any>()
  appId:string;
  appName: string;
  appVersion: string;
  deviceSerial: string;
  adminName: string;
  fileTrasnferFailed: boolean = false;
  docConversionFailed: boolean = false;
  isSessionEnded: boolean = false;
  isFujiBasedProduct:boolean;
  isSessionStarted: boolean = false;
  isPaperinPlaten: boolean = false;
  userName: string;
  password: string;
  snmpWriteString: string;
  snmpReadString: string;
  lastJobStatus: any;
  infoRequired: boolean = false;
  currentLanguage: string;
  isSetDeviceConfig: boolean = false
  isSetNewTrayValue: boolean = false

  private sessionState: any = {
    webAppConnected: true,
    logout: false,
    checkoutInitiated: false,
    jobRunning: false,
    logoutErrorMessage: '',
    isPrintJobSubmitted: false,
    convertionStarted: false,
    checkoutDetails: ''
  }

  constructor(
    private location: Location,
    private deviceInfoService: DeviceInfoService,
    private checkoutService: CheckoutService,
    private logService: LogService
  ) {
    const queryparams = new URLSearchParams(this.location.path())
    this.deviceUrl = this.getDeviceIp(queryparams.get('debug'))
    this.appId = queryparams.get('appName')
    this.appName = queryparams.get('deviceAppName')
    this.appVersion = queryparams.get('appVersion')
    this.deviceSerial = queryparams.get('deviceId')
    if(queryparams.get('clearLocalStorage') == 'true'){
      localStorage.clear()
    }
  }

  private getDeviceIp(debugParam: string): string {
    if (window.location.port)
      return environment.debugUrl
    if (debugParam)
      return this.getDebugDevice(debugParam)

    return environment.deviceUrl
  }
  private getDebugDevice(debugParam: string): string {
    if (debugParam.includes('.'))
      return 'http://192.168.' + debugParam
    else
      return 'http://192.168.1.' + debugParam
  }
  getDeviceInfo(): Observable<any> {
    const val = (localStorage.getItem('deviceInfo') !== null)
    if (val) {
      if (this.deviceInfo) {
        return this.deviceInfo
      }
      else {
        var deviceConfig = JSON.parse(localStorage.getItem('deviceInfo'))
        this.deviceInfo = new ReplaySubject<any>();
        this.logService.writeToLocalStorage("Getting DeviceConfig from localstorage ", "Device Name= " + deviceConfig.name + " Device Serial= " + deviceConfig.serial + " Device Model= " + deviceConfig.model, true)
        this.deviceInfo.next(deviceConfig)
        return (this.deviceInfo)
      }
    }
    else {
      return this.deviceInfoService.get(this.deviceUrl)
        .pipe(
          tap(di => {
            this.logService.writeToLocalStorage("Fetched DeviceConfig from device and setting it in local storage " , "Device Name= " + di.name + " Device Serial= " + di.serial + " Device Model= " + di.model, true)
            localStorage.setItem("deviceInfo", JSON.stringify(di))
            this.deviceInfo = new ReplaySubject<any>();
            this.deviceInfo.next(di)
          })
        )
    }
  }



  getKioskSettings() {
    const itemSet = (localStorage.getItem('kioskSettings') !== null);

    if (itemSet) {
      var settings = JSON.parse(localStorage.getItem('kioskSettings'))
      this.userName = settings.admin
      this.password = settings.password
      this.snmpWriteString = settings.writeString
      this.snmpReadString = settings.readString
    }
    else{
      this.infoRequired = true
    }
  }

  getSessionState() {
    return this.sessionState
  }

  setWebAppDisconnected() {
    this.sessionState.webAppConnected = false
  }

  setWebAppConnected() {
    this.sessionState.webAppConnected = true
  }

  setInitiateCheckoutStarted() {
    this.sessionState.checkoutInitiated = true
  }

  setCheckoutDetails(checkoutDetails) {
    this.sessionState.checkoutDetails = checkoutDetails
  }

  setLogoutErrorMessage(errorMessage: string) {
    this.sessionState.logoutErrorMessage = errorMessage
  }

  setLogoutInitiate() {
    this.sessionState.logout = true;
    if (!this.sessionState.jobRunning && !this.sessionState.convertionStarted ){
      this.checkoutService.initiateCheckout()
    }
  }

  setJobRunning() {
    this.setLogoutErrorMessage('jobsubmitted')
    this.sessionState.jobRunning = true;
  }

  clearJobRunning() {
    this.sessionState.jobRunning = false;
  }

  setPrintJobSubmiited() {
    this.sessionState.isPrintJobSubmitted = true;
  }

  setConvertionStarted() {
    this.sessionState.convertionStarted = true;
  }

  setPrintJobSubmissionAborted(eventDetails) {
    if (eventDetails == 'docConversionFailed') {
      this.docConversionFailed = true;
    }
    else {
      this.fileTrasnferFailed = true;
    }
  }

  clearConvertionStarted() {
    this.sessionState.convertionStarted = false;
  }

  getConversionStarted() {
    return this.sessionState.convertionStarted
  }

  getAppName(){
    return this.appName;
  }
}
