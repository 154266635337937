
/* --------------------------------------------------------------------------------------
   print.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service';
declare function xrxPrintInitiatePrintJobURL(url, printUrl, username, password, ticket, callback, errorCallback, timeout: number)
declare function xrxPrintParseInitiatePrintJobURL(response: string)


@Injectable({
    providedIn: 'root'
  })
  export class PrintService {
    constructor(private environmentService: EnvironmentService){}
    print(deviceUrl, sourceUrl, jobTemplate) {
        //Wrapper to attach status functions, can be here or in job service
        return this.submitPrintTicket(deviceUrl, sourceUrl, jobTemplate)
    }
    submitPrintTicket (deviceUrl, sourceUrl, jobTemplate) {
        const result = new Promise((resolve, reject) => {
        xrxPrintInitiatePrintJobURL(
            deviceUrl,
            sourceUrl,
            '','',
            jobTemplate,
            (env, response) => resolve(xrxPrintParseInitiatePrintJobURL(response)),
            (env, response, status) => reject({env, response, status}),
            this.environmentService.timeOutValue
          )
        })
        return from(result)
      }
  }