/* --------------------------------------------------------------------------------------
   checkout.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  private checkoutParams = { deviceID: '', sessionID: '' }
  private purchaseDone: boolean = false;
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private logService: LogService
  ) { }

  isPurchaseDone() {
    return this.purchaseDone
  }

  setDeviceId(deviceId: string) {
    this.checkoutParams.deviceID = deviceId
  }

  setSessionId(sessionId: string) {
    this.checkoutParams.sessionID = sessionId
  }

  initiateCheckout() {
    const endpoint = this.environmentService.kioskServerUrl + "/api/MobileUI/InitiateCheckout" + '?deviceID=' + this.checkoutParams.deviceID
    let receiptType = 'none';
    if (this.purchaseDone) {
      receiptType = 'print'
    }
    const body = {
      receiptType: receiptType,
      userId: ' '
    }
    const payload = JSON.stringify(body)
    return this.http.post(endpoint, payload, { headers: { 'sessionID': this.checkoutParams.sessionID, 'Content-Type': 'application/json' } }).pipe(timeout(this.environmentService.timeOutValue * 1000))
      .subscribe({
        error: e => this.logService.error("Error in initiateCheckout ", e)
      })
  }


  getCartTotal() {
    const endpoint = this.environmentService.kioskServerUrl + '/api/MobileUI/GetCartTotal'
    const headers = new HttpHeaders().append('sessionID', sessionStorage.getItem("sessionID"));
    const params = new HttpParams().append('deviceID', this.checkoutParams.deviceID);
    this.http.get(endpoint, { headers, params }).pipe(timeout(this.environmentService.timeOutValue * 1000)).subscribe(data => {
      this.handleCartTotal(data)
    })
  }

  private handleCartTotal(data) {
    if (data.total.length > 1) {
      let cartTotal = data.total.substring(1)
      let cartTotalNumber = Number(cartTotal)
      if (!isNaN(cartTotalNumber) && cartTotalNumber > 0) {
        this.purchaseDone = true
      }
    }
  }
}
