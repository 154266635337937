
/* --------------------------------------------------------------------------------------
   device-test.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { forkJoin, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogService } from 'src/app/services';
import { SessionService } from 'src/app/services/session.service';
declare function xrxJobMgmtGetInterfaceVersion(url, callback, errorCallback);
declare function xrxTemplateGetInterfaceVersion(url, callback, errorCallback);
declare function xrxScanV2GetInterfaceVersion(url, callback, errorCallback);
declare function xrxPrintGetInterfaceVersion(url, callback, errorCallback);
declare function xrxCopyGetInterfaceVersion(url, callback, errorCallback);
declare function xrxWsSnmpGetInterfaceVersion(url, callback, errorCallback);



@Injectable({
    providedIn: 'root'
})
export class DeviceTestService {
    constructor(
        private sessionService: SessionService,
        private logService: LogService
    ) {

    }
    getUnavailableServices(deviceCap){
        this.logService.writeToLocalStorage("getUnavailableServices is called. ServiceSupported= " ,JSON.stringify(deviceCap), true) 
        var services: any;
            if(deviceCap.includes("WorkflowScanning") && deviceCap.includes("Copy")){
              services = [
                  { function: xrxJobMgmtGetInterfaceVersion, translationKey: 'FAULT.BODY.JOB_MANAGEMENT_EXTENSION'},
                  { function: xrxTemplateGetInterfaceVersion, translationKey: 'FAULT.BODY.SCAN_TEMPLATE_MANAGEMENT'},
                  { function: xrxScanV2GetInterfaceVersion, translationKey: 'FAULT.BODY.SCAN_SERVICES'},
                  { function: xrxPrintGetInterfaceVersion, translationKey: 'FAULT.BODY.PRINT_SERVICES'},
                  { function: xrxCopyGetInterfaceVersion, translationKey: 'FAULT.BODY.COPY_SERVICES'},
                  { function: xrxWsSnmpGetInterfaceVersion, translationKey: 'FAULT.BODY.DEVICE_CONFIGURATION'}
              ];
            }
          else {
                services = [
                    { function: xrxJobMgmtGetInterfaceVersion, translationKey: 'FAULT.BODY.JOB_MANAGEMENT_EXTENSION' },
                    { function: xrxPrintGetInterfaceVersion, translationKey: 'FAULT.BODY.PRINT_SERVICES' },
                    { function: xrxWsSnmpGetInterfaceVersion, translationKey: 'FAULT.BODY.DEVICE_CONFIGURATION' }
                ];
          }
        return forkJoin(services.map(s => this.serviceCallWrapper(s)))
            .pipe(map(r => r.filter(s => s !== null)));
  }
    serviceCallWrapper(service) {
        const result = new Promise((resolve) => {
            service.function(
                this.sessionService.deviceUrl,
                () => resolve(null),
                () => resolve(service.translationKey)
            );
        });
        return from<any>(result);
    }
}
