/* --------------------------------------------------------------------------------------
   eip-web.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { eipconst } from './eip.const';
declare function xrxCreateTag(label, type, value)
declare function xrxCallWebservice(url, request, callback, errorCallback)

@Injectable({
    providedIn: 'root'
  })
  export class EipWebService {
      request(deviceUrl: string, deviceApi: string, method: string, soap11: boolean) {
        const accessInfoPath = '/webservices/office/' + deviceApi + '/1'
        const accessInfoNamespace = 'xmlns="http://www.xerox.com/webservices/office/' + deviceApi + '/1"'
        const accessInfoTag = xrxCreateTag( method, accessInfoNamespace, '' ) 
        const requestStart = soap11? eipconst.XRX_SOAP11_SOAPSTART : eipconst.XRX_SOAPSTART
        const requestEnd = soap11? eipconst.XRX_SOAP11_SOAPEND : eipconst.XRX_SOAPEND
        const request =  requestStart + accessInfoTag + requestEnd
        return this.callWebService(deviceUrl + accessInfoPath, request)
      }
      private callWebService(deviceUrl, request) {
          const result = new Promise((resolve, reject) => {
              xrxCallWebservice(
                deviceUrl,
                request,
                (env, response) => resolve(response),
                error => reject(error)
              )
          })
          return from(result)
      }
  }