
/* --------------------------------------------------------------------------------------
   pwa-session.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { flatMap, map, share, tap, timeout } from 'rxjs/operators';
import { forkJoin, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DeviceCapabilitiesService, DeviceInfoService, AccessInfoService, EmailContentService } from '../shared/eip';
import { SessionService } from './session.service';
import { KiosksessionRequestService } from './kiosksession-request.service';
import { EnvironmentService } from './environment.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class PwaSessionService {
  sessionId: ReplaySubject<string> = new ReplaySubject<string>();
  isAltalink: boolean = false;
  isDiscoveyDevice: boolean =false;
  isFujiDevice: boolean = false;
  EipSoftwareVersion: string;
  deviceModel: string;

  constructor(
    private http: HttpClient,
    private deviceInfoService: DeviceInfoService,
    private deviceCapabilitiesService: DeviceCapabilitiesService,
    private accessInfoService: AccessInfoService,
    private sessionService: SessionService,
    private emailContentService: EmailContentService,
    private kiosksessionRequestService: KiosksessionRequestService,
    private environmentService: EnvironmentService,
    private logService: LogService
  ) { }

  getSessionUrl() {
    return this.sessionService.getDeviceInfo().pipe(
      flatMap((di: any) => forkJoin({
        kioskSession: this.startKioskSession(di.serial),
        emailEnabled: this.enableEmail(di.model)
      }).pipe(timeout(30000),map(r => Object.assign(r, di)), // If any one of observable is not emitted within 30sec period, Timeout error will occur
        tap(s => this.pushSessionDetail(s)),
        map(t => this.getQrUrl(this.getSessionData(t.serial, t.kioskSession.sessionKey)))
      ))
    ).pipe(share())
  }

  private pushSessionDetail(session) {
    this.sessionId.next(session.kioskSession.sessionID)
    sessionStorage.setItem("sessionID", session.kioskSession.sessionID)
  }

  enableEmail(model) {
    return this.emailContentService.getSender()
    .pipe(map(r => !!r[0] && r[1]? 1 : 0))
  }

  isEmailAppOpen(isVersa) {
    return this.accessInfoService.getAccessInfo(this.sessionService.deviceUrl, isVersa).pipe(
      map((r: any) => r.Email == 'xeOPEN')
    )
  }

  private startKioskSession(serial) {
    sessionStorage.setItem("serialNumber", serial)
    return this.kiosksessionRequestService.doKioskSessionRequestInitialize(serial)
  }

  private getSessionData(serial: string,sessionKey: string) {
    return {
      s: serial,
      k: sessionKey
    }
  }

private getEncodedDeviceType(model, serviceSupported) {
    const modelType = { 'v': 'v3', 'vr': 'v4', 'a': 'a'}
    const deviceModel = this.getDeviceModel(serviceSupported);
    const deviceType = this.getDeviceType(model);
    return deviceType !== 'a' ? modelType[deviceType] + deviceModel : deviceType
}

public getDeviceModel(serviceSupported){
    if(serviceSupported.includes("WorkflowScanning") && serviceSupported.includes("Copy"))
      return 'm';
    else
      return 'p';
  }

  getDeviceType(model) {
    if (model.match(/Alta.*/))
    {
      this.isAltalink = true;
      return 'a'; //altalink a3
    }      

    if (model.match(/Versa.*[CB]\d\d\d\d/))
      return 'v'; //verslink a3

    return 'vr'; //versalink restricted to a4
  }

  private getQrUrl(sessionData: object) {
    const sessionDataString = btoa(JSON.stringify(sessionData));
    return `${this.environmentService.pwaUrl}?session=${sessionDataString}`;
  }

  public GetDeviceControler() {
    let kioskController = "";
    this.sessionService.getDeviceInfo().subscribe(deviceInfo => {
      let majorVersion = deviceInfo.EIPSoftware.split('.')
      this.EipSoftwareVersion = majorVersion[0]
      this.deviceModel = this.getDeviceType(deviceInfo.model)
    });

    if ((this.deviceModel == 'v' || this.deviceModel == 'vr') && (parseInt(this.EipSoftwareVersion) < 5)) {
      kioskController = "Fuji Business Controller"
      this.isFujiDevice = true
    }
    else {
      kioskController = "Discovery Controller"
      this.isDiscoveyDevice = true
    }
    return kioskController
  }
}
