
/* --------------------------------------------------------------------------------------
   job-status.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { LogService, SessionService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { RestrictedUsers } from 'src/app/shared/eip/eip.const';
import { EnvironmentService } from 'src/app/services/environment.service';
declare function xrxJobMgmtParseGetJobDetails(response: string)
declare function xrxFindElement(jobDetails: any, elements: string[])
declare function xrxFindElements(jobDetails: any, elements: string[])
declare function xrxGetValue(node: any)
declare function xrxJobMgmtCancelJob(deviceUrl: string, jobType: string, jobId: string, callback: any, errorCallback: any, timeout: number)
declare function xrxJobApprovalUpdate(proceedState: string, jobId: string, jobType: string, jobToken: string, url: string, callback: any, errorCallback: any, timeout: number)
declare function xrxCallWebservice(sendUrl: string, sendReq: any, callback_success: any, callback_failure: any, timeout: any, headers: any, username: any, password:any, async:boolean)
declare function xrxCallAjax(sendUrl: string, sendReq: any, method:any, callback_success: any, callback_failure: any, timeout: any, headers: any, username: any, password:any, async:boolean)
declare function xrxCompletedJob(deviceID: string, completedJobID: string, accounting: string, callback_success: any, callback_failure: any, timeout: number)
declare function xrxCancelKioskJob(deviceID: string, cancelJobID: string, callback_success: any, callback_failure: any)
declare function xrxJobMgmtListActiveQueueSecure( url:string, admin:string, adminPassword:string, username:string, password:string, callback_success:any, callback_failure:any, timeout: number) 
declare function xrxJobMgmtParseListActiveQueueSecure( response:any ) 
declare function xrxJobMgmtResumeJobSecure(deviceUrl: string, jobType: string, jobId: any, admin: string, adminPassword: string, username: string, password: string, pin: number, callback_success: any, callback_failure: any, timeout: number)
declare function xrxJobMgmtCancelJobSecure(deviceUrl: string, jobType: string, jobId: any, admin: string, adminPassword: string, username: string, password: string, pin: number, callback_success: any, callback_failure: any, timeout: number)
declare function xrxJobMgmtGetJobDetailsSecure(deviceUrl: string, jobType: string, jobId: string,admin: string, adminPassword: string, username: string, password: string, callback: any, errorCallback: any, timeout: number)
declare function xrxJobMgmtListActiveQueue( url:string, callback_success: any, callback_failure: any )
declare function xrxJobMgmtParseListActiveQueue( response:any )

@Injectable({
    providedIn: 'root'
  })
  export class JobManagementService {
    constructor(
      private logService: LogService,
      private  sessionService: SessionService,
      private environmentService: EnvironmentService) {}

  getJobDetails(deviceUrl, jobType, jobId) {
    const result = new Promise((resolve, reject) => {
      xrxJobMgmtGetJobDetailsSecure(
        deviceUrl,
        jobType,
        jobId,
        '',
        '',
        '',
        '',
        (env, response) => resolve(this.parseStatus(response)),
        (env, response) => {
          this.logService.warn("Error in getJobDetails: ", response)
          reject(response)},
        0.9
        )
    })
    return from(result)
  }

    retrieveJBAData() {
        return this.processJBAData(environment.accountUrl)
    }

    purgeJBAData() {
        return this.processJBAData(environment.purgeUrl)
    }

    cancelPausedJob(jobId, jobType, jobToken, deviceUrl) {
        return this.processJob('Reject', jobId, jobType, jobToken, deviceUrl)
    }

    releasePauseJob(jobId, jobType, jobToken, deviceUrl) {
        return this.processJob('Release', jobId, jobType, jobToken, deviceUrl)
    }

    sendCompletedStatus(deviceID, completedJobID, accounting) {
        const result = new Promise((resolve, reject) => {
          xrxCompletedJob(
            deviceID,
            completedJobID,
            accounting,
            (env, response) => resolve(response),
            (env, response) => {
              this.logService.error("Error in xrxCompletedJob: ", response)
              reject(response)},
            this.environmentService.timeOutValue
            )
        })
        return from(result)
    }

  private processJob(proceedState, jobId, jobType, jobToken, deviceUrl) {
    const result = new Promise((resolve, reject) => {
      xrxJobApprovalUpdate(
        proceedState,
        jobId,
        jobType,
        jobToken,
        deviceUrl,
        (env, response) => {
          this.logService.debug("Received response from xrxJobApprovalUpdate proceedState = " + proceedState)
          resolve(response)
        },
        (env, response) => {
          this.logService.error('Error in process Job ', JSON.stringify(response))
          reject(response)
        },
        this.environmentService.timeOutValue)
    })
    return from(result)
  }

  private processJBAData(deviceUrl) {
    var headers = [["Content-Type", "application/xrx-acct-data"]]
    const result = new Promise((resolve, reject) => {
      xrxCallAjax(
        deviceUrl,
        '',
        'GET',
        headers,
        (env, response) => resolve(response),
        (env, response) => reject(response),
        15, null, null, false)
    });
    return from(result)
  }

    private parseStatus(jobDetailsResponse) {
        const jobDetails = xrxJobMgmtParseGetJobDetails(jobDetailsResponse);
        const jobStateNode = xrxFindElement(jobDetails, ["JobInfo", "JobState"])
        const jobStateReasonsNode = xrxFindElement(jobDetails, ["JobInfo", "JobStateReasons"])
        return {status: xrxGetValue(jobStateNode), reasons: xrxGetValue(jobStateReasonsNode)}
    }
    cancelJob(deviceUrl, jobType, jobId) {      
      const result = new Promise((resolve, reject) => {
        xrxJobMgmtCancelJob(
          deviceUrl,
          jobType,
          jobId,
          (env, response) => resolve(response),
          (env, response) => {
            this.logService.error("Error in CancelJob: ", response)
            reject(response)},
          this.environmentService.timeOutValue)
      })
      return from(result)
    }

  getJobMgmtListActiveSecureJob(deviceUrl) {
    const result = new Promise((resolve, reject) => {
      xrxJobMgmtListActiveQueueSecure(
        deviceUrl,
        '',
        '',
        '',
        '',
        (env, response) => resolve(this.parseListOfActiveJobs(response)),
        (env, response) => {
          this.logService.error("Error in xrxJobMgmtListActiveQueueSecure : ", response)
          reject(response)},
        this.environmentService.timeOutValue)
    })
    return from(result)
  }

  private parseListOfActiveJobs(securePrintDocList) {
    let secureJobList = [];
    try {
      let parseSecureJobList = xrxJobMgmtParseListActiveQueueSecure(securePrintDocList);
      const jobInfoList = xrxFindElements(parseSecureJobList, ["JobInfo"])
      for (let index = 0; index < jobInfoList.length; index++) {
        secureJobList.push(
          {
            "jobID": xrxGetValue(xrxFindElement(jobInfoList[index], ["JobInfo", "JobId"])),
            "jobname": xrxGetValue(xrxFindElement(jobInfoList[index], ["JobInfo", "JobName"])),
            "jobOwner": xrxGetValue(xrxFindElement(jobInfoList[index], ["JobInfo", "JobOriginatingUserName"])),
            "submissionTime": xrxGetValue(xrxFindElement(jobInfoList[index], ["JobInfo", "DateTimeAtCreation"])),
            "jobStateReasons": xrxGetValue(xrxFindElement(jobInfoList[index], ["JobInfo", "JobStateReasons"])) 
          })
      }
    } catch (e) {
      secureJobList = [];
    }
    return this.getUserJobList(secureJobList);
  }

  getUserJobList(secureJobList) {
    let userJobList = [];
    if(this.sessionService.isFujiBasedProduct) {
      for (let i = 0; i < secureJobList.length; i++) {
        let jobOwner = secureJobList[i]['jobOwner'];
        if (!(RestrictedUsers.includes(jobOwner))) {
          userJobList.push(secureJobList[i]);
        }
      }
      return userJobList;
    }
    else {
      for (let i = 0; i < secureJobList.length; i++) {
        let jobOwner = secureJobList[i]['jobOwner'];
        //For Discovery device,Secureprintjoblist contains only those jobs that has jobStateReason of JobPasswordWait
        if (!(RestrictedUsers.includes(jobOwner)) && (secureJobList[i]['jobStateReasons'] == 'JobPasswordWait')) {
          userJobList.push(secureJobList[i]);
        }
      }
      return userJobList;
    }
  }

  resumeSecurePrintJob(deviceUrl, jobType, jobId, pin) {
    const result = new Promise((resolve, reject) => {
      xrxJobMgmtResumeJobSecure(
        deviceUrl,
        jobType,
        jobId,
        '',
        '',
        '',
        '',
        pin,
        (env, response) => resolve(response),
        (env, response) => {
          this.logService.error("Error in xrxJobMgmtResumeJobSecure: ", response)
          reject(response)},
        7)
    })
    return from(result)
  }

  deleteSecurePrintJob(deviceUrl, jobType, jobId, pin) {
    const result = new Promise((resolve, reject) => {
      xrxJobMgmtCancelJobSecure(
        deviceUrl,
        jobType,
        jobId,
        '',
        '',
        '',
        '',
        pin,
        (env, response) => resolve(response),
        (env, response) => {
          this.logService.error("Error in deleteSecurePrintJob: ", response)
          reject(response)},
        this.environmentService.timeOutValue)
    })
    return from(result)
  }

  getListActiveQueueJobDetails(deviceUrl) {
    const result = new Promise((resolve, reject) => {
      xrxJobMgmtListActiveQueue(
        deviceUrl,
        (env, response) => resolve(this.parseListActiveQueue(response)),
        (env, response) => reject(response))
    })
    return from(result)
  }

  private parseListActiveQueue(Response) {
    const jobDetails = xrxJobMgmtParseListActiveQueue(Response);
    const jobIDNode = xrxFindElement(jobDetails, ["JobInfo", "JobId"])
    const JobTypeNode = xrxFindElement(jobDetails, ["JobInfo", "JobType"])
    const jobStateNode = xrxFindElement(jobDetails, ["JobInfo", "JobState"])
    const jobStateReasonsNode = xrxFindElement(jobDetails, ["JobInfo", "JobStateReasons"])
    return { JobId: xrxGetValue(jobIDNode), JobType: xrxGetValue(JobTypeNode), status: xrxGetValue(jobStateNode), reasons: xrxGetValue(jobStateReasonsNode) }
  }
}

