import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, delay, flatMap, map, share, switchMap } from 'rxjs/operators';
import { DeviceCapabilitiesService, DeviceNetworkService, EmailContentService } from '../shared/eip';
import { EnvironmentService } from './environment.service';
import { PwaSessionService } from './pwa-session.service';
import { SessionService } from './session.service';
import { SnmpService } from '../shared/eip/snmp.service';
import { oidCodes } from '../shared/eip';
import { environment } from 'src/environments/environment';
import { LogService } from './log.service';
declare function xrxWsSnmpParseGet(response: string)
declare function xrxWsSnmpGet(url: string, scope: string, oid: string, callback: any, errorCallback: any, timeout: number, async: boolean): any;

@Injectable({
  providedIn: 'root'
})
export class DeviceconfigParamsService {
  timeElapsed = {
    GetDeviceInfo: 0,
    EmailSupported: 0,
    colorSupported: 0,
    deviceLocation: 0,
    inputTrays: 0,
    finishingSupported: 0,
  }
  serviceSupported: any;
  deviceInfo: any;
  deviceIp: any;
  emailSupported: any;
  colorSupported: any;
  deviceName: any;
  deviceLocation: any;
  inputTrays: any;
  finishingSupported: any;
  impressionMode: any;
  deviceConfigValues: any;
  startTime: number

  readonly endpoint = 'http://localhost/webservices/SNMP/1'
  constructor(
    private http: HttpClient,
    private deviceCapabilitiesService: DeviceCapabilitiesService,
    private sessionService: SessionService,
    private deviceNetworkService: DeviceNetworkService,
    private pwaSessionService: PwaSessionService,
    private emailContentService: EmailContentService,
    private environmentService: EnvironmentService,
    private snmpService: SnmpService,
    private logService: LogService
  ) {
    this.deviceCapabilitiesService.getServiceSupported()
      .subscribe(s => this.serviceSupported = s);
    // this.deviceNetworkService.getDeviceIp(this.sessionService.deviceUrl)
    // .subscribe(deviceIp => this.deviceIp = deviceIp)
  }

  // to serialize all observable and assign to object and finally subscribe it
  get() {
    return this.sessionService.getDeviceInfo().pipe(
      switchMap(info => {
        this.timeElapsed.GetDeviceInfo = Math.abs(this.deviceCapabilitiesService.startTime - Date.now())
        this.deviceInfo = info
        return this.isEmailSupported(info.model).pipe(catchError(err => of(err)));
      }),
      switchMap(emailSupported => {
        this.timeElapsed.EmailSupported = Math.abs(this.startTime - Date.now())
        this.emailSupported = emailSupported
        return this.deviceCapabilitiesService.getDeviceColorSupport().pipe(catchError(err => of(err)))
      }),
      switchMap(colorSupported => {
        this.timeElapsed.colorSupported = Math.abs(this.deviceCapabilitiesService.startTime - Date.now())
        this.colorSupported = colorSupported
        return this.getDeviceLocation().pipe(catchError(err => of(err)))
      }),
      switchMap(deviceLocation => {
        this.timeElapsed.deviceLocation = Math.abs(this.startTime - Date.now())
        this.deviceLocation = deviceLocation
        return this.deviceCapabilitiesService.setTrayInformation().pipe(catchError(err => of(err)))
      }),

      switchMap(inputTrays => {
        this.timeElapsed.inputTrays = Math.abs(this.deviceCapabilitiesService.startTime - Date.now())
        this.inputTrays = inputTrays
        return this.deviceCapabilitiesService.getFinishingSupported().pipe(catchError(err => of(err)))
      }),

      switchMap(finishingSupported => {
        this.timeElapsed.finishingSupported = Math.abs(this.deviceCapabilitiesService.startTime - Date.now())
        this.finishingSupported = finishingSupported
        return this.fetchImpressionMode(this.deviceInfo.model).pipe(catchError(err => of(err)))
      }),
      map(impressionMode => {
        var completedTime = Date.now()
        //Due to memory concern logging only FinishingSupported and ColorSupported, but not InputTrays. 
        this.logService.writeToLocalStorage("GetDeviceCapabilities response= " , "FinishingSupported= " + JSON.stringify(this.finishingSupported) + " ColorSupported= " + JSON.stringify(this.colorSupported), true )
        this.logService.debug("Time elapsed for deviceInfo =" + this.timeElapsed.GetDeviceInfo + ", emailSupported =" + this.timeElapsed.EmailSupported + ", getDeviceColorSupport =" + this.timeElapsed.colorSupported + ", deviceLocation =" + this.timeElapsed.deviceLocation + ", inputTrays =" + this.timeElapsed.inputTrays + ", finishingSupported =" + this.timeElapsed.finishingSupported + ", impressionMode = " + Math.abs(this.startTime - completedTime))
        this.impressionMode = impressionMode
      })
    ).pipe(catchError(err => of(err))).pipe(map(s => {
      this.deviceConfigValues = {
        emailSupported: this.emailSupported,
        colorSupported: this.colorSupported,
        deviceName: this.deviceInfo.model,
        deviceLocation: this.deviceLocation,
        inputTrays: this.inputTrays,
        finishingSupported: this.finishingSupported,
        impressionMode: this.impressionMode,
        systemsoftware: this.deviceInfo.systemsoftware
      }
      return this.getDeviceConfigBody(this.deviceInfo.model, this.deviceInfo.mac, this.deviceConfigValues.deviceName, this.deviceConfigValues.deviceLocation, this.deviceConfigValues.emailSupported, this.deviceConfigValues.colorSupported, this.deviceConfigValues.inputTrays, this.deviceConfigValues.finishingSupported, this.deviceConfigValues.impressionMode, this.deviceInfo.systemsoftware)
    })
    ).pipe(catchError(err => {
      return of(err)
    }))
  }


  public getDeviceType() {
    this.startTime = Date.now()
    if (this.serviceSupported.includes("WorkflowScanning") && this.serviceSupported.includes("Copy"))
      return 'MFD';
    else
      return 'SFP';
  }

  private getDeviceName() {
    this.startTime = Date.now()
    return this.snmpService.snmpGet(environment.deviceUrl, oidCodes.getdDviceNameOID).pipe(map(r => r.returnValue))
  }

  private getDeviceLocation() {
    this.startTime = Date.now()
    return this.snmpService.snmpGet(environment.deviceUrl, oidCodes.getDeviceLocationOID).pipe(map(s => { return s.returnValue }))
  }

  private fetchImpressionMode(model) {
    this.startTime = Date.now()
    return this.snmpService.snmpGet(environment.deviceUrl, oidCodes.fetchImpressionModeOID).pipe(
      map(i => i.returnValue
      ), map(s => { return this.getImpressionMode(s, model) }))
      .pipe(catchError(e => { return this.getImpressionMode("error", model) }))

  }

  private getMaxPaperSizeSupported(model) {
    const deviceModel = this.pwaSessionService.getDeviceType(model)
    return deviceModel == ("a" || "v") ? "A3" : "A4"
  }

  private isEmailSupported(model) {
    this.startTime = Date.now()
    return this.emailContentService.getSender().pipe(map(r => !!r[0] && r[1] ? true : false))
  }

  private isFaxSupported() {
    const deviceType = this.getDeviceType();
    return deviceType == "MFD" ? true : false
  }

  private parseInput(body) {
    return body
      .split("returnValue")[1]
      .replace('>', '')
      .replace('</', '');
  }

  private getImpressionMode(impressionMode, model) {
    let impression = ""
    if (this.isA3device(model) && impressionMode != "error") {
      if (Number(impressionMode) >= 4) {
        impression = "A4"
      } else {
        impression = "A3"
      }
    }
    return impression
  }

  private isA3device(model) {
    if (this.pwaSessionService.getDeviceType(model) != 'vr') {
      return true
    }
    return false
  }

  private getTimezoneOffset() {
    let d = new Date();
    return d.getTimezoneOffset();
  }

  private getDeviceConfigBody(model, mac, name, location, emailSupported, colorSupported, inputTrays, finishing, impressionMode, systemsoftware) {
    const body = {
      deviceType: this.getDeviceType(),
      deviceModel: model,
      deviceName: name,
      deviceLocation: location,
      macAddress: mac,
      outputColor: colorSupported,
      emailSupported: emailSupported,
      faxSupported: this.isFaxSupported(),
      maxPaperSizeSupported: this.getMaxPaperSizeSupported(model),
      inputTrays: inputTrays,
      stapleFinishingsSupported: finishing.stapleFinishingsSupported,
      punchFinishingsSupported: finishing.punchFinishingsSupported,
      timeZoneOffset: this.getTimezoneOffset(),
      impressionMode: impressionMode,
      softwareVersion: systemsoftware,
      installedAppVersion: this.sessionService.appVersion,
      KioskController: this.pwaSessionService.GetDeviceControler()
    }
    this.logService.debug("deviceconfig-params.service getDeviceConfig Body" + JSON.stringify(body))
    return body
  }

}
