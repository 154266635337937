
/* --------------------------------------------------------------------------------------
   job.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { flatMap, retryWhen, scan, tap, delay, catchError, switchMap } from 'rxjs/operators';
import { LogService, SessionService, PwaSessionService} from '.';
import { JobType } from '../app.types';
import { CopyService, PrintService, ScanService, JobManagementService } from '../shared/eip';
import { CheckoutService } from './checkout.service';
import { SecurePrintJobService } from './secure-print-job.service';
import { JbaconfigService } from './jbaconfig.service';
import { DeviceAlertService } from './device-alert.service';
import { SnmpService } from '../shared/eip/snmp.service';
import { oidCodes } from '../shared/eip';


@Injectable({
    providedIn: 'root'
})

export class JobService {
    currentJob: Subject<any> = new Subject<any>()
    error: Subject<any> = new Subject<any>()
    receipt: Subject<any> = new Subject<any>()
    copyNotification: Subject<any> = new Subject<any>()
    private currentJobState: string = ""
    private statusTimer$: Observable<number> = interval(1000)
    private currentJob$: Observable<any> = new Observable<any>()
    private pollJobStatusSubscription: Subscription
    private lastCall: number = 0
    private completedNoneCount: number = 0
    private jobType: JobType
    private jobId: string
    private newJobId: string
    private jobToken: string = "123456789abcdefa"
    private completedJobInfo: any
    private isCancelled: boolean
    overallStatus: any
    private errorPopup: boolean
    private prevMsg: string = ""
    private jobReleaseTime: number = 0
    private jobPrintingTime: number
    private purgeCompleted: BehaviorSubject<any> = new BehaviorSubject<any>(null)
    private release: boolean = false
    private isGetJobDetailsFailed: boolean = false
    private errorCounter: number = 0
    private secureJobPin: string
    private isSecureJobCanceled: boolean = false
    private faxTransfered: boolean
    private checkFileTransferCall: boolean
    private count: number = 0
    parser: any
    xmlResponse: any
    deviceAlertValue: any
    deviceAlertLength: any
    mobileJobWaitingForApproval: boolean = false
    retVal

    constructor(
        private copyService: CopyService,
        private scanService: ScanService,
        private printService: PrintService,
        private sessionService: SessionService,
        private jobManagementService: JobManagementService,
        private logService: LogService,
        private checkoutService: CheckoutService,
        private securePrintJobService: SecurePrintJobService,
        private jbaconfigService: JbaconfigService,
        private deviceAlertService: DeviceAlertService,
        private pwaSessionService: PwaSessionService,
        private snmpService: SnmpService
    ) {

    }

    setsecureJobPin(pin){
        this.secureJobPin = pin;
    }

    startPrintJobConversion() {
        this.overallStatus = { release: false, error: false, abortBySystem: false, jobApproval: false, cancelbyUser: false, causeBySystem: false, deviceFault: false, heldResource: false }
        this.setNextCurrentJobStatus(JobType.Print, 'Converting', 'Converting', false, "XwpcConversion")
    }

    printJobConversionError() {
        this.setNextCurrentJobStatus(JobType.Print, 'Completed', 'cancelConversion', false, "CoversionOrTransferFailed")
    }

    cancelPrintJobConversion() {
        this.overallStatus.cancelbyUser = true
        this.sessionService.setLogoutErrorMessage('cancelled')
        this.setNextCurrentJobStatus(JobType.Print, 'Completed', 'cancelConversion', false, "XwpcConversion")
    }

    startJob(jobType: JobType, template: any, url?: string) {
        this.logService.debug('JobService startJob ' + this.getJobTypeString(jobType))
        if (Math.abs(this.lastCall - Date.now()) < 500)
            return null
        this.lastCall = Date.now()
        this.sessionService.setJobRunning()
        this.sessionService.clearConvertionStarted()
        switch (jobType) {
            case JobType.Copy:
                this.currentJob$ = this.copyService.copy(this.sessionService.deviceUrl, template)
                break;
            case JobType.Scan:
                this.currentJob$ = this.scanService.scan(this.sessionService.deviceUrl, template)
                break;
            case JobType.Print:
                this.currentJob$ = this.printService.print(this.sessionService.deviceUrl, url, template)
                break;
            case JobType.Fax:
                this.currentJob$ = this.scanService.fax(this.sessionService.deviceUrl, template)
                break;
        }

        this.initializeJobInfo(jobType)

        if (jobType !== JobType.SecurePrint) {
            this.currentJob$.subscribe({
                next: jobId => this.followJobStatus(jobId, jobType),
                error: error => this.handleError(jobType, error, 'starting job', template)
            })
        } else {
            this.followJobStatus(template, jobType)
        }
        this.currentJob.next({ jobType: jobType, status: 'computingCost' })
    }

    followJobStatus(jobId: string, jobType: JobType): Subscription {
        // For Alexandra JobId conversion is not required
        if (jobId.includes("job:")) this.jobId = jobId
        // For Fuji controller devices JobId conversion is required
        else {
            let processingJobId = parseInt(jobId, 16)
            this.jobId = processingJobId.toString()
        }

        this.logService.debug('FollowJobStatus: JobId: ' + this.jobId + ' JobType: ' + jobType)
        //pollJobStatusSubscription : It will poll the getJobDetails if the error occured contiously more than 3 times changes the job status, reasons and throws error.
        this.pollJobStatusSubscription = this.pollDeviceJobStatus(jobId, jobType).subscribe({
            next: (status: any) => {
                if (status != null) {
                    this.errorCounter = 0
                    this.isGetJobDetailsFailed = false
                    this.updateCurrentStatus(jobType, status, jobId)
                }
            },
            error: error => {
                this.isGetJobDetailsFailed = true
                this.logService.error('followJobStatus: error:', JSON.stringify(error))
                this.updateCurrentStatus(jobType, { status: 'Completed', reasons: 'AbortBySystem' }, jobId)
            }
        });
        return this.pollJobStatusSubscription
    }

    /**
     * @summary This function polls the getJobDetails every second and returns the job status.
     * @param jobId 
     * @param jobType 
     * @catchError Handles the error and return the error message to the source observable.
     * @returns Observable<any>
     */
    pollDeviceJobStatus(jobId: string, jobType: JobType): Observable<any> {
        return this.statusTimer$.pipe(switchMap(() => this.jobManagementService.getJobDetails(this.sessionService.deviceUrl, this.getJobTypeString(jobType), jobId)
            .pipe(catchError(error => {
                this.errorCounter++;
                if (this.errorCounter > 3) return throwError(error);
                else return of(null); // Return a safe observable `of(null)` to continue polling
            })
            )
        ))
    }

    updateCurrentStatus(jobType, status, jobId) {
        if (!this.pollJobStatusSubscription || this.pollJobStatusSubscription.closed)
            return;

        // Logging job state transitions
        var msg = "Job ID: " + this.jobId + " State: " + status.status + " Reason: " + status.reasons;
        if (msg != this.prevMsg) this.logService.info(msg)
        this.prevMsg = msg;

        let nextAction = this.processDeviceStatus(jobType, status.status, status.reasons, jobId)
        if (nextAction.finished) {
            this.completedNoneCount = 0
            if (!this.completedJobInfo.started && this.overallStatus.jobApproval) {
                this.completeJob()
            }
        }

        if (nextAction.state == "jobCompleted" || nextAction.state == "jobCompleteWithErrorBeforeApproval" || nextAction.state == "secureJobCanceled") {
            if(!(jobType == JobType.SecurePrint)) {
                this.pollJobStatusSubscription.unsubscribe()
            }
            //We unsubscribe to polljobstatus only after getting 'jobCompleted' state from UpdateKioskStatus for PrintFromPC job.
            else if((jobType == JobType.SecurePrint) && (this.sessionService.lastJobStatus.state == 'jobCompleted')) {
                this.pollJobStatusSubscription.unsubscribe()
            }
        }

        if (nextAction.state == "faxJobCompleted" && jobType == JobType.Fax && !this.overallStatus.abortBySystem) {
            this.setNextCurrentJobStatus(jobType, 'Completed', 'JobCompletedSuccessfully', true, 'jobCompleted')
        }

        if (nextAction.state == "secureJobCanceled" && jobType == JobType.SecurePrint && this.overallStatus.abortBySystem) {
            this.setNextCurrentJobStatus(jobType, 'Completed', 'AbortBySystem', true, 'jobCompleted')
        }

        if (jobType == JobType.Copy && status.reasons == "WaitingForSide2")
              this.setNextCurrentJobStatus(jobType, status.status, status.reasons, false, false)

        if (nextAction.error) {
            const errorReasons = nextAction.cancel? 'CopyTwoSidedError' : status.reasons
            this.handleErrorscenarios(jobType, nextAction, errorReasons)
        } else {
            this.passCurrentJobInformation(jobType, status, nextAction)
        }

        if (!nextAction.processing && !nextAction.finished) {
            this.completedNoneCount++
            if (this.completedNoneCount == 5) {
                this.completedNoneCount = 0
                this.setNextCurrentJobStatus(jobType, status.status, status.reasons, true, true)
            }
        }
    }

    releaseJob(jobID,jobType,jobToken) {
        this.logService.debug("release job in job service: " + jobID)
        this.mobileJobWaitingForApproval = false
        this.newJobId = jobID
        this.overallStatus.toBeReleased = true;
        this.jobReleaseTime = Date.now()
        this.releasePausedJob(jobID,jobType,jobToken)
    }

    cancelJob(jobID, jobType, jobToken) {
        this.logService.debug("cancelJob job in job service: " + jobID)
        this.mobileJobWaitingForApproval = false
        this.newJobId = jobID
        this.overallStatus.abortBySystem = true
        this.cancelPausedJob(jobID, jobType, jobToken)
    }

    deleteSecureJob() {
        let jobId
        if (this.pwaSessionService.isFujiDevice) {
            jobId = this.convertDecimalToHexa(this.jobId)
        }
        else {
            jobId = this.jobId
        }
        this.jobManagementService.deleteSecurePrintJob(this.sessionService.deviceUrl, this.getJobTypeString(this.jobType), jobId, this.secureJobPin)
            .pipe(retryWhen(
                errors => {
                    return errors.pipe(
                        scan((count, error) => {
                            if (count > oidCodes.retryCount) throw (error);
                            this.logService.warn("retry deleteSecureJob attempt: " + count);
                            return count + 1;
                        }, 1),
                        delay(2000),
                        tap(() => this.logService.warn("retrying deleteSecureJob...")))
                }
            ))
            .subscribe({
                next: s => {
                    this.logService.debug("sucessfully deleted Secure Job from MFD")
                    this.isSecureJobCanceled = true
                },
                error: e => {
                    this.logService.error("Error in deleteSecureJob:" + JSON.stringify(e), "")
                    this.isSecureJobCanceled = true
                    this.setNextCurrentJobStatus(this.jobType, 'Completed', 'AbortedAbnormallyBySystem', true, 'jobCompleted')
                }
            })
    }

    xrxCancelJob(jobId) {
        this.logService.debug('JobService: xrxCancelJob trigger')
        this.jobManagementService.cancelJob(this.sessionService.deviceUrl, this.getJobTypeString(this.jobType), jobId).subscribe({
            error: error => this.handleError(this.jobType, error, 'XRX Cancelling job')
        })
    }

    copySideTwo() {
        this.snmpService.snmpGetResponse(this.sessionService.deviceUrl, oidCodes.paperInPlaten).subscribe({
            next: (response: any) => {
                //Continue Two-sided copy job if paper is in platen else send copyNotification to main component
                if (this.sessionService.isPaperinPlaten) {
                    this.copyService.copySideTwo(this.jobId).subscribe({
                        next: s => { this.logService.debug("sucessfully copySideTwo from MFD" + this.jobId) },
                        error: error => this.handleError(this.jobType, error, 'copySideTwo job' + this.jobId)
                    })
                }
                else {
                    this.logService.warn("Cannot use feeder for side 2: Place side 2 of your document on the document glass, then select Continue.")
                    this.copyNotification.next()
                }
            },
            error: error => this.handleError(this.jobType, error, 'Getting JBA Data')
        })
    }

    cancelCopyJob() {
        this.newJobId = this.jobId
        this.overallStatus.abortBySystem = true
        this.overallStatus.jobApproval = true
        this.clearCurrentJobState()
        this.copyService.cancelCopyJob(this.jobId).subscribe({
            next: s => {
                this.logService.debug("sucessfully cancelled pausedJob from MFD")
            },
            error: error => this.handleError(this.jobType, error, 'Cancelling job')
        })
    }

    private completeJob() {
        this.logService.debug("completeJob called")
        this.purgeCompleted.next(null);
        this.completedJobInfo.started = true
        this.getJBAdataForJobID()
    }

    private getJBAdataForJobID() {
        this.getJBAdata().subscribe({
            next: (response: any) => {
                let jobID = this.jobType == JobType.SecurePrint ? this.newJobId : this.jobId; // SecurePrint jobs have a new job ID. Other jobs have a current job ID.
                if ((response == "" || response == null) || (!response.includes(jobID))) {
                    if (this.count < 4) {
                        var msg = (response == "" || response == null) ? 'Empty response' : response
                        this.logService.warn("Retrying getJBAdata count=" + this.count + "Reason: " + msg)
                        this.count = this.count + 1
                        setTimeout(() => this.getJBAdataForJobID(), 2000)
                    } else {
                        this.handleError(this.jobType, "Retry failed", 'Getting JBA Data')
                    }
                }
                else {
                    this.count = 0;
                    if (this.faxTransfered) { response = response.replace("terminated", "completed-normal") }
                    this.sendCompletedJobRequest(response);
                }
            },
            error: error => this.handleError(this.jobType, error, 'Getting JBA Data')
        })
    }
    
    validateJBADataAndContinue(jobType?: JobType,  template?)  {
        return this.getJBAdata().subscribe({
            next: (response: any) => {
                if (response != "") {
                    this.logService.debug('JobService: JBAData' + response)
                    this.purgeJBAdataAndContinue(jobType, template)
                }
                else{
                    this.logService.debug("validateJBADataAndContinue called inside else");
                    this.purgeCompleted.complete();
                }
            },
            error: error => this.handleError(this.jobType, error, 'Getting JBA Data')
        })
    }

    private getJBAdata() {
        return this.jobManagementService.retrieveJBAData()
    }

    private cancelPausedJob(jobID, jobType, jobToken) {
        this.overallStatus.jobApproval = true
        this.clearCurrentJobState()
        this.jobManagementService.cancelPausedJob(jobID, jobType, jobToken, this.sessionService.deviceUrl).subscribe({
            next: s => {
                this.logService.debug("sucessfully cancelled pausedJob from MFD")
                if(this.jobType == JobType.SecurePrint) {
                    setTimeout(() => {
                        this.deleteSecureJob();
                    }, 2000);
                }
            },
            error: error => this.handleError(this.jobType, error, 'Cancelling job')
        })
    }

    convertDecimalToHexa(JobId) {
        try {
            let hexToDec = parseInt(JobId, 10).toString(16);
            let newJobId;
            let zeroValue = '0';
            let len = hexToDec.length;
            if (len == 4) {
                newJobId = zeroValue.repeat(len) + hexToDec;
            }
            else if (len > 4) {
                newJobId = zeroValue.repeat(len - 1) + hexToDec;
            }
            else {
                newJobId = zeroValue.repeat(8 - len) + hexToDec;
            }
            return newJobId.toUpperCase();
        } catch (error) {
            this.logService.error("error in conversion: ", JSON.stringify(error))
        }
    }

    private releasePausedJob(jobID,jobType,jobToken) {
        this.logService.debug("calling releasePausedJob :" + jobID)
        this.overallStatus.jobApproval = true
        this.clearCurrentJobState()
        //When the JBA purge data is complete, we can release the job.
        this.purgeCompleted.subscribe(
            {
                error: error => this.handleError(this.jobType, error, 'Releaseing job'),
                complete: () => {
                    if (this.overallStatus.toBeReleased == true && this.newJobId == jobID) {
                        this.logService.debug("ReleasePausedJob for the JobId" + jobID + "is called")
                        this.overallStatus.release = true
                        return this.jobManagementService.releasePauseJob(jobID, jobType, jobToken, this.sessionService.deviceUrl);
                    }
                }
            }
        )
    }

    private purgeJBAdataAndContinue(jobType?: JobType, template?) {
        return this.jobManagementService.purgeJBAData()
            .subscribe({
                next: (response: any) => {
                    if (response == 0) {
                        this.logService.debug("purgeJBAdataAndContinue called inside if")
                        this.purgeCompleted.complete()
                    } else {
                        this.validateJBADataAndContinue(jobType, template)
                    }
                },
                error: error => this.handleError(this.jobType, error, 'Purging JBA Data')
            })
    }

    private sendCompletedJobRequest(jbaData) {
        this.sessionService.getDeviceInfo().subscribe(deviceInfo => {
            let jobID = this.jobType == JobType.SecurePrint ? this.newJobId : this.jobId;
            this.logService.debug("sendCompletedStatus param : DeviceID = " + deviceInfo.serial +", jobID =" +jobID + ", jbaData=" + JSON.stringify(jbaData));
            this.jobManagementService.sendCompletedStatus(deviceInfo.serial, jobID, jbaData)
                .pipe(retryWhen(
                    errors => {
                        return errors.pipe(
                            scan((count, error) => {
                                if (count > oidCodes.retryCount) throw (error);
                                this.logService.warn("retry sendCompletedStatus attempt: " + count);
                                return count + 1;
                            }, 1),
                            tap(() => this.logService.warn("retrying sendCompletedStatus...")))
                    }
                ))
                .subscribe({
                    next: (response: any) => this.setCompletedJobInfoRequestSuccess(),
                    error: error => this.handleError(this.jobType, error, 'CompletedJobInfoRequest Failed')
                })
        });
    }

    private setCompletedJobInfoRequestSuccess() {
        this.completedJobInfo.completed = true
        this.purgeJBAdataAndContinue()
        this.checkoutService.getCartTotal()
    }

    private clearCurrentJobState() {
        this.currentJobState = ""
    }

    printReceipt(jobType: JobType, template: string, url: string, deviceUrl: string) {
        this.printService.print(deviceUrl, url, template)
        this.receipt.next({ status: 'processing' })
    }

    private processDeviceStatus(jobType, status, reasons, jobId) {
        const finishedError = { finished: true, error: true, cancel: false, processing: false, state: 'finishedError' }
        const copyTwoSided  = { finished: false, error: false, cancel: false, processing: true, state: 'calculatingPayment' }
        const waitingForCompletion = { finished: false, error: false, cancel: false, processing: false, state: 'waitingForCompletedReason' }
        const success = { finished: true, error: false, cancel: false, processing: false, state: 'SendingJBAData' }
        const processing = { finished: false, error: false, cancel: false, processing: true, state: 'processing' }

        const calculatingPayment = { finished: false, error: false, cancel: false, processing: true, state: 'calculatingPayment' }
        const waitingForApproval = { finished: false, error: false, cancel: false, processing: true, state: 'waitingForApproval' }
        const jobCompleted = { finished: false, error: false, cancel: false, processing: true, state: 'jobCompleted' }
        const jobCanceled = { finished: true, error: false, cancel: false, processing: false, state: 'jobCanceled' }
        const cancelledByUser = { finished: true, error: false, cancel: false, processing: false, state: 'cancelledByUser' }
        const cancelAfterReleased = { finished: true, error: true, cancel: false, processing: false, state: 'cancelAfterReleased' }
        const paperJamAtPrint = { finished: true, error: true, cancel: false, processing: false, state: 'paperJamAtPrint' }
        const deviceFaultAtPrint = { finished: false, error: false, cancel: false, processing: true, state: 'deviceFaultAtPrint' }
        const deviceFaultAtScan = { finished: false, error: false, cancel: false, processing: true, state: 'deviceFaultAtScan' }
        const deviceFaultCleared = { finished: false, error: false, cancel: false, processing: true, state: 'deviceFaultCleared' }
        const jobCompleteWithErrorBeforeApproval = { finished: false, error: true, cancel: false, processing: false, state: 'jobCompleteWithErrorBeforeApproval' }
        const jobCompleteWithWarnings = { finished: true, error: true, cancel: false, processing: false, state: 'jobCompleteWithWarnings' }
        const secureJobCanceled = { finished: true, error: false, cancel: false, processing: false, state: 'secureJobCanceled' }
        const faxJobCompleted = { finished: true, error: false, cancel: false, processing: false, state: 'faxJobCompleted' }

        if (reasons == 'Printing') {
            this.jobPrintingTime = Date.now()
        }

        if (['JobAborted'].includes(status)) return finishedError

        if (reasons == 'WaitingForSide2') return copyTwoSided

        if (this.completedJobInfo.completed) return jobCompleted

        if(status == 'Processing' && this.overallStatus.jobApproval && this.overallStatus.release) {
            this.overallStatus.causeBySystem = false
            this.clearCurrentJobState()
        }

        if (status == "ProcessingStopped" && reasons == 'CauseBySystem') {
            this.overallStatus.deviceFault = true
            if (this.overallStatus.jobApproval && this.overallStatus.release) {
                this.overallStatus.causeBySystem = true
                return deviceFaultAtPrint
            }
            else
                return deviceFaultAtScan
        }

        if (status == 'PendingHeld' && this.overallStatus.abortBySystem && JobType.SecurePrint && this.isSecureJobCanceled) {
            this.isSecureJobCanceled = false;
            return this.overallStatus.jobApproval ? secureJobCanceled : jobCompleteWithErrorBeforeApproval
        }

        if (status != "PendingHeld" && this.overallStatus.release && this.overallStatus.heldResource) {
            this.overallStatus.heldResource = false
            this.clearCurrentJobState()
            return deviceFaultCleared
        }

        if (status != "ProcessingStopped" && this.overallStatus.deviceFault) {
            this.overallStatus.deviceFault = false
            return deviceFaultCleared
        }

        if (status == 'Completed') {
            if (reasons == 'None') return waitingForCompletion
            if (reasons == 'JobCompletedSuccessfully') return success
            if (this.overallStatus.causeBySystem) return paperJamAtPrint
            if (reasons == 'AbortBySystem') {
                if (jobType == JobType.Fax && this.checkFileTransferCall && !this.isGetJobDetailsFailed) {
                    this.checkFileTransferCall = false;
                    this.jbaconfigService.inititateCheckFileTransfer(this.jobId).subscribe({
                        next: (data) => {
                            this.faxTransfered = data;
                            this.logService.debug("Completed Fax Flag" + data);
                            if (data) {
                                this.overallStatus.abortBySystem = false;
                                return faxJobCompleted;
                            }
                        },
                        error: e => this.logService.error("CheckFileTransfer Exception", JSON.stringify(e))
                    })
                }
                if ((!(jobType == JobType.Fax)) || (jobType == JobType.Fax && !this.faxTransfered) && !this.isGetJobDetailsFailed) {
                    this.overallStatus.abortBySystem = true
                    return this.overallStatus.jobApproval ? jobCanceled : jobCompleteWithErrorBeforeApproval
                }
                if (this.isGetJobDetailsFailed) {
                    !this.overallStatus.jobApproval ? this.cancelJob(jobId, jobType, this.jobToken) : ""
                    this.overallStatus.abortBySystem = true
                    return this.overallStatus.jobApproval ? jobCanceled : jobCompleteWithErrorBeforeApproval
                }
            }
            if (reasons == 'JobCompletedWithWarnings') return jobCompleteWithWarnings
            if (reasons == 'CancelByUser' || 'OutOfAccountingResources') {
                this.overallStatus.cancelbyUser = true
                return this.overallStatus.jobApproval ? (this.overallStatus.release ? cancelAfterReleased : cancelledByUser) : jobCompleted
            }
            return finishedError
        }
        
        if (status == 'PendingHeld' || this.currentJobState == "PendingHeld") {
            if (reasons == 'JobIncoming') {
                return calculatingPayment
            }
            // For the case where the user cancels the job at this screen rather than loading paper
            if (this.pwaSessionService.isFujiDevice && jobType == JobType.SecurePrint && status == 'PendingHeld' && reasons == 'None' 
            && this.overallStatus.release && this.overallStatus.causeBySystem) {
                this.overallStatus.cancelbyUser = true
                return this.overallStatus.jobApproval ? (this.overallStatus.release ? cancelAfterReleased : cancelledByUser) : jobCompleted
            }

            this.currentJobState = "PendingHeld"
            this.overallStatus.jobApproval = true
            if (this.sessionService.getSessionState().logout && !this.isCancelled) {
                this.isCancelled = true;
                this.sessionService.setLogoutErrorMessage('cancelled')
                let processingJobId
                // For Alexandra JobId conversion is not required                
                if (jobId.includes("job:")) {
                    this.cancelJob(jobId, this.getJobTypeString(this.jobType), this.jobToken)
                }
                // For Fuji controller devices JobId conversion is required
                else {
                    processingJobId = parseInt(jobId, 16)
                    this.cancelJob(processingJobId.toString(), this.getJobTypeString(this.jobType), this.jobToken)
                }

            }
            if (this.overallStatus.release && (Math.abs(this.jobReleaseTime - Date.now()) > 3000) && jobType == JobType.SecurePrint) {
                this.deviceAlertService.getDeviceAlertPrintFromPC().subscribe((res)=>{
                    this.parser = new DOMParser();
                    this.xmlResponse = this.parser.parseFromString(res, "text/xml");
                    this.deviceAlertValue = this.xmlResponse.getElementsByTagName('alert-text-brief')[0].childNodes[0].nodeValue;
                    if (this.deviceAlertValue) {
                        this.overallStatus.heldResource = true
                        return deviceFaultAtPrint
                    }
                })
			}
            if (this.overallStatus.release && (Math.abs(this.jobReleaseTime - Date.now()) > 3000) && jobType != JobType.SecurePrint) {
              if (!this.jobPrintingTime || (Math.abs(this.jobPrintingTime - Date.now()) > 3000)) {
                this.overallStatus.heldResource = true
                return deviceFaultAtPrint
              }
            }
            return waitingForApproval
        }

        if (this.currentJobState == "calculatingPayment" && status != 'PendingHeld') {
            if (this.sessionService.getSessionState().logout && !this.isCancelled) {
                this.isCancelled = true
                this.sessionService.setLogoutErrorMessage('cancelled')
                this.xrxCancelJob(jobId)
            }
            return calculatingPayment
        }

        return processing
    }
    private handleError(jobType, error, deviceStatus?, template?) {
        let errorType = 'unexpected'
        let errorDetail = {
            jobType: jobType,
            deviceStatus: deviceStatus,
            error: error,
            template: template
        }
        this.logService.error('Error while doing Job occured at JobService ', JSON.stringify(errorDetail))
        this.error.next({ errorType, error, deviceStatus, jobType })
    }

    private handleCancelErrors(jobType, error) {
        this.logService.error('Error while cancelling job occured at JobService ', JSON.stringify(error))
        let errorType = 'cancelError'
        this.error.next({ errorType, error })
    }
    private handleSmtpErrors(jobType, error) {
        this.setNextCurrentJobStatus(this.jobType, 'Completed', error, true, 'jobCompleted')
        let errorType = 'smtpError'
        this.error.next({ errorType, error })
    }
    private setNextCurrentJobStatus(jobType, status, reasons, finished, state) {
        let cancelled = false
        let isReleased = this.overallStatus.release
        // cancel at scan phase from MFD -> Job Cancelled
        // cancel from MFD after job approval
        // AbortBySystem -> cancel from job approval screen and MFD cancels before job Approval
        // cancel from MFD at job approval screen
        if ((!this.overallStatus.jobApproval && this.overallStatus.cancelbyUser) || (this.overallStatus.jobApproval && this.overallStatus.cancelbyUser) || this.overallStatus.abortBySystem ||
            (this.overallStatus.jobApproval && this.overallStatus.cancelbyUser && !this.overallStatus.release))
            cancelled = true
        this.currentJob.next({
            jobType,
            status,
            reasons,
            finished,
            state,
            cancelled,
            isReleased
        })
    }
    private getJobTypeString(jobType: JobType) {
        switch (jobType) {
            case JobType.Fax:
                return 'WorkflowScanning'
            case JobType.Scan:
                return 'Email'
            case JobType.Print:
                return 'Print'
            case JobType.Copy:
                return 'Copy'
            case JobType.SecurePrint:
                return 'SecurePrint'
        }
    }

    private passCurrentJobInformation(jobType, status, nextAction) {
        if (nextAction.state == "calculatingPayment") {
            this.setNextCurrentJobStatus(jobType, 'computingCost', status.reasons, nextAction.finished, nextAction.state)
        }
        else if (nextAction.state == "waitingForApproval") {
            this.setNextCurrentJobStatus(jobType, 'PendingHeld', status.reasons, nextAction.finished, nextAction.state)
        }
        else if (nextAction.state == "deviceFaultAtScan" || nextAction.state == "deviceFaultAtPrint") {
            this.setNextCurrentJobStatus(jobType, 'deviceFault', status.reasons, nextAction.finished, nextAction.state)
        }
        else if (nextAction.state == "deviceFaultCleared") {
            this.setNextCurrentJobStatus(jobType, 'deviceFaultCleared', status.reasons, nextAction.finished, nextAction.state)
        }
        else {
            this.setNextCurrentJobStatus(jobType, status.status, status.reasons, nextAction.finished, nextAction.state)
        }
    }

    private initializeJobInfo(jobType) {
        this.jobType = jobType
        this.overallStatus = { toBeReleased: false, release: false, error: false, abortBySystem: false, jobApproval: false, cancelbyUser: false, causeBySystem: false, deviceFault: false, heldResource: false }
        this.release = false;
        this.isCancelled = false;
        this.completedJobInfo = { started: false, completed: false }
        this.currentJobState = "calculatingPayment"
        this.errorPopup = false
        this.faxTransfered = false
        this.checkFileTransferCall = true
    }

    private handleErrorscenarios(jobType, nextAction, errorReasons) {
        switch (nextAction.state) {
            case 'cancelAfterReleased':
                nextAction.status = 'Completed'
                let description;
                if (this.getJobTypeString(jobType) == "Email" && errorReasons == "NoToAddress") {
                    description = "INVALID_EMAIL_ADDRESS"
                } else if (this.getJobTypeString(jobType) == "Email" && errorReasons == "NoRoomOnSMTPServer") {
                    description = "SCANTOMAIL_FILE_TOO_LARGE"
                } else if (this.getJobTypeString(jobType) == "Email" && (errorReasons == "CannotConnectToSMTPServer" || errorReasons == "SMTPHostNameNotAvailable" || errorReasons == "CannotTransferToSMTPServer")) {
                    description = "CannotConnectToSMTPServer"
                } else {
                    description = this.getJobTypeString(jobType) == "Email" ? "CANCEL_AFTER_RELEASED_EMAIL" : "CANCEL_AFTER_RELEASED"
                }
                this.handleErrorMessages(jobType, nextAction, errorReasons, description)
                break;
            case "paperJamAtPrint":
                this.handleErrorMessages(jobType, nextAction, errorReasons, "ERROR_AFTER_APPROVAL")
                break;
            case "jobCompleteWithErrorBeforeApproval":
                nextAction.status = 'Completed'
                this.handleErrorMessages(jobType, nextAction, errorReasons, "JOB_CANNOT_PROCESSED")
                break;
            case "jobCompleteWithWarnings":
                nextAction.status = 'Completed'
                this.handleErrorMessages(jobType, nextAction, errorReasons, "ERROR_AFTER_APPROVAL")
                break;
            default:
                this.handleError(jobType, errorReasons, nextAction.status)
                this.setNextCurrentJobStatus(jobType, 'deviceFault', errorReasons, nextAction.finished, nextAction.state)
        }
    }

    private handleErrorMessages(jobType, nextAction, errorReasons, description) {
        if (!this.errorPopup) {
            if (nextAction.state == 'jobCompleteWithErrorBeforeApproval') {
                this.handleError(jobType, errorReasons, 'Error processing job')
            } else if (errorReasons == "CannotConnectToSMTPServer" || errorReasons == "SMTPHostNameNotAvailable" || errorReasons == "CannotTransferToSMTPServer") {
                this.handleSmtpErrors(jobType, description)
            }
            else {
                this.handleCancelErrors(jobType, description)
            }
            this.errorPopup = true
        }
        this.setNextCurrentJobStatus(jobType, nextAction.status, errorReasons, nextAction.finished, nextAction.state)
    }

    public getListActiveQueue() {
        this.retVal = this.jobManagementService.getListActiveQueueJobDetails(this.sessionService.deviceUrl).toPromise().then((response) => {
            this.logService.debug("getListActiveQueueJobDetails" + JSON.stringify(response))
            this.deleteCopyJob(response)
        })
        return this.retVal
    }

    deleteCopyJob(data) {
        var res = JSON.stringify(data)
        var Obj = JSON.parse(res);
        if (Obj != null) {
            let processingJobId
            if (Obj.JobType == "Copy" && Obj.reasons == "NextOriginalWait" && (Obj.status == "Processing" || Obj.status == "ProcessingStopped")) {
                processingJobId = parseInt(Obj.JobId, 16)
                this.copyService.cancelCopyJob(Obj.JobId).subscribe({
                    next: s => {
                        this.logService.debug("sucessfully cancelled copy job")
                    },
                    error: error => this.handleError(this.jobType, error, 'Cancelling job')
                })
            } else if (Obj.JobType == "Copy" && Obj.reasons == "WaitingForSide2" && Obj.status == "Processing") {
                this.copyService.cancelCopyJob(Obj.JobId).subscribe({
                    next: s => {
                        this.logService.debug("sucessfully cancelled copy job")
                    },
                    error: error => this.handleError(this.jobType, error, 'Cancelling job')
                })
            }

        }
    }
}
