
/* --------------------------------------------------------------------------------------
   log.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { timeout } from 'rxjs/operators';

export enum LogLevel {
  ALL = 0,
  DEBUG = 1,
  INFO = 2,
  WARN = 3,
  ERROR = 4,
  FATAL = 5,
  OFF = 6
}

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private serialNumber
  private logMsg = []
  private landingComponentLogs = []
  parsedPostData: string;
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) {
  }

  setSerialNumber(serialNumber: string) {
    this.serialNumber = serialNumber
  }

  debug(message: string) {
    this.writeToLog(LogLevel.DEBUG, 'DEBUG', message, null);
  }

  info(message: string) {
    this.writeToLog(LogLevel.INFO, 'INFO', message, null);
  }

  warn(message: string, exceptionMessage?: string) {
    this.writeToLog(LogLevel.WARN, 'WARN', message, null);
  }

  error(message: string, exceptionMessage: string) {
    this.writeToLog(LogLevel.ERROR, 'ERROR', message, exceptionMessage);
  }

  fatal(message: string, exceptionMessage: string) {
    this.writeToLog(LogLevel.FATAL, 'FATAL', message, exceptionMessage);
  }

  private shouldLog(level: LogLevel): boolean {

    let ret: boolean = false;
    const logLevelNumber = LogLevel[this.environmentService.logLevel]

    if (level >= logLevelNumber) {
      ret = true;
    }
    return ret;
  }

  private writeToLog(logLevel: LogLevel, logLevelName: string, message: string, exceptionMessage?: string) {
    if (this.shouldLog(logLevel)) {
      var currentTime = new Date().toISOString().replace("T", " ").replace("Z", "")
      const sessionId = sessionStorage.getItem("sessionID")
      const postdata = {
        LogLevel: logLevelName,
        Message: message + " Time stamp: " + currentTime,
        ExceptionMessage: exceptionMessage,
        DeviceSerialNumber: sessionStorage.getItem("serialNumber")
      }
      const endpoint = this.environmentService.kioskServerUrl + '/api/Log/Log'
      this.http.post(endpoint, postdata, { headers: { 'sessionID': sessionId } }).pipe(timeout(3000)).subscribe({
        error: e => {
        let newPostData = JSON.stringify(postdata)
        // JSON.parse() returns an object
        this.parsedPostData = JSON.parse(newPostData);
        // The below is called when the logService API is failed
        this.writeToLocalStorage(this.parsedPostData ,"WriteToLog is failed")  
        }   
      })
    }
  }

  /**
  * This function stores the timeout error log in local storage.
  * @param {string}	message					HTTP call name which is failed/error message
  * @param {any} exceptionMessage   Error message
  */
  writeToLocalStorage(message: string, exceptionMessage: any, isLandingComponent?: boolean): void {
    var currentTime = new Date().toISOString().replace("T", " ").replace("Z", "")
    if (isLandingComponent) {
      exceptionMessage = JSON.stringify(exceptionMessage)
      var data = {
        Message: message,
        Response: exceptionMessage,
        DeviceTime: currentTime
      }
      this.landingComponentLogs.push(data)
      var msg = JSON.stringify(this.landingComponentLogs)
      localStorage.setItem('landingComponentLogs', msg)
    }
    else {
      exceptionMessage = JSON.stringify(exceptionMessage)
      var savedata = {
        DeviceTime: currentTime,
        Message: message,
        ExceptionMessage: exceptionMessage
      }
      this.logMsg.push(savedata)
      var formattedMsg = JSON.stringify(this.logMsg)
      if (exceptionMessage.toUpperCase().includes("TIMEOUT")) localStorage.setItem('timeoutlog', formattedMsg)
      else localStorage.setItem('errorlog', formattedMsg)
    }
  }
 
}
