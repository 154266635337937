<!--
   check-kiosk-settings.component.html
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
-->
<div *ngIf="getCredentials">
  <app-full-screen-modal #settingsMismatchModal [showTitle]="true" [showOk]="true" title={{title}}>

    <div class="content">
      <div class="bg_color ml-1">
        <form [formGroup]="settingsForm" class="col-12 form-group">

          <div class="message component">
            <span>{{ description | translate }}</span>
          </div>

          <!-- Admin User Name String Field -->
          <div *ngIf="getAdminUserName">
            <label class="subtitle-2">
              <p>{{ 'SETTINGS.DEVICE_ADMIN_USERNAME' | translate }}</p>
            </label>
            <div class="marginBottom30">
              <input type="text" #device_admin_name formControlName="deviceAdminName"
                (keyup.Enter)="onEnterKeyClicked($event)" class="form-control form input_style" />
              <div
                *ngIf="settingsForm.controls['deviceAdminName'].invalid && (settingsForm.controls['deviceAdminName'].dirty || settingsForm.controls['deviceAdminName'].touched)"
                class="alert">
                <div class="input-error" *ngIf="settingsForm.controls['deviceAdminName'].errors.required">
                  <p>{{'SETTINGS.MANDATORY'| translate}}</p>
                </div>
              </div>
              <div *ngIf="isAuthenticationError" class="text-danger error-label">
                {{ 'SETTINGS.USERNAME_PASSWORD_INVALID' | translate }}
              </div>
            </div>

          </div>

          <!-- Device Admin password Field -->
          <div *ngIf="getPassword">
            <label class="subtitle-2">
              <p>{{ 'SETTINGS.DEVICE_ADMIN_PASSWORD' | translate }}</p>
            </label>
            <div class="marginBottom30">
              <input type="text" #device_admin_password formControlName="deviceAdminPassword"
                (keyup.Enter)="onEnterKeyClicked($event)" class="form-control form input_style" />
              <div
                *ngIf="settingsForm.controls['deviceAdminPassword'].invalid && (settingsForm.controls['deviceAdminPassword'].dirty || settingsForm.controls['deviceAdminPassword'].touched)"
                class="alert">
                <div class="input-error" *ngIf="settingsForm.controls['deviceAdminPassword'].errors.required">
                  <p>{{'SETTINGS.MANDATORY'| translate}}</p>
                </div>
              </div>
              <div *ngIf="isAuthenticationError" class="text-danger error-label">
                {{ 'SETTINGS.USERNAME_PASSWORD_INVALID' | translate }}
              </div>
            </div>

          </div>


        </form>
      </div>
    </div>
  </app-full-screen-modal>
</div>