<!--
   thankyou-modal.component.html
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
-->

<ng-template #content let-modal>
  <div class="modal-container">
    <div class="modal-header" *ngIf="showTitle">
      <h4 class="modal-title" id="modal-basic-title" >{{ title | translate }}</h4>
    </div>
    <div class="modal-body-container">
      <div class="modal-body text-center">
        <div class="modal-body-icon" *ngIf="receiptImage">
          <img class="thankyou" src="assets\images\thankyou_receipt.png">
        </div>
        <div class="modal-body-icon" *ngIf="loadingIcon">
          <img class="thankyou" src="/assets/images/loading.gif" alt="image" />
        </div>
        <div class="modal-body-description" *ngIf="showDescription">
          <div [ngClass]="descriptionSize == false ? 'regular-font-size' : 'reduce-font-size'">
            {{description | translate}}
          </div>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
    <div class="modal-footer" *ngIf="closeText">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close()">{{ closeText }}</button>
    </div>
  </div>
</ng-template>