/* --------------------------------------------------------------------------------------
   accountconfig.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { Observable, Subject, from } from 'rxjs';
import { EnvironmentService } from './environment.service';
declare function xrxGetAccountingMethodConfig(url: any, admin: any, adminPassword: any, callback_success: any, callback_failure: any, timeout: number)
declare function xrxParseAccountingMethodConfig(response: any)

@Injectable({
  providedIn: 'root'
})
export class AccountconfigService {
  t1: any
  constructor(private environmentService: EnvironmentService) { }

  getAccountConfiguration(): Observable<any> {
    this.t1 = Date.now()
    const result = new Promise((resolve, reject) => {
      xrxGetAccountingMethodConfig(
        "http://localhost",
        null,
        null,
        (env, response) => resolve(xrxParseAccountingMethodConfig(response)),
        (env, error) => reject(error),
        20
      )
    })
    return from(result);
  }
}
