
/* --------------------------------------------------------------------------------------
   app.component.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ILoaderOption } from './model';
import { DeviceConfigService, SessionService, LogService } from './services';
import { AppState, selectLoaderOption } from './store';
import { Router } from '@angular/router';
import { EnvironmentService } from './services/environment.service';
import { EnergysaverConfigService } from './services/energysaver-config.service';
import { supportedCountries, supportedCountryCodes } from 'src/app/model/supported-country';
import { oidCodes } from './shared/eip';
import { retryWhen, scan, tap } from 'rxjs/operators';

declare function xrxGetValue(node: any)
declare function xrxFindElement(root: any, elements: any)
declare function xrxFindElements(jobDetails: any, elements: string[])
// import { TranslateService } from '@ngx-translate/core';
// import defaultLanguage from "assets/i18n/en.json";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Workplace Kiosk';
  loaderOption$: Observable<ILoaderOption>;
  logged_in_user: any;
  logged_in_user_role: any;
  loadPercent: number = 0
  displayProgressIndicator: boolean = true
  getAdminStatusEndTime: number
  /*constructor(private translate: TranslateService) {
    //translate.setTranslation('en', defaultLanguage);
    translate.setDefaultLang('en');
  }*/
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private translate: TranslateService,
    private deviceConfigService: DeviceConfigService,
    private environmentService: EnvironmentService,
    private router: Router,
    public sessionService: SessionService,
    private logService: LogService
  ) {
    this.loaderOption$ = store.select(selectLoaderOption);
  }

  ngOnInit() {
    this.translate.addLangs(supportedCountries); // addLangs() is a library function which updates the list of available langs
    //Language configuration
    this.environmentService.getServerUrl().pipe(retryWhen(
      errors => {
          return errors.pipe(
              scan((count, error) => {
                  if (count > oidCodes.retryCount) throw (error);
                  this.logService.warn("retry getServerUrl attempt: " + count);
                  return count + 1;
              }, 1),
              tap(() => this.logService.warn("retrying getServerUrl...")))
      }
  ))
      .subscribe(s => {
        this.displayProgressIndicator = (this.environmentService.progressIndicator == "true") ? true : false
        if(this.displayProgressIndicator) this.sessionService.progressIndicator.subscribe((n) => this.loadPercent = n)
        this.sessionService.progressIndicator.next(1)
        this.getKioskCountry()
      })
  }

  private checkAdminLogin() {
    this.sessionService.progressIndicator.next(15)
    this.deviceConfigService.getAdminStatus()
      .subscribe(
        s => {
          this.getAdminStatusEndTime = Date.now();
          this.logService.writeToLocalStorage("Time elapsed for getAdminStatus success: " , Math.abs(this.deviceConfigService.getAdminStatusStartTime - this.getAdminStatusEndTime) + " Ms")
          this.getAdminSuccess(s)
        },
        error => {
          this.getAdminStatusEndTime = Date.now();
          this.logService.writeToLocalStorage("Time elapsed for getAdminStatus failed: " , Math.abs(this.deviceConfigService.getAdminStatusStartTime - this.getAdminStatusEndTime) + " Ms")
          this.router.navigate(['checkKioskSettings'], { skipLocationChange: true });
        }
      )
  }

  private getKioskCountry() {
    const endpoint = this.environmentService.kioskServerUrl + '/api/Kiosk/GetKioskCountry'
    const params = new HttpParams().append('deviceID', this.sessionService.deviceSerial);
    const headers = new HttpHeaders().append('PBSecretKey', this.environmentService.pbSecretKey)
    return this.http.get(endpoint, { headers, params }).subscribe({
      next: n => {
        this.sessionService.progressIndicator.next(5)
        this.getLanguage(n)},
      error: e => {
        this.sessionService.progressIndicator.next(6)
        this.logService.writeToLocalStorage("getKioskCountry failed", JSON.stringify(e))
        this.getBrowserLanguage()
        this.checkAdminLogin()
      }
    })
  }

  private getLanguage(response: any) {
    this.sessionService.progressIndicator.next(10)
    this.checkAdminLogin()
    if (response == supportedCountryCodes.Slovak.toUpperCase()) {
      const language = supportedCountryCodes.Slovak
      const defaultLanguage = supportedCountries.includes(language) ? language : supportedCountryCodes.default
      this.sessionService.currentLanguage = defaultLanguage;
      this.translate.use(defaultLanguage)
    }
    else {
      this.getBrowserLanguage()
    }
  }

  private getBrowserLanguage() {
    const language = window.navigator.language.split('-')[0].toLowerCase();
    const defaultLanguage = supportedCountries.includes(language) ? language : supportedCountryCodes.default
    this.sessionService.currentLanguage = defaultLanguage;
    this.translate.use(defaultLanguage)
  }

  getAdminSuccess(result) {
    //Getting the userName here
    const xrxUserName = result.getElementsByTagName("username");
    if (xrxUserName == null || xrxUserName.length < 1) {
      this.logged_in_user = "public";

    } else {
      if (xrxUserName[0].childNodes.length == 0) {
        this.logged_in_user = "public";
      }
      else {
        this.logged_in_user = xrxUserName[0].childNodes[0].nodeValue;
      }
      this.sessionService.adminName = this.logged_in_user;
    }

    //Getting the UserRole here
    try {
      var session_data = xrxFindElement(result, ["authorization", "roles", "authorized"]);
      var roles = xrxFindElements(session_data, ["role"]);
      for (let index = 0; index < roles.length; index++) {
        var userRole: string[] = xrxGetValue(roles[index])
      }
    }
    catch {
      this.router.navigate(['checkKioskSettings'], { skipLocationChange: true })
    }
    if (userRole.includes("xeSystemAdministrator")) {
      this.router.navigate(['kiosksettings'], { skipLocationChange: true })
    } else {
      this.router.navigate(['checkKioskSettings'], { skipLocationChange: true })
    }

  }
}
