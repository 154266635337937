import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { switchMap, tap, timeout } from 'rxjs/operators';
import { Observable, from, Subject } from 'rxjs';
import { LogService, SessionService, PwaSessionService } from 'src/app/services';
import { EnvironmentService } from './environment.service';
import { SnmpService } from '../shared/eip/snmp.service';
import { DeviceInfoService, DeviceCapabilitiesService } from '../shared/eip';
import { oidCodes } from '../shared/eip/constants/oid-codes.const';
import { EnergySaver, SysTimerDiscovery } from 'src/app/shared/eip/eip.const';

declare function xrxWsSnmpSet(url: any, communityString: string, oidArr: string[], callback_success: any, callbck_failure: any, timeout: number, async: boolean): any;
declare function xrxCallAjax(sendUrl: string, envelope: string, method: any, headers: any, callback_success: any, callback_failure: any, timeout: any, username: any, password: any, async: boolean)

export enum nameOfDay {
	Sunday,
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday
}

@Injectable({
	providedIn: 'root'
})
export class EnergysaverConfigService {

	currSysTimer: number;
	newSysTimer: number;
	retVal;
	currDiscoverySysTimerInSecs: any;
	newDiscoverySysTimerInSecs: number;
	currPSTimer: number;
	newPSTimer: number;
	dayStartHour: number;
	dayEndHour: number;
	nextDayStartHour: number;
	isScheduled: boolean = false;
	isLowPowerEndSet: boolean = false;
	isLastHourPowerSaver: boolean = false;
	resetCheckSatus: ReturnType<typeof setTimeout> = null
	poverSaverSetTimeout: ReturnType<typeof setTimeout> = null
	setSystemTimerFailed: boolean = false;
	isFirstUpdateLowPowerMode: boolean = false;
	LastAppSetPSValue: number;
	setSysTimerFailed: Subject<any> = new Subject<any>()
	setSysTimerSuccess: Subject<any> = new Subject<any>()
	checkAccountingConfig: Subject<any> = new Subject<any>()
	InitiateTime: any;
	private deviceSerial
	deviceObjects: any = {}

	constructor(
		private http: HttpClient,
		private sessionService: SessionService,
		private logService: LogService,
		private environmentService: EnvironmentService,
		private pwaSessionService: PwaSessionService,
		private snmpService: SnmpService,
		private deviceInfoService: DeviceInfoService,
		private deviceCapabilitiesService: DeviceCapabilitiesService
	) { }

	getEnergySaverConfig(sessionID) {
		this.sessionService.getDeviceInfo().subscribe(
			deviceInfo => this.deviceSerial = deviceInfo.serial
		)
		this.InitiateTime = Date.now()
		const endpoint = this.environmentService.kioskServerUrl + '/api/Kiosk/GetEnergySaverConfig'
		const params = new HttpParams().append('deviceID', this.deviceSerial);
		const headers = new HttpHeaders().append('sessionID', sessionID)
		return this.http.get(endpoint, { headers, params }).pipe(timeout(10 * 1000))
	}

	checkAlerts(): Observable<any> {
		const url = "http://localhost/get-printer-attributes?attributes-charset=utf-8&attributes-natural-language=%s&document-format=application&requested-attributes=alert-status-col";
		const result = new Promise((resolve, reject) => {
			xrxCallAjax(
				url,
				" ",
				"GET",
				null,
				(env, response) => resolve(this.getAlertsSuccess(env, response)),
				(env, error) => reject(this.getAlertsFailure(env, "", error)),
				null, null, null, false
			)
		})
		return from(result);
	}

	getAlertsSuccess(sendText, respText) {
		if (respText != null && respText != "") {
			if ((respText.match("016-424") != null) || (respText.match("016-425") != null)) {
				this.exitPowerSave();
			}
		}
	}

	getAlertsFailure(request, sendText, respText) {
		setTimeout(() => this.checkAlerts(), 1000);
	}

	// wakeup the device and get user defined low power mode value 
	exitPowerSave() {
		this.getPowerSaverValueFromCWIS()
		.pipe(switchMap(s => this.setPowerSaveTimer(3)))
		.subscribe({
			next: () => {
				var currentTime = new Date().toISOString().replace("T", " ").replace("Z", "")
				this.logService.debug("Device exit from power save at " +  currentTime);
				this.checkPowerSaveStatus();
			}
		  })
	}

	startSystemTimer() {
		//SystemTimer 
		if (this.sessionService.isFujiBasedProduct) {
			this.currSysTimer = this.environmentService.currSystemTimer;
			if (this.currSysTimer == this.environmentService.currSystemTimer) {
				this.newSysTimer = this.environmentService.newSystemTimer;
			}
			else {
				this.newSysTimer = this.environmentService.currSystemTimer;
			}
			this.setSystemTimer(this.newSysTimer);
		}
		else {
			if(this.currDiscoverySysTimerInSecs == SysTimerDiscovery.MaxThreshold) { return }
			else {
				//If the current systemtimer value >= 58 mins and <= 60 mins, then set systemtimer to 60 mins
				if((this.currDiscoverySysTimerInSecs  >= SysTimerDiscovery.MinThreshold) && (this.currDiscoverySysTimerInSecs <= SysTimerDiscovery.MaxThreshold)) {
					this.setSystemTimer(SysTimerDiscovery.MaxThreshold)
				}	
				else {
					// It will add the user-defined system timer value (CWIS) + 2 minutes.
					this.currDiscoverySysTimerInSecs = parseInt(localStorage.getItem('SystemTimer')) + parseInt(this.environmentService.currDiscoverySysTimerInSecs);
					this.setSystemTimer(this.currDiscoverySysTimerInSecs)
				}
			}		
		}
	}

	//If the device starts in the middle of the scheduled time, that time setPowerSave updated as expected, so that time was the first time setPowerSave initiated.
	startUserSession(min: number) {
		//PowerSaverTimer 
		this.currPSTimer = min;
		if (this.currPSTimer == this.environmentService.currPSTimer) {
			this.newPSTimer = this.environmentService.newPSTimer;
		}
		else {
			this.newPSTimer = this.environmentService.currPSTimer;
		}
		this.setPowerSaveTimer(this.newPSTimer);
	}

	setSystemTimer(secs, oneTimeCall?: boolean) {
		const oidArr = ["1.3.6.1.4.1.253.8.53.5.2.1.2.1", "i", "2325",
			"1.3.6.1.4.1.253.8.53.5.2.1.3.1", "s", "OI=1.3.6.1.4.1.253.8.53.13.2.1.6.1.180.10:OV=" + secs + ":",
			"1.3.6.1.4.1.253.8.53.5.2.1.7.1", "s", this.sessionService.password];
		if (this.sessionService.isFujiBasedProduct) {
			this.currSysTimer = secs;
		}
		else {
			this.currDiscoverySysTimerInSecs = secs;
		}
		this.retVal = new Promise((resolve, reject) => {
			xrxWsSnmpSet(
				'http://127.0.0.1',
				this.sessionService.snmpWriteString,
				oidArr,
				(envelope, response) => resolve(this.snmpsetST_callback_success(envelope, response, oneTimeCall)),
				(request, response, status) => reject(this.snmpset_callback_failure(secs, request, response, status, oneTimeCall)),
				null,
				false
			)
		})
	}

	snmpsetST_callback_success(envelope, response, oneTimeCall?: boolean) {
		if(oneTimeCall) this.setSysTimerSuccess.next()
		const sysTimerFrequency = (5 * 60 * 1000)
		// For the discovery device, we subtract 10 seconds from the set timeout delay value.
		const sysTimerFreqForDiscovery = (2 * 60 * 1000 - 10000)
        if(this.sessionService.isFujiBasedProduct) {
			//compare the cwis systimer value with localstorage systimer value for fuji and set localstorage value to cwis
			if ((!this.sessionService.isSessionStarted) && (!(parseInt(localStorage.getItem('SystemTimer')) == this.currSysTimer))) {
				this.setSystemTimer(localStorage.getItem('SystemTimer'));
				this.logService.debug("SystemTimer in localStorage " + localStorage.getItem('SystemTimer'))
			}
			if ((!this.sessionService.isSessionEnded) && (this.sessionService.isSessionStarted)) {
				if (this.currSysTimer == this.environmentService.currSystemTimer) {
					this.newSysTimer = this.environmentService.newSystemTimer;
				}
				else {
					this.newSysTimer = this.environmentService.currSystemTimer;
				}
				this.logService.debug("SystemTimer will be set to " + this.newSysTimer + "in" + sysTimerFrequency + "msec")
				setTimeout(() => {
					this.setSystemTimer(this.newSysTimer)
				}, sysTimerFrequency);
			}
		}
		else {
			//compare the cwis systimer value with localstorage systimer value for discovery and set localstorage value to cwis
			if ((!this.sessionService.isSessionStarted) && (!(parseInt(localStorage.getItem('SystemTimer')) == this.currDiscoverySysTimerInSecs))) {
				this.setSystemTimer(localStorage.getItem('SystemTimer'));
				this.logService.debug("SystemTimer in localStorage " + localStorage.getItem('SystemTimer'))
			}			
			//If the current systemtimer value <= 58 mins, then only set systemtimer to this.currDiscoverySysTimerInSecs + 2 minutes 
			if(this.currDiscoverySysTimerInSecs  <= SysTimerDiscovery.MinThreshold) {
				if (!this.sessionService.isSessionEnded && (this.sessionService.isSessionStarted)) {
					var newDiscoveryTimer = parseInt(this.currDiscoverySysTimerInSecs) + parseInt(this.environmentService.currDiscoverySysTimerInSecs)
					this.logService.debug("SystemTimer will be set to " + newDiscoveryTimer + "in" + sysTimerFreqForDiscovery + "msec")
					setTimeout(() => {
						this.newDiscoverySysTimerInSecs =  parseInt(this.currDiscoverySysTimerInSecs) + parseInt(this.environmentService.currDiscoverySysTimerInSecs);
						this.setSystemTimer(this.newDiscoverySysTimerInSecs);
					}, sysTimerFreqForDiscovery)
				}
			}		
		}
	}

	snmpset_callback_failure(secs, request, response, status, oneTimeCall?: boolean) {
		if(oneTimeCall) this.setSysTimerFailed.next(response)
		this.logService.error("SNMP set failed for SystemTimer: ", status);
		this.logService.error("SNMP set failed for SystemTimer while trying to set the value to: " + secs + " error = ", response);
	}

	// manipulte the device low power mode value (15 or 30)
	setPowerSaveTimer(min: number) {
		let oidArr = ["1.3.6.1.4.1.253.8.53.5.2.1.2.1", "i", "2325",
			"1.3.6.1.4.1.253.8.53.5.2.1.3.1", "s", "OI=1.3.6.1.4.1.253.8.53.13.2.1.6.1.114.5506:OV=" + min + ":",
			"1.3.6.1.4.1.253.8.53.5.2.1.7.1", "s", this.sessionService.password];
		this.currPSTimer = min;
		this.retVal = new Promise((resolve, reject) => {
			xrxWsSnmpSet(
				'http://127.0.0.1',
				this.sessionService.snmpWriteString,
				oidArr,
				(envelope, response) => {
					resolve(this.snmpsetPS_callback_success(envelope, response))
				},
				(request, response, status) => {
					this.logService.debug("snmpsetPS_callback_failure" + status)
					reject(this.snmpsetPS_callback_failure(min, request, response, status))
				},
				null,
				false
			)
		})
		return this.retVal
	}

	snmpsetPS_callback_success(envelope, response) {
		this.logService.debug("Power save Timer successfully set to  " + this.currPSTimer)
		//if there are any outstanding timers, cancel it and set a new one
		if (this.poverSaverSetTimeout) {
			clearTimeout(this.poverSaverSetTimeout);
			this.poverSaverSetTimeout = null;
		}
		this.isFirstUpdateLowPowerMode = true;
		this.checkPowerSaveStatus();
		if (this.getCurrentPowerSaveStatus() && !this.isLastHourPowerSaver) {
			this.LastAppSetPSValue = this.currPSTimer;
			if (this.currPSTimer == this.environmentService.currPSTimer) {
				this.newPSTimer = this.environmentService.newPSTimer;
			}
			else {
				this.newPSTimer = this.environmentService.currPSTimer;
			}
			// currPSTimer == 3, then set the timer to 2.5 minutes and update the low power value to 15 min 
			if (this.currPSTimer == 3) {
				this.poverSaverSetTimeout = setTimeout(() => this.setPowerSaveTimer(this.newPSTimer), (this.currPSTimer * 60 - 30) * 1000);
			} else {
				// currPSTimer == 15 or 30, then set the timer to currPSTimer-2 minutes and update the low power value
				this.poverSaverSetTimeout = setTimeout(() => this.setPowerSaveTimer(this.newPSTimer), (this.currPSTimer * 60 - 120) * 1000);
			}
		}
	}

	snmpsetPS_callback_failure(min, request, response, status) {
		this.logService.error("SNMP set failed for PowerSaver while trying to set the value to: " + min + " error = ", response);
	}

	// manage device wakeup and sleep
	checkPowerSaveStatus(onWakeup?: boolean) {
		//if there are any outstanding timers, cancel it and set a new one
		if (this.resetCheckSatus) {
			clearTimeout(this.resetCheckSatus);
			this.resetCheckSatus = null;
		}
		//Triggers the onWakeup() function on device wakeup when device is in sheduled mode.
		if (onWakeup) {
			this.onWakeup()
		}
		this.pwaSessionService.sessionId.
			pipe(switchMap(s => this.getEnergySaverConfig(s))).subscribe({
				next: data => {
					var t2 = Date.now()
					let res = JSON.parse(JSON.stringify(data))
					let currentDay = this.getCurrentDay();
					let nextDay = this.getNextDay();

					let date = new Date();
					let hour = date.getHours();
					this.logService.debug("checkPowerSaveStatus called time :" + hour + ":" + date.getMinutes() + ":" + date.getSeconds()+", Elapsed time = " + Math.abs(t2 - this.InitiateTime));

					let day = res.filter(e => {
						return e.Day == currentDay;
					})

					let nextday = res.filter(e => {
						return e.Day == nextDay;
					})

					// Set next day wakeup hour
					if (nextday != null && nextday != "") {
						this.nextDayStartHour = nextday[0]['time'].WakeupTime;
					}

					// check mode (Scheduled / device activity)
					if (day != null && day != "" && hour >= day[0]['time'].WakeupTime && hour < day[0]['time'].SleepTime && day[0]['Mode'] == EnergySaver.Scheduled) {
						this.logService.debug("Enter into Schedule time");

						this.dayStartHour = day[0]['time'].WakeupTime;
						this.dayEndHour = day[0]['time'].SleepTime;
						
						this.checkAlerts();
						this.isScheduled = true;
						//If the device starts in the middle of the scheduled time, that time setPowerSave updated as expected, so that time was the first time setPowerSave initiated.
						if(!this.isFirstUpdateLowPowerMode){
							this.snmpService.snmpGet(this.sessionService.deviceUrl, oidCodes.powerSaverValueFromCWIS).toPromise().then((response) => {
								setTimeout(() => this.startUserSession(parseInt(response.returnValue)), 1000); 
							})
						}

						// set timer to force the device into low power mode
						if (hour < this.dayEndHour && !this.isLowPowerEndSet) {
							let sleepTime = this.dayEndHour + ":" + 0 + ":" + 0 + ":" + 0;
							let currentTime = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + ":" + 0;
							const setTimer = this.calculateTimeDiff(sleepTime, currentTime)
							this.logService.debug("device will enter into low power mode in " + this.msToTime(setTimer))
							this.resetCheckSatus = setTimeout(() => this.initiateDirectLowPowerMode(), setTimer - 1000);
						}
					} else if (hour < day[0]['time'].WakeupTime && day[0]['Mode'] == EnergySaver.Scheduled) {
						// set timer to wakeup the device for current day
						this.deviceWakeUpSet(day[0]['time'].WakeupTime, true);
					} else {
						this.isFirstAppLoadOnDeviceActivity()
						this.logService.debug("Enter into Device activity");
						this.isScheduled = false;
						// set timer to wakeup the device for next day 
						this.deviceWakeUpSet(this.nextDayStartHour, false);
						// checkPowerSaveStatus is checked every hour when the device is in Device Activity mode.
						let delay = date.getMinutes() < 60 ? (60 - date.getMinutes()) : 60;
						setTimeout(() => this.checkPowerSaveStatus(), (1 * delay * 60) * 1000);
					}
				},
				error: e => this.logService.error("Error occured for getEnergySaverConfig ", e)
			})
	}

	// check whether it is in scheduled time or not
	getCurrentPowerSaveStatus() {
		this.pwaSessionService.sessionId.
			pipe(switchMap(s => this.getEnergySaverConfig(s))).subscribe({
				next: data => {
					let res = JSON.parse(JSON.stringify(data))
					let currentDay = this.getCurrentDay();

					let date = new Date();
					let hour = date.getHours();

					let day = res.filter(e => {
						return e.Day == currentDay;
					})

					if (day != null && day != "" && hour >= day[0]['time'].WakeupTime && hour < day[0]['time'].SleepTime && day[0]['Mode'] == EnergySaver.Scheduled) {
						this.isScheduled = true;
					} else {
						this.isScheduled = false;
					}
				},
				error: e => this.logService.error("Error occured for getEnergySaverConfig ", e)
			})
		return this.isScheduled
	}

	getCurrentDay() {
		let date = new Date();
		let currentDay = nameOfDay[date.getDay()];
		return currentDay;
	}

	getNextDay() {
		let date = new Date();
		let nextdate = new Date(date.setDate(date.getDate() + 1));
		let nextDay = nameOfDay[nextdate.getDay()];
		return nextDay;
	}

	// get user defined low power mode values
	getPowerSaverValueFromCWIS() {
		this.retVal = this.snmpService.snmpGet(this.sessionService.deviceUrl, oidCodes.powerSaverValueFromCWIS).toPromise().then((response) => {
			this.logService.debug("getUserDefinedpowerSaverValue : " + response.returnValue)
			localStorage.setItem('UserDefinedPSValue', response.returnValue)
		})
		return this.retVal
	}

	// Change low power value back to user defined value and set timer to force the device into low power mode
	initiateDirectLowPowerMode() {
		this.isLastHourPowerSaver = true;
		this.logService.debug("LastAppSetPSValue : " + this.LastAppSetPSValue)
		this.logService.debug("UserDefinedPSValue : " + parseInt(localStorage.getItem("UserDefinedPSValue")))
		//  Only if it is not equal to the LastAppSetPSValue and UserDefinedPowerSaveValue, then only the Powersaver value is updated
		if (parseInt(localStorage.getItem("UserDefinedPSValue")) != this.LastAppSetPSValue) {
			this.setPowerSaveTimer(parseInt(localStorage.getItem("UserDefinedPSValue")))
		}
		setTimeout(() => this.setLowPowerMode(), 1000);
	}

	//Force the device into low power mode
	setLowPowerMode() {
		const oidArrLowPowerMode = ["1.3.6.1.4.1.2699.1.6.1.6.1.1.1.1", "i", "30"];
		this.retVal = new Promise((resolve, reject) => {
			xrxWsSnmpSet(
				'http://127.0.0.1',
				this.sessionService.snmpWriteString,
				oidArrLowPowerMode,
				(envelope, response) => resolve(this.snmpsetLowPower_callback_success(envelope, response)),
				(request, response, status) => {
					reject(this.snmpsetLowPower_callback_failure(request, response, status))
				},
				null,
				false
			)
		})
	}

	snmpsetLowPower_callback_success(envelope, response) {
		this.isLowPowerEndSet = true;
		this.isLastHourPowerSaver = false;
		this.isFirstUpdateLowPowerMode = true;
		this.logService.info("device went to low power mode at " + this.dayEndHour)
	}

	snmpsetLowPower_callback_failure(request, response, status) {
		this.logService.error("snmpsetLowPower_callback_failure", response)
		this.isLowPowerEndSet = false;
	}

	// Convert milliseconds to hour and minutes
	msToTime(milliseconds) {
		let seconds = (milliseconds / 1000).toFixed(1);
		let minutes = (milliseconds / (1000 * 60)).toFixed(1);
		let hours = (milliseconds / (1000 * 60 * 60)).toFixed(1);
		let days = (milliseconds / (1000 * 60 * 60 * 24)).toFixed(1);
		if (parseInt(seconds) < 60) return seconds + " Sec";
		else if (parseInt(minutes) < 60) return minutes + " Min";
		else if (parseInt(hours) < 24) return hours + " Hrs";
		else return days + " Days"
	}

	calculateTimeDiff(starthour, endhour) {
		const time_start = new Date();
		const time_end = new Date();
		var value_start = starthour.split(':');
		var value_end = endhour.split(':');
		time_start.setHours(value_start[0], value_start[1], value_start[2], 0)
		time_end.setHours(value_end[0], value_end[1], value_end[2], 0)
		const delayMillis = (time_start.getTime() - time_end.getTime())
		return delayMillis
	}

	// set device wakeup for current day or next day
	deviceWakeUpSet(dayStartHour, isCurrentDay: boolean) {
		const targetTime = new Date();
		const now = new Date();

		// Check if wakeup time is in current day 
		if (now.getHours() < dayStartHour && isCurrentDay) {
			let starthour = dayStartHour + ":" + 0 + ":" + 0 + ":" + 0;
			let endhour = now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds() + ":" + 0;
			const delayMillis = this.calculateTimeDiff(starthour, endhour);
			this.logService.debug("Current day device will wakeup in " + this.msToTime(delayMillis))
			// next day device is checked checkPowerSaveStatus that time device in schedule time only wakes up, else device does not wake up.
			this.resetCheckSatus = setTimeout(() => this.checkPowerSaveStatus(true), delayMillis);
		} else {
			// Check if wakeup time is in next day 
			targetTime.setDate(targetTime.getDate() + 1);
			targetTime.setHours(this.nextDayStartHour)
			if (targetTime.getTime() < now.getTime()) {
				targetTime.setHours(targetTime.getHours() + 24);
			}
			const delayMillis = targetTime.getTime() - now.getTime();
			this.logService.debug("Next day device will wakeup in " + this.msToTime(delayMillis))
			// next day device is checked checkPowerSaveStatus that time device in schedule time only wakes up, else device does not wake up.
			this.resetCheckSatus = setTimeout(() => this.checkPowerSaveStatus(), delayMillis);
		}
		return dayStartHour
	}
	/**
	 * Performs the required tasks when device exit from sleep mode in scheduled time.
	 */
	private onWakeup(): void {
		// Bug:96127 - during wakeup we manually reload the app and after reload the app then we check account information
		this.logService.debug("onWakeup event triggered");
		localStorage.setItem("onWakeup","true");
		setTimeout(() => window.location.reload(), 500)
	}
	/**
	 * Performs the required tasks on the first app load in that day when the device is configured as device activity.
	 */
	public isFirstAppLoadOnDeviceActivity(): void {
		var currentDay = this.getCurrentDay()
		if (currentDay != localStorage.getItem("currentDay") && !this.sessionService.isSessionStarted) {
			localStorage.setItem("currentDay", currentDay)
			this.task()
		}
	}
	public task(): void {
		//Bug:93189- Check Accounting configuration.
		this.checkAccountingConfig.next()
		//Bug:92518- Store the device information in the local storage.
		this.deviceInfoService.get(this.sessionService.deviceUrl).pipe(tap((deviceInfo) => {
			localStorage.setItem("deviceInfo", JSON.stringify(deviceInfo))
		}))

		this.logService.debug("Store the device capabilities in the local storage")
		//Bug:92518- Store the device capabilities in the local storage.
		this.deviceCapabilitiesService.setDeviceCapabilities(this.sessionService.deviceUrl).pipe(tap((dc) => {
				var xmlSerializer = new XMLSerializer();
                var newXmlStr = xmlSerializer.serializeToString(dc.trays);
                this.deviceObjects.trays = newXmlStr;
                this.deviceObjects.colorsupport = dc.colorsupport;
                this.deviceObjects.servicesupport = dc.servicesupport;
                this.deviceObjects.finisher = dc.finisher;
                localStorage.setItem("deviceCapabilities", JSON.stringify(this.deviceObjects))
		}))
		localStorage.setItem("onWakeup","false");
	}
}