
/* --------------------------------------------------------------------------------------
   device-registration.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { throwError, of } from 'rxjs';
import { switchMap, catchError, timeout } from 'rxjs/operators';
import { SessionService } from './session.service';
import { JbaconfigService } from './jbaconfig.service';
import { EnvironmentService } from './environment.service';
/**
 * Handles the device registration.
 */
@Injectable({
    providedIn: 'root'
})
export class DeviceRegistrationService {
    t1: any
    startTime: any
    constructor(
        private sessionService: SessionService,
        private http: HttpClient,
        private jbaconfigService: JbaconfigService,
        private environmentService: EnvironmentService
    ) {

    }

    registerDevice(sessionId: string) {
        if (localStorage.getItem('regInfo') !== 'success') {
            this.jbaconfigService.setJbaConfig()
            return this.sessionService.getDeviceInfo().pipe(
                switchMap(s => this.doPostRegistration(s, sessionId))
            )
        }
        return of('DeviceRegistered')
    }

    private doPostRegistration(deviceInfo, sessionId) {
        this.startTime = Date.now()
        const endpoint = this.environmentService.kioskServerUrl + '/api/RegDevice/device'+'?deviceID=' + deviceInfo.serial
        const devInfo = Object.assign({},deviceInfo)
        delete devInfo.serial;
        const regData = JSON.stringify(devInfo)
        return this.http.post(endpoint, regData, { headers: { 'sessionID': sessionId, 'Content-Type': 'application/json' } })
            .pipe(timeout(30 * 1000)).pipe(catchError(r => this.handleDeviceExistsError(r)))
    }

    verifyKioskRegistration(sessionID){
      // if(localStorage.getItem('appSubscription') !== 'subscribed'){
            return  this.sessionService.deviceInfo.pipe(
            switchMap(s=>this.doVerifyKioskRegistration(s.serial,sessionID)));
     //  }
     //  return of('AppSubscribed')
    }
    private doVerifyKioskRegistration(serial,sessionID){
        this.t1 = Date.now()
        const appId= this.sessionService.appId;
        const endpoint = this.environmentService.kioskServerUrl + '/api/MobileUI/VerifyKioskRegistration'+'?deviceID=' + serial + '&appName=' + appId
        return this.http.post(endpoint,{},{ headers: { 'sessionID': sessionID, 'Content-Type': 'application/json' } })
        .pipe(timeout(20000)).pipe(catchError(err => this.handleConfigError(err)))
    }
    private handleDeviceExistsError(result) {
        const error = result.error
        if(error.Message != 'DeviceExists') {
            throw throwError(result.error)
        }
        return of('success')
    }
    private handleConfigError(result) {
        if (JSON.stringify(result).toUpperCase().includes('TIMEOUT')) throw 'Timeout error: Timeout has occured'
        const err = result.error;
        if (err == 'configurationNotValid') return of('configurationNotValid')
        throw throwError(result.error)
    }
}
