
/* --------------------------------------------------------------------------------------
   device-capabilities.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PaperSizes } from 'src/app/model';
import { LogService, SessionService } from 'src/app/services';

declare function xrxDeviceConfigGetDeviceCapabilities(url: string, callback: any, errorCallback: any, timeout: number): any;
declare function xrxDeviceConfigParseGetDeviceCapabilities(capabilitiesResponse: string): any;

@Injectable({
    providedIn: 'root'
})
export class DeviceCapabilitiesService {
    startTime: number
    deviceCapabilities: ReplaySubject<any>
    deviceObjects: any = {}
    parser: any
    xmlResponse: any

    constructor(private sessionService: SessionService,
    private  logService: LogService    
    ) {
    }
    get() {
        this.startTime = Date.now()
        return this.getDeviceCapabilities(this.sessionService.deviceUrl).pipe(map(dc => { return { colorsupport: dc.colorsupport } }))
    }
    getUrlObject() {
        return this.getDeviceCapabilities(this.sessionService.deviceUrl).pipe(map(dc => { return { c: +dc.colorsupport, t: this.getAvailableTrays(dc.trays) } }))
    }
    getDeviceColorSupport() {
        this.startTime = Date.now()
        return this.getDeviceCapabilities(this.sessionService.deviceUrl)
            .pipe(map(dc => { return +dc.colorsupport == 1 ? "Color" : "Mono" })).pipe(catchError(err => of(err)))
    }
    getServiceSupported() {
        return this.getDeviceCapabilities(this.sessionService.deviceUrl).pipe(map(x => { return x.servicesupport }))
    }
    getFinishingSupported() {
        this.startTime = Date.now()
        return this.getDeviceCapabilities(this.sessionService.deviceUrl).pipe(map(x => { return x.finisher }))
    }
    setTrayInformation() {
        this.startTime = Date.now()
        return this.getDeviceCapabilities(this.sessionService.deviceUrl).pipe(map(x => { return this.getAvailabeTrayInformation(x) }))
    }
    setUpdateTrayInformation() {
        this.sessionService.isSetNewTrayValue = true
        return this.getNewDeviceCapabilities(this.sessionService.deviceUrl).pipe(map(x => { return { finisher: x.finisher, trays: this.getAvailabeTrayInformation(x) } }))
    }
    private getAvailableTrays(trayXml) {
        this.parser = new DOMParser();
        trayXml = this.parser.parseFromString(trayXml, "text/xml");
        let trays = trayXml.documentElement.getElementsByTagName('InputTrayEntry')
        const prefixedTrays = trayXml.documentElement.getElementsByTagName('eipjobcap:InputTrayEntry')
        let prefix = ''
        if (prefixedTrays.length > 0) {
            prefix = 'eipjobcap:'
            trays = prefixedTrays
        }

        let result = ['a']
        for (let index = 0; index < trays.length; index++) {
            const name = trays[index].getElementsByTagName(prefix + 'InputTrayName')[0].textContent
            const levelTagList = trays[index].getElementsByTagName(prefix + 'TrayLevel')
            const level = (!levelTagList.length) ?
            0 :
            trays[index].getElementsByTagName(prefix + 'TrayLevel')[0].textContent

            const hasPaper = !!parseInt(level)

            const size = (!levelTagList.length) ?
                null :
                this.getMediaSize(trays[index], prefix)

            const orientation = levelTagList.length? trays[index].getElementsByTagName(prefix + 'FeedOrientation')[0].textContent : null
            const orientationCode = orientation == 'ShortEdgeFirst'? 's' : 'l'

            //adding numbered trays
            if (!isNaN(name.charAt(name.length-1)) && size != null) {
                const codeLetter = hasPaper? 'T' : 't'
                const orientationCode = orientation == 'ShortEdgeFirst'? 's' : 'l'
                result.push(codeLetter + name.charAt(name.length-1) + '-' + orientationCode +'-' + size)
            }
            //adding bypasstray
            if (name == 'BypassTray') {
                const codeLetter = hasPaper? 'B' : 'b'
                result.push(codeLetter + '-' + orientationCode + '-' + size)
            }
        }

        return result.join('/')
    }
    getMediaSize(traysElement, prefix) {
        const mediaSize = traysElement.getElementsByTagName(prefix + 'MediaSize')[0]
        let x = mediaSize.firstChild.textContent
        let y = mediaSize.lastChild.textContent
        if (x.trim().length == 0) {
            //VersaLink
            const fields = traysElement.textContent.replace(' ','').split('\n')
            const values = fields.map(f => f.trim()).filter(f => f.length > 0)
            x = values[2]
            y = values[3]
        }
        const result = PaperSizes.find(p => p.x == x && p.y == y)
        // In future based on the country where the device is deployed
        // the respective system (imperial or metric) should be used.
        if(result == null) {
            let conversionValue = 0.00039370
            let x_inch = Math.ceil(x * conversionValue)
            let y_inch = Math.ceil(y * conversionValue)
            return x_inch + 'x' + y_inch
        }
        return result.name
    }

    //Bug:92518- Get the device capabilities values from the local storage.
     getDeviceCapabilities(deviceUrl: string): Observable<any> {
        const val = (localStorage.getItem('deviceCapabilities') !== null)
        if (val) {
          if (this.deviceCapabilities) {
            return this.deviceCapabilities;
          }
          else {
            var deviceConfig = JSON.parse(localStorage.getItem('deviceCapabilities')) 
            this.deviceCapabilities = new ReplaySubject<any>();
            this.deviceCapabilities.next(deviceConfig)
            return (this.deviceCapabilities)
          }
        }
        else {
            return this.updateDeviceCapabilities(deviceUrl)
        }
    }

    //Bug:97421- Get the device capabilities values.
    getNewDeviceCapabilities(deviceUrl: string): Observable<any> {
            return this.updateDeviceCapabilities(deviceUrl)
    }

    updateDeviceCapabilities(deviceUrl: string){
        return this.setDeviceCapabilities(deviceUrl)
        .pipe(
          tap(dc => {
            var xmlSerializer = new XMLSerializer();
            var newXmlStr = xmlSerializer.serializeToString(dc.trays);
            this.deviceObjects.trays = newXmlStr;
            this.deviceObjects.colorsupport = dc.colorsupport;
            this.deviceObjects.servicesupport = dc.servicesupport;
            this.deviceObjects.finisher = dc.finisher;
            //Bug:92518- Store the device capabilities in the local storage.
            localStorage.setItem("deviceCapabilities", JSON.stringify(this.deviceObjects))
            this.logService.writeToLocalStorage("updateDeviceCapabilities in localStorage", "" ,true)
            var deviceConfig = JSON.parse(localStorage.getItem('deviceCapabilities')) 
            this.deviceCapabilities = new ReplaySubject<any>();
            this.deviceCapabilities.next(deviceConfig);
            return this.deviceCapabilities;
          })
        )
    }
    setDeviceCapabilities(deviceUrl: string) {
        return this.getDeviceCapabilitiesXml(deviceUrl)
        .pipe(map(x => this.parseDeviceCapabilities(x)))
    }
    private parseDeviceCapabilities(rawDeviceCapabilities: any) {
        return {
            trays: this.parseTraySupport(rawDeviceCapabilities),
            colorsupport: this.getColorSupport(rawDeviceCapabilities),
            finisher: this.parseFinishingSupported(rawDeviceCapabilities),
            servicesupport: this.parseServiceSupported(rawDeviceCapabilities)
        }
    }
    private getDeviceCapabilitiesXml(deviceUrl: string): Observable<any> {
        const result = new Promise((resolve, reject) => {
            xrxDeviceConfigGetDeviceCapabilities(
                deviceUrl,
                (env, response) => resolve(xrxDeviceConfigParseGetDeviceCapabilities(response)),
                (error) => reject(error),
                5
            )
        })
        return from(result)
    }
    private parseTraySupport(rawTraySupport) {
        const versalinkTrayCapabilitiesNode = rawTraySupport.documentElement.getElementsByTagName('eipjobcap:InputTraysCapabilities')
        const altalinkTrayCapabilitiesNode = rawTraySupport.documentElement.getElementsByTagName('InputTraysCapabilities')
        const trayCapabilitiesNode = versalinkTrayCapabilitiesNode.length ? versalinkTrayCapabilitiesNode : altalinkTrayCapabilitiesNode
        return trayCapabilitiesNode[0]
        // use this way below for register device
        //return trayCapabilitiesNode[0].innerHTML.replace(/[\n\r]/g, '')
    }
    private parseServiceSupported(rawDeviceCapabilities){
        let element = [];
        const versalinkServiceTypes = rawDeviceCapabilities.documentElement.getElementsByTagName('eipjobcap:ServiceType')
        const altalinkServiceTypes = rawDeviceCapabilities.documentElement.getElementsByTagName('ServiceType')
        const serviceTypes = versalinkServiceTypes.length ? versalinkServiceTypes : altalinkServiceTypes
          for (let index = 0; index < serviceTypes.length; index++) {
            element.push(serviceTypes[index].textContent)
          }
        return element;
    }
    private getColorSupport(rawDeviceCapabilities) {
        const versalinkColorSupportNode = rawDeviceCapabilities.documentElement.getElementsByTagName('eipjobcap:ColorEffectsTypeSupported')
        const altalinkColorSupportNode = rawDeviceCapabilities.documentElement.getElementsByTagName('ColorEffectsTypeSupported')
        const ColorSupportNode = versalinkColorSupportNode.length ? versalinkColorSupportNode : altalinkColorSupportNode
        return ColorSupportNode[0].textContent.includes('Color')
    }

    private parseFinishingSupported(rawDeviceCapabilities) {
        let element = {}
        let staple: any
        const versalinkStapleFinishingTypes = rawDeviceCapabilities.documentElement.getElementsByTagName('eipjobcap:StapleFinishingsSupported')
        const altalinkStapleFinishingTypes = rawDeviceCapabilities.documentElement.getElementsByTagName('StapleFinishingsSupported')
        const stapleFinishingTypes = versalinkStapleFinishingTypes.length ? versalinkStapleFinishingTypes : altalinkStapleFinishingTypes
        if (typeof (stapleFinishingTypes[0]) != 'undefined' && stapleFinishingTypes[0] != null) {
            staple = stapleFinishingTypes[0].textContent.split('\n').filter(entry => entry.trim() != '');
            if (staple == "None") { staple = []; }
        }
        else {
            staple = [];
        }
        let punch: any
        const versalinkpunchFinishingTypes = rawDeviceCapabilities.documentElement.getElementsByTagName('eipjobcap:PunchFinishingsSupported')
        const altalinkpunchFinishingTypes = rawDeviceCapabilities.documentElement.getElementsByTagName('PunchFinishingsSupported')
        const punchFinishingTypes = versalinkpunchFinishingTypes.length ? versalinkpunchFinishingTypes : altalinkpunchFinishingTypes
        if (typeof (punchFinishingTypes[0]) != 'undefined' && punchFinishingTypes[0] != null) {
            punch = punchFinishingTypes[0].textContent.split('\n').filter(entry => entry.trim() != '');
        }
        else {
            punch = [];
        }
        element = { stapleFinishingsSupported: staple, punchFinishingsSupported: punch }
        return element;
    }

    private getAvailabeTrayInformation(dc) {
        let inputTrays = [];
        let trays;
        let prefixedTrays;

        //In the device capabilities tray values, if the value comes from local storage string values, we need to use the DOM parser, or if the value comes from an XML object, there is no need to use the DOM parser.
        if(!this.sessionService.isSetNewTrayValue){
            this.parser = new DOMParser();
            this.xmlResponse = this.parser.parseFromString(dc.trays, "text/xml");
            trays = this.xmlResponse.documentElement.getElementsByTagName('InputTrayEntry')
            prefixedTrays = this.xmlResponse.documentElement.getElementsByTagName('eipjobcap:InputTrayEntry')
        } else {
            trays = dc.trays.getElementsByTagName('InputTrayEntry')
            prefixedTrays = dc.trays.getElementsByTagName('eipjobcap:InputTrayEntry')
            this.sessionService.isSetNewTrayValue = false
        }
        let prefix = ''
        if (prefixedTrays.length > 0) {
            prefix = 'eipjobcap:'
            trays = prefixedTrays
        }
        for (let index = 0; index < trays.length; index++) {
            const trayName = trays[index].getElementsByTagName(prefix + 'InputTrayName')[0].textContent
            if (trayName !== "Automatic") {
                const levelTagList = trays[index].getElementsByTagName(prefix + 'TrayLevel')
                const trayLevel = (!levelTagList.length) ?
                    0 :
                    trays[index].getElementsByTagName(prefix + 'TrayLevel')[0].textContent
                const mediaSize = (!levelTagList.length) ?
                    null :
                    trays[index].getElementsByTagName(prefix + 'MediaSize')[0]
                let xDimension = mediaSize.firstChild.textContent
                let yDimension = mediaSize.lastChild.textContent
                if (xDimension.trim().length == 0) {
                    const fields = trays[index].textContent.replace(' ', '').split('\n')
                    const values = fields.map(f => f.trim()).filter(f => f.length > 0)
                    xDimension = values[2]
                    yDimension = values[3]
                }
                const mediaColor = levelTagList.length ? trays[index].getElementsByTagName(prefix + 'MediaColor')[0].textContent : null
                const mediaType = levelTagList.length ? trays[index].getElementsByTagName(prefix + 'MediaType')[0].textContent : null
                const feedOrientation = levelTagList.length ? trays[index].getElementsByTagName(prefix + 'FeedOrientation')[0].textContent : null
                if (mediaSize !== null) {
                    inputTrays.push(
                        {
                            'InputTrayName': trayName,
                            'TrayLevel': trayLevel,
                            'MediaSize': {
                                'XDimension': xDimension,
                                'YDimension': yDimension
                            },
                            'MediaColor': mediaColor,
                            'MediaType': mediaType,
                            'FeedOrientation': feedOrientation
                        }
                    );
                }
            }
        }
        return inputTrays
    }
}
