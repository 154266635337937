
/* --------------------------------------------------------------------------------------
   snmp.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionService, LogService } from 'src/app/services';
import { EnvironmentService } from 'src/app/services/environment.service';
declare function xrxWsSnmpGet(url: string, scope: string, oid: string, callback: any, errorCallback: any, timeout: number, async: boolean): any;
declare function xrxWsSnmpParseGet(result: any): any;

@Injectable({
  providedIn: 'root'
})
export class SnmpService {
  checkKioskSettings: boolean = false

  constructor(
    private sessionService: SessionService,
    private logService: LogService,
    private environmentService: EnvironmentService
  ) { }

  get(deviceUrl: string, oid: string) {
    return this.snmpGet(deviceUrl, oid).pipe(map(r => r.returnValue))
  }
  public snmpGet(deviceUrl: string, oid: string): Observable<any> {
    const result = new Promise((resolve, reject) => {
      xrxWsSnmpGet(
        deviceUrl,
        this.sessionService.snmpReadString,
        oid,
        (env, result) => resolve(xrxWsSnmpParseGet(result)),
        (error: any) => reject(this.snmpGetErrorLog(error)),
        this.environmentService.snmpTimeOutValue,
        true
      )
    })
    return from(result)
  }

  snmpGetResponse(deviceUrl: string, oid: string): Observable<any> {
    const result = new Promise((resolve, reject) => {
      xrxWsSnmpGet(
        deviceUrl,
        this.sessionService.snmpReadString,
        oid,
        (env, response) => resolve(this.snmpGetSuccess(response)),
        (env, error) => reject(this.snmpGetErrorLog(error)),
        this.environmentService.snmpTimeOutValue,
        false)
    })
    return from(result);
  }

  snmpGetSuccess(respText) {
    var oidVal = xrxWsSnmpParseGet(respText);
    if (oidVal.returnValue == 0) {  //no paper in feeder
      this.sessionService.isPaperinPlaten = true;
    }
    else {
      this.sessionService.isPaperinPlaten = false;
    }
  }

  snmpGetErrorLog(error) {
    this.logService.error("snmpGet() function error", error);
    if (!this.sessionService.isSessionStarted && !this.checkKioskSettings) {
      window.location.reload()
    }
  }
}
