
/* --------------------------------------------------------------------------------------
   main.component.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Component, OnInit, ViewChild } from '@angular/core';
import { interval } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { JobType } from 'src/app/app.types';
import {
  AliveService, FriendlyStatusService, JobService, KioskStatusService, SessionService, SignalrService, CheckoutService, LogService, ReceiptService, PwaSessionService
} from 'src/app/services';
import { DeviceAlertService } from 'src/app/services/device-alert.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ThankyouModalComponent } from 'src/app/shared/components/thankyou-modal/thankyou-modal.component';
import { XrxModalComponent } from 'src/app/shared/components/xrx-modal/xrx-modal.component';
import { DeviceNetworkService } from 'src/app/shared/eip';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  elementType = 'url';
  error: string;
  sessionUrl: string;
  sessionStarted: boolean;
  twoSidedError: boolean;
  webSocketStatus: string = '';
  isDeviceFault: boolean = false;
  twoSidedCopyPopup: boolean = false;
  disableButton: boolean = false;

  @ViewChild('errorModal', { static: true }) errorModal: XrxModalComponent;
  @ViewChild('sessionErrorModal', { static: true }) sessionErrorModal: XrxModalComponent;
  @ViewChild('cancelErrorModal', { static: true }) cancelErrorModal: XrxModalComponent;
  @ViewChild('ShowConversionError', { static: true }) ShowConversionError: ThankyouModalComponent ;
  @ViewChild('logoutErrorModal', { static: true }) logoutErrorModal: XrxModalComponent;
  @ViewChild('showReceiptModal', { static: true }) showReceiptModal: ThankyouModalComponent ;
  @ViewChild('showEndSessionModal', { static: true }) showEndSessionModal: ThankyouModalComponent ;
  @ViewChild('showThankyouModal', { static: true }) showThankyouModal: ThankyouModalComponent ;
  @ViewChild('webSocketErrorModal', { static: true }) webSocketErrorModal: XrxModalComponent;
  @ViewChild('trayInfoModal', { static: true }) trayInfoModal: XrxModalComponent;
  @ViewChild('copyTwoSideModal', { static: true }) copyTwoSideModal: XrxModalComponent;
  @ViewChild('copyTwoSideErrorModal', { static: true }) copyTwoSideErrorModal: XrxModalComponent
  @ViewChild('paperSupplyNotSupportModal', { static: true }) paperSupplyNotSupportModal: XrxModalComponent;

  constructor(
    private jobService: JobService,
    private loader: LoaderService,
    private kioskStatusService: KioskStatusService,
    private friendlyStatusService: FriendlyStatusService,
    private deviceNetworkService: DeviceNetworkService,
    private aliveService: AliveService,
    private sessionService: SessionService,
    private signalRService: SignalrService,
    private checkoutService: CheckoutService,
    private logService: LogService,
    private receiptService: ReceiptService,
    private deviceAlertService:DeviceAlertService,
    private environmentService: EnvironmentService,
    private pwaSessionService: PwaSessionService
  ) { }

  ngOnInit(): void {
    localStorage.setItem("serverUrl", this.environmentService.kioskServerUrl + "/")
    sessionStorage.setItem("protocol", "")
    this.jobService.currentJob.subscribe({
      next: result => this.handleJobStatus(result),
      error: err => this.handleJobError(err)
    });

    this.receiptService.receipt.subscribe({
      next: result => {
        this.kioskStatusService.updateKioskStatusRest("waitingForUser", {"jobName":null,"jobType":null,"jobSource":null,"jobStateReason":null,"printReceiptStatus":"completed"}).subscribe()
        this.showReceipt(result)}
    });

    this.signalRService.sessionNotification.subscribe({
      next: result => this.showSessionEndedMessage(result)
    })

    this.signalRService.webSocketNotificationMain.subscribe({
      next: result => this.showWebSocketMessageMain(result)
    })

    this.jobService.error.subscribe(err => { err.errorType == "unexpected" ? this.handleJobError(err) : err.errorType == "smtpError" ? this.handleSMTPErrors(err.error) : this.handleCancelErrors(err.error) })
    this.aliveService.get().subscribe({
      next: n => this.logService.debug('Language settings enabled: ' + n),
      error: e => this.logService.debug('Unable to read the language settings: ' + e)
    })
  }

  handleJobStatus(jobStatus) {
    if (jobStatus.status == 'computingCost' || jobStatus.status == 'Converting') {
      this.cancelErrorModal.close()
    }
    if (this.isNewStatus(jobStatus) && this.isNotWaitingForApprovalState(jobStatus) && this.isNotXwpcConversionState(jobStatus) && this.sendCompletedAfterInfoRequest(jobStatus) && !this.twoSidedCopyPopup) {
      this.sessionService.lastJobStatus = jobStatus;
      this.kioskStatusService.updateKioskStatus(jobStatus).subscribe(r => {
        this.logService.debug("Calling updateKioskStatus with jobState= " + this.sessionService.lastJobStatus.state)
        if (jobStatus.state == "jobCompleted" || jobStatus.state == "jobCompleteWithErrorBeforeApproval" || jobStatus.state == 'fileTransferComplete') {
          this.twoSidedCopyPopup = false;
          this.isMediumNotLoadedForAPS(jobStatus) ? (this.isUnexpectedReasons(jobStatus) ? this.UnexpectedReasonsError(jobStatus) : this.autoTrayNotAvailableError()) : this.onJobComplete();
        }
      })
    }
    //When user enters invalid email for scan job in Discovery device, then the jobStateReason is NoToAddress.
    if (!(jobStatus.status == 'deviceFault' || jobStatus.status == 'deviceFaultCleared' ||
      jobStatus.state == 'CoversionOrTransferFailed' ||
      (jobStatus.status == 'PendingHeld' && jobStatus.reasons == 'JobIncoming') ||
      (jobStatus.status == undefined) ||
      (jobStatus.status == 'Completed' && (jobStatus.reasons == 'NoToAddress' || jobStatus.reasons == 'NoRoomOnSMTPServer' || (jobStatus.reasons == 'CancelByUser' && jobStatus.cancelled == false))))) {
      this.showJobStatusModal(jobStatus);
    }

    if (jobStatus.state == 'CoversionOrTransferFailed' && (this.sessionService.fileTrasnferFailed || this.sessionService.docConversionFailed)) {
      this.handleConvertionError();
      this.onJobComplete();
    }

    if (jobStatus.reasons == 'cancelConversion') {
      this.sessionService.setLogoutErrorMessage('cancelled')
      this.onJobComplete();
    }

    //When multiple paper sizes are available in the feeder in a scan job, then the job state reason is InputScanSizeNotDetermined.
    if (jobStatus.reasons == 'InputScanSizeNotDetermined') {
      this.paperSupplyNotSupportError()
      this.onJobComplete();
    }

    if (jobStatus.jobType == JobType.Copy && this.pwaSessionService.isDiscoveyDevice && this.sessionService.isPaperinPlaten && jobStatus.reasons == 'WaitingForSide2' && !this.twoSidedCopyPopup) {
      this.disableButton = false
      this.loader.hide();
      this.twoSidedCopyPopup = true;
      this.copyTwoSideModal.open();
    }

    if (jobStatus.jobType == JobType.Copy && this.pwaSessionService.isDiscoveyDevice && jobStatus.reasons == 'WaitingForSide2' && this.twoSidedCopyPopup) {
      this.loader.hide();
    }

    if (jobStatus.status == 'deviceFault' && !this.isDeviceFault) {
      this.isDeviceFault = true;
      this.deviceAlertService.setDeviceAlert(null)
        .subscribe({
          next: n => this.logService.debug('Main Component setDeviceAlert response: ' + n),
          error: e => this.logService.debug('Main Component setDeviceAlert error: ' + e)
        })
    }
    if (jobStatus.status == 'deviceFaultCleared' && this.isDeviceFault) {
      this.isDeviceFault = false;
      this.deviceAlertService.setDeviceAlert(null)
        .subscribe({
          next: n => this.logService.debug('Main Component setDeviceAlert response: ' + n),
          error: e => this.logService.debug('Main Component setDeviceAlert error: ' + e)
        })
    }
  }

  isNewStatus(jobStatus) {
    return !this.sessionService.lastJobStatus ||
        this.sessionService.lastJobStatus.status !== jobStatus.status ||
        this.sessionService.lastJobStatus.reasons !== jobStatus.reasons;
  }

  isNotWaitingForApprovalState(jobStatus) {
    return !(jobStatus.state == 'waitingForApproval')
  }

  isNotXwpcConversionState(jobStatus) {
    return !(jobStatus.state == 'XwpcConversion')
  }

  sendCompletedAfterInfoRequest(jobStatus) {
    if(jobStatus.state == 'jobCompleteWithErrorBeforeApproval'){
      return true
    }
    return !(jobStatus.status == 'Completed' && jobStatus.state != 'jobCompleted')
  }

  onJobComplete() {
    setTimeout(() => this.loader.hide(), 2000);
    this.sessionService.lastJobStatus = null;
    this.sessionService.clearJobRunning()
    this.kioskStatusService.updateKioskStatusRest('waitingForUser', {}).subscribe(
      n => {
        if (this.sessionService.getSessionState().logout) {
          this.checkoutService.initiateCheckout()
        }
      })
  }

  showJobStatusModal(jobStatus) {
    if (jobStatus.state == 'waitingForApproval' && !jobStatus.isReleased && !jobStatus.cancelled && this.jobService.mobileJobWaitingForApproval){
      this.loader.show({
        title: 'MODAL.REMOTE_JOB_SUBMISSION',
        description: this.getJobDescription(jobStatus.jobType),
        loadingText: this.friendlyStatusService.get(jobStatus),
        icon: 'pause'
      });
    }
    else {
      this.loader.show({
        title: 'MODAL.REMOTE_JOB_SUBMISSION',
        description: this.getJobDescription(jobStatus.jobType),
        loadingText: this.friendlyStatusService.get(jobStatus),
      });
    }
  }

  showReceipt(result) {
    if (result.reason == 'JobCompletedSuccessfully') {
      this.showReceiptModal.openWithContent('MODAL.THANK_YOU', 'MODAL.RECEIPT');
    } else {
      this.logService.error('MainComponent: Error occured in recipt. Error = ', JSON.stringify(result))
      this.cancelErrorModal.openWithContent('JOB_CANCEL.TITLE.UNEXPECTED_ERROR', 'JOB_CANCEL.BODY.RECEIPT_JOB_ABORTED');
    }
    setTimeout(() => this.signalRService.triggerEndSessionRequest(sessionStorage.getItem("serialNumber")), 2000)
  }

  getJobDescription(jobType: JobType) {
    switch (jobType) {
      case JobType.Scan:
        return 'MODAL.SCAN_TO_EMAIL_JOB';
      case JobType.Print:
        return 'MODAL.PRINT_JOB';
      case JobType.Copy:
        return 'MODAL.COPY_JOB';
      case JobType.Fax:
        return 'MODAL.FAX_JOB';
      case JobType.SecurePrint:
        return 'MODAL.SECURE_PRINT_JOB';
    }
  }

  handleConvertionError() {
    this.logService.error('Error in Session while doc conversion occured at MainComponent ', null)
    this.loader.hide();
    const title = this.sessionService.fileTrasnferFailed ? 'MODAL.FILE_TRANSFER_FAILED' : 'MODAL.DOCUMENT_CONVERSION_ERROR'
    const message = this.sessionService.fileTrasnferFailed ? 'MODAL.PLEASE_TRY_AGAIN' : 'MODAL.DOCUMENT_CONVERSION_ERROR_BODY'
    this.ShowConversionError.openWithContent(title, message);
    setTimeout(() => this.ShowConversionError.close(), 5000);
  }

  handleJobError(error) {
    if (error.deviceStatus == "starting job") {
      this.kioskStatusService.updateKioskStatusRest('jobComplete', {
        'jobName': 'PortBay Job',
        'jobType': error.jobType,
        'jobSource': 'mobile',
        'jobStateReason': "AbortBySystem"
      }).subscribe()
      setTimeout(() => this.kioskStatusService.updateKioskStatusRest('waitingForUser', {}).subscribe(), 5000);
    }
    this.logService.error('Error while doing job occured at MainComponent:  ', JSON.stringify(error.error))
    this.loader.hide();
    this.twoSidedError = error.error == 'CopyTwoSidedError'
    this.errorModal.open();
    setTimeout(() => this.closeError(), 5000);
  }

  handleSessionError() {
    this.logService.error('Error in Session while doing job occured at MainComponent ', null)
    this.loader.hide();
    this.sessionErrorModal.open();
    setTimeout(() => this.closeSessionError(), 5000);
  }

  handleCancelErrors(error) {
    this.logService.error('Error while cancelling job occured at MainComponent: ', JSON.stringify(error))
    this.loader.hide();
    this.cancelErrorModal.openWithContent('JOB_CANCEL.TITLE.CANCEL' , 'JOB_CANCEL.BODY.' + error);
  }

  handleSMTPErrors(error) {
    this.logService.error('Error while cancelling job occured at MainComponent: ', JSON.stringify(error))
    this.loader.hide();
    this.cancelErrorModal.openWithContent('KIOSK_ERROR.TITLE.CONFIGURATION_NOT_VALID' , 'JOB_CANCEL.BODY.SMTP_SETTINGS_INCORRECT');
  }

  closeSessionError() {
    this.sessionErrorModal.close()
    window.location.reload()
  }

  closeError() {
    this.twoSidedError = null
    this.errorModal.close()
  }

  private showSessionEndedMessage(messageType) {
    if (messageType == "abnormal") {
      this.showLogoutErrorMessage()
      return
    }
    const checkoutDetailsJSON = this.sessionService.getSessionState().checkoutDetails
    const checkoutDetails = JSON.parse(checkoutDetailsJSON)
    //lowMemory - At the end of the sesion available memory is less than the threshold value 
    if (messageType == "lowMemory") {
      this.showEndSessionModal.close()
      this.showThankyouModal.openWithContent('MODAL.SESSION_COMPLETE', 'MODAL.LOW_MEMORY')
    }
    else {
      switch (checkoutDetails.receiptType) {
        case 'email':
          this.showReceiptModal.openWithContent('MODAL.THANK_YOU', 'MODAL.THANKYOU_EMAIL');
          break;
        default:
          if (this.checkoutService.isPurchaseDone()) {
            this.showEndSessionModal.openWithContent('MODAL.SESSION_COMPLETE', 'MODAL.END_SESSION');
          } else {
            this.showThankyouModal.openWithContent('MODAL.SESSION_COMPLETE', 'MODAL.THANKYOU_KIOSK')
          }
      }
    }
  }

  // Show error popup while reconnecting
  // Hide error popup while reconnected or connected received
  private showWebSocketMessageMain(messageType) {
    this.webSocketStatus = messageType
    if (messageType == "reconnecting")
      this.webSocketErrorModal.open()
    else if (messageType == "reconnected" || messageType == "connected")
      this.webSocketErrorModal.close()
  }

  private showLogoutErrorMessage() {
    if( this.checkoutService.isPurchaseDone() )
      this.logoutErrorModal.openWithContent('KIOSK_ERROR.TITLE.LOGOUT_SESSION_UNEXPECTEDLY_ENDED', 'KIOSK_ERROR.BODY.TRANSACTION_CANCELED_JOB_IN_CART');
    else
      this.logoutErrorModal.openWithContent('KIOSK_ERROR.TITLE.LOGOUT_SESSION_UNEXPECTEDLY_ENDED', 'KIOSK_ERROR.BODY.TRANSACTION_CANCELED');
  }

  autoTrayNotAvailableError() {
    this.onJobComplete();
    this.loader.hide();
    const title = 'LANDING.AUTO_SELECT_NOT_SUPPORT'
    const message = 'LANDING.AUTO_SELECT_NOT_SUPPORT_DESC'
    this.trayInfoModal.openWithContent(title, message);
    setTimeout(() => this.closeautoTrayNotAvailableError(), 5000);
  }

  // Show Auto Select not supported error popup 
  paperSupplyNotSupportError() {
    this.onJobComplete();
    this.loader.hide();
    const title = 'LANDING.AUTO_SELECT_NOT_SUPPORT'
    const message = 'LANDING.AUTO_SELECT_NOT_SUPPORT_DESC'
    this.paperSupplyNotSupportModal.openWithContent(title, message);
    setTimeout(() => this.paperSupplyNotSupportErrorClose(), 5000);
  }

  UnexpectedReasonsError(jobStatus){
    this.onJobComplete();
    this.loader.hide();
    const title = 'JOB_CANCEL.TITLE.CANCEL'
    const message = 'JOB_CANCEL.BODY.UNEXPECTED_REASON ' + jobStatus.reason;
    this.trayInfoModal.openWithContent(title, message);
    setTimeout(() => this.closeautoTrayNotAvailableError(), 5000);
  }

  closeautoTrayNotAvailableError() {
    this.trayInfoModal.close()
  }

  paperSupplyNotSupportErrorClose() {
    this.paperSupplyNotSupportModal.close()
  }

  isMediumNotLoadedForAPS(jobStatus) {
    if (jobStatus.reasons == 'LargerStandardMediumNotLoadedForAPS' || jobStatus.reasons == 'ExactStandardMediumNotLoadedForAPS' 
    || jobStatus.reasons == 'MediumNotAvailableForAPS' || jobStatus.reasons == 'MSORequiredMediumNotLoadedForAPS') {
      return true
    } else {
      return false
    }
  }

  isUnexpectedReasons(jobStatus) {
    if (jobStatus.reasons == 'LargerStandardMediumNotLoadedForAPS' || jobStatus.reasons == 'ExactStandardMediumNotLoadedForAPS'
      || jobStatus.reasons == 'MediumNotAvailableForAPS' || jobStatus.reasons == 'MSORequiredMediumNotLoadedForAPS'
      || jobStatus.reasons == 'JobCompletedWithWarnings' || jobStatus.reasons == 'JobCompletedSuccessfully'
    ) {
      return false
    } else {
      return true
    }
  }
  // Cancel Two-sided copy platen job
  cancelTwoSidedCopyJob() {
    this.copyTwoSideErrorModal.close()
    this.copyTwoSideModal.close();
    setTimeout(()=> this.twoSidedCopyPopup = false, 2000)
    this.jobService.cancelCopyJob()
  }

  // Continue Two-sided copy job if paper is in platen else show copyTwoSideErrorModal
  continueTwoSidedCopyJob() {
    this.disableButton = true
    this.copyTwoSideErrorModal.close()
    this.copyTwoSideModal.close();
    this.jobService.copySideTwo()
    setTimeout(()=> this.twoSidedCopyPopup = false, 2000)
    this.jobService.copyNotification.subscribe({
      next: s => {
        this.copyTwoSideErrorModal.close()
        this.loader.hide();
        this.copyTwoSideModal.close()
        this.copyTwoSideErrorModal.open()
      }
    })
  }
}
