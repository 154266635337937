
/* --------------------------------------------------------------------------------------
   device-info.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import $ from 'jquery';
import { from, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { LogService } from 'src/app/services';
import { EnvironmentService } from 'src/app/services/environment.service';
//import * as semver from 'semver'

declare function xrxGetElementValue(content: string, key: string): any;
declare function xrxStringToDom(xrxString: string): any;
declare function xrxDeviceConfigGetDeviceInformation(url: string, callback: any, errorCallback: any, timeout: number): any;


@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {
  constructor(private environmentService: EnvironmentService,
    private logService: LogService
  ){}
  get(deviceUrl) {
    return this.getDeviceConfigGetDeviceInformation(deviceUrl)
    .pipe(
      map(info => this.parseDeviceInfo(info)),
      share()
    )
  }

  private parseDeviceInfo(rawDeviceInfo) {
    const domDeviceConfigDeviceInformation = xrxGetElementValue(xrxStringToDom(rawDeviceInfo), "Information")
    const info = xrxStringToDom(domDeviceConfigDeviceInformation)
    return {
      name: $(info).find("DeviceInformation > device > name").text(),
      mac: $(info).find("DeviceInformation > device > mac").text(),
      serial: $(info).find("DeviceInformation > device > serial").text(),
      model: $(info).find("DeviceInformation > device > model").text(),
      canvasWidth: $(info).find("DeviceInformation > display > canvasSize > width").text(),
      canvasHeight: $(info).find("DeviceInformation > display > canvasSize > height").text(),
      //styleGeneration: $(info).find("DeviceInformation > style > generation").text(),
      systemsoftware: $(info).find("DeviceInformation > version > systemSoftware").text(),
      //uiSoftware: $(info).find("DeviceInformation > version > uiSoftware").text(),
      //netControllerSoftware: $(info).find("DeviceInformation > version > netControllerSoftware").text()
      EIPSoftware: this.getEipVersion(info),
      EIPBrowser: this.getLatestBrowserInfo($(info).find("DeviceInformation > version > EIPBrowserInfo") ,info)
    }
  }
  private getEipVersion(info) {
    var version = $(info).find("DeviceInformation > version > eipSoftware > majorVersion").text();
    version += "." + $(info).find("DeviceInformation > version > eipSoftware > minorVersion").text();
    version += "." + $(info).find("DeviceInformation > version > eipSoftware > revision").text();
    return version;
  }
  private getLatestBrowserInfo(EipBrowserInfoNodes, info) {
    let latestIndex = 0
    // let latestVersion = '0.0.0'
    // for (let index = 0; index < EipBrowserInfoNodes.length; index++) {
    //    const browserNode = EipBrowserInfoNodes[index]
    //    const eipWidgetsVersion = browserNode.childNodes[2].textContent
    //    const version = semver.clean(eipWidgetsVersion.split(' ')[0])
    //    if (semver.gt(version, latestVersion)) {
    //      latestIndex = index
    //      latestVersion = version
    //    }
    // }
    const resultBrowserNode = EipBrowserInfoNodes[latestIndex]
    return {
      browserType: resultBrowserNode.childNodes[0].textContent,
      browserVersion: resultBrowserNode.childNodes[1].textContent,
      widgets: resultBrowserNode.childNodes[2].textContent
    }
  }
  private getDeviceConfigGetDeviceInformation(deviceUrl: string): Observable<any> {
    const result = new Promise((resolve, reject) => {
      xrxDeviceConfigGetDeviceInformation(
        deviceUrl,
        (env, response) => resolve(response),
        (error) => reject(error),
        6
      )
    })
    return from(result)
  }
  
} 