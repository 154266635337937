
/* --------------------------------------------------------------------------------------
   email-content.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { combineLatest, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionService } from 'src/app/services';
import { oidCodes } from './constants/oid-codes.const';
import { SnmpService } from './snmp.service';

@Injectable({
    providedIn: 'root'
})
export class EmailContentService {
    constructor(
        private snmpService: SnmpService,
        private sessionService: SessionService
    ) {
    }
    get() {
        return forkJoin({
            sender: this.getSender(),
            subject: this.getSubject(),
            body: this.getBody(),
            signature: this.getSignature()
        })
    }
    getSender() {
        return this.getSnmpValue(oidCodes.emailSender)
    }    
    getSubject() {
        return this.getSnmpValue(oidCodes.altaLink.emailSubject)
    }
    getBody() {
        return this.getSnmpValue(oidCodes.altaLink.emailBody)
    }
    getSignature() {
        const snmpCalls = [1,2,3,4,5,6]
            .map(n => this.getSnmpValue(oidCodes.altaLink.emailSignature['line' + n]))
        return combineLatest(snmpCalls).pipe(map(r => r.join('\r\n')))
    }
    private getSnmpValue(oidCode) {
        return this.snmpService.get(this.sessionService.deviceUrl, oidCode)
            .pipe(map((result: any) => result));
    }
}